import { applyFormatNegatif } from "../display/FormatNegatifDisplay";
import { applyFormatZero } from "../display/FormatZeroDisplay";
import { applyPositionSymbol } from "../display/PositionSymbolDisplay";
import { applyCustomSeparators } from "../display/Separator";
import { applyShorterFormat, getSymbolShorterFormat } from "../display/ShorterFormatDisplay";


export const fillValueWithZeroToMatchPrecision = (value, separatorDecimal, precision) => {
    try {
        const parts = value.toString().split(separatorDecimal);

        const partDec = parts[1]

        if (!partDec && precision > 0) {

            let newValue = value
            newValue = newValue.concat(separatorDecimal)

            for (let n = 0; n < precision; n++) {
                newValue = newValue.concat('0')
            }
            return newValue

        } else if (!!partDec && partDec.length < precision) {

            let newValue = value
            for (let n = partDec.length; n < precision; n++) {
                newValue = newValue.concat('0')
            }
            return newValue

        } else {
            return value
        }
    } catch (e) {
        return value
    }
}

export const applyPrecision = (number,precision) => {
    return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision)
}

const DataOfCellNumberFormatted = (value, formatterNumber, symbol) => {

    if(isFinite(value)){
         
        const isNegatif = value < 0
        let tranformedValue = applyShorterFormat(value, formatterNumber?.shorterFormat)
        let symbolShorterFormat = getSymbolShorterFormat(value, formatterNumber?.shorterFormat) // "", K, M or B

        // we remove minus for now
        tranformedValue = Math.abs(tranformedValue)
        // we apply precision
        tranformedValue = applyPrecision(tranformedValue,formatterNumber?.precision)
        // not a number anymore
        tranformedValue = applyCustomSeparators(tranformedValue, formatterNumber?.separatorThousand, formatterNumber?.separatorDecimal)
        tranformedValue = fillValueWithZeroToMatchPrecision(tranformedValue, formatterNumber?.separatorDecimal?.symbol, formatterNumber?.precision)

        // Check if value is a real 0 
        if (parseFloat(tranformedValue) === 0) {
            tranformedValue = applyFormatZero(formatterNumber?.formatZero, tranformedValue).toString()
        }
        // we add K, M, B...
        tranformedValue = tranformedValue + symbolShorterFormat;
        if (isNegatif)
            tranformedValue = applyFormatNegatif(tranformedValue, formatterNumber?.formatNegatif)
        if (symbol === '%') {
            tranformedValue = applyPositionSymbol(tranformedValue, '%', formatterNumber?.positionSymbol)
        } else {
            if (!!symbol) tranformedValue = applyPositionSymbol(tranformedValue, symbol, formatterNumber?.positionSymbolCurrency)
        }

        return tranformedValue

    }else{ // if value is Infinity
        //return <>&infin;</>
        return <>&nbsp;</>
    }
}


export default DataOfCellNumberFormatted