import React, { useState } from "react";

import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "./CustomToggle";
import CustomMenu from "../../../commons/form/currency/CustomMenu";
import CustomToggleIcon from "./CustomToggleIcon";
import {
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { ColorDisplayMode, LightMode } from "../../display/ColorDisplayMode";
import Popup, { Position } from "devextreme-react/popup";
import { v4 as uuid } from "uuid";

const CommonDropDown = ({
  toggle,
  menu,
  className = "",
  label,
  collapsedIcon = faChevronDown,
  collapsedIconTooltip,
  tooltipPlacement,
  nonCollapsedIcon = faChevronUp,
  collapsed = null,
  setCollapsed,
  onBlur,
  outlined = false,
  displayMode = LightMode,
  readOnly = false,
  containsGrid = false,
  positionAt = "left bottom",
  positionMy = "left top",
  positionOf,
  resizable = false,
  width,
  maxWidth = '98vw',
  shading = false,
  fullScreen = false,
  needTooltip = true
}) => {
  // collapsed et setCollapsed ne sont pas obligatoire !
  // Dans le cas ou ils ne sont pas remplis, collapsedDefault et setCollapsedDefault prennent le relais
  const [collapsedDefault, setCollapsedDefault] = useState(true);

  const [popupSize, setPopupSize] = useState({ width: width ?? 'auto', height: 'auto' })

  // mais collapsedDefault et setCollapsedDefault doivent exister
  const isCollapseExternal = collapsed !== null && !!setCollapsed;

  const onClick = () => {
    if (isCollapseExternal) {
      setCollapsed(!collapsed);
    } else {
      setCollapsedDefault(!collapsedDefault);
    }
  };

  const onPopupResize = (e) => {
    if (resizable) {
      const newSize = { width: e.width, height: e.height }
      setPopupSize(newSize)
    }
  }

  const dropDownUnikClassName = `dd-${uuid()}`;

  return (
    <Dropdown
      className={`dropdown-selector ${outlined ? "outlined" : ""
        } ${displayMode} ${className} ${!toggle ? "no-toggle" : ""} ${readOnly ? "read-only" : ""
        } ${dropDownUnikClassName}`}
      show={isCollapseExternal ? !collapsed : !collapsedDefault}
      autoClose={false}
      flip={"true"}
    >
      <Dropdown.Toggle as={CustomToggle} onClick={onClick}>
        <div className={`common-dropdown-toggle`}>{toggle}</div>
        {!readOnly && (
          <CustomToggleIcon
            collapsed={isCollapseExternal ? collapsed : collapsedDefault}
            collapsedIcon={collapsedIcon}
            nonCollapsedIcon={nonCollapsedIcon}
            collapsedIconTooltip={needTooltip ? collapsedIconTooltip : ""}
            tooltipPlacement={tooltipPlacement}
          />
        )}
      </Dropdown.Toggle>
      {!readOnly && (
        <Dropdown.Menu
          as={CustomMenu}
          containsGrid={containsGrid}
          className={`common-dropdown-menu`}
          specificClassName={className}
          show={isCollapseExternal ? !collapsed : !collapsedDefault}
          onHide={() => {
            isCollapseExternal ? setCollapsed(true) : setCollapsedDefault(true);
            if (!!onBlur) onBlur();
          }}
        >

          <Popup
            className="common-dropdown-menu-dxpopup"
            visible={true}
            hideOnOutsideClick={true}
            onHiding={() => {
              isCollapseExternal
                ? setCollapsed(true)
                : setCollapsedDefault(true);
              if (!!onBlur) onBlur();
            }}
            dragEnabled={true}
            showCloseButton={true}
            width={popupSize?.width}
            maxWidth={maxWidth}
            height={popupSize?.height}
            title={label ?? collapsedIconTooltip}

            deferRendering={true}

            resizeEnabled={resizable}
            onResizeEnd={onPopupResize}
            shading={shading}
            fullScreen={fullScreen}
          >
            <Position
              at={positionAt}
              my={positionMy}
              of={positionOf ?? `.${dropDownUnikClassName} .inhovate-custom-toggle`}
            />
            {menu}
          </Popup>


        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

CommonDropDown.propTypes = {
  collapsed: PropTypes.bool, // permet d ouvrir/fermer le dropdown manuellement
  setCollapsed: PropTypes.func, // permet d ouvrir/fermer le dropdown manuellement
  outline: PropTypes.bool, // permet d'outline le toogle du dropdown
  displayMode: PropTypes.oneOf(ColorDisplayMode), // permet d'utiliser un mode d affichage alternatif
};
export default CommonDropDown;
