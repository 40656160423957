import React from 'react';

import './components/commons/scss/_common.scss'
import './components/commons/scss/common-bootstrap-variant.scss'
import './components/commons/scss/common-modals.scss'
import './components/commons/scss/common-dropdowns.scss'
import './components/commons/scss/common-typeahead.scss'
import './components/commons/scss/common-datasets.scss'
import './components/commons/scss/common-dxgrid.scss'
import './components/commons/scss/common-treelist.scss'


const CommonScss = () => {

    return <></>

}

export default CommonScss

