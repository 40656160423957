import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import PropTypes from 'prop-types'
import CommonTooltip from "../utils/tooltip/CommonTooltip"
import './CommonButton.scss'


const CommonButton = ({variant, type="button", onClick, text, className, icon, icon2, tooltipText, tooltipPlacement, disabled=false, hidden=false, spin=false, flipHorizontal}) => {

    let button = <Button title={tooltipText} type={type} variant={variant} disabled={disabled} className={`button-common ${className} ${hidden? "button-hidden" : ""}`} onClick={onClick} >
        {!!icon && ( <FontAwesomeIcon icon={icon} flip={flipHorizontal && "horizontal"} spin={spin}/> )}
        {!!icon2 && ( <>&nbsp;<FontAwesomeIcon icon={icon2} className="icon2" /></> )}
        {(!!icon && !!text)?" ":""}{text}
    </Button>

    if(!!tooltipText) {

        button = <CommonTooltip tooltipText={tooltipText} placement={tooltipPlacement}>
            {button}
        </CommonTooltip>

    }

    return button;

}


CommonButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    text: PropTypes.any,
    icon: PropTypes.object,
    variant: PropTypes.string,
    className: PropTypes.string,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),

}

export default CommonButton
