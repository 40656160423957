import React from 'react'
import PropTypes from 'prop-types'
import { ColorOverlayLayer, TransparentMode } from './ColorOverlayLayer'
import './CommonOverlay.scss'

const CommonOverlay = ({show, setShow, specificClassName, ColorOverlayLayer=TransparentMode, isOverlayTransparent=true, children }) => {

    // IMPORTANT LE PARENT du CommonOverlay DOIT AVOIR UNE POSITION RELATIVE pour le partscreen 
    const classComplementaire = isOverlayTransparent ? "overlay-transparent" : "overlay-not-transparent";

    return ( 
        <>
        { show && (  
            <div className={`common-overlay ${classComplementaire}`}>
                <div className={`layer-common-overlay ${ColorOverlayLayer} ${specificClassName} ${classComplementaire}`} onClick={(e) => { if(!!setShow){setShow(false)}; e.stopPropagation(); }} />

                <div className={`content-common-overlay ${specificClassName}`}>
                    {children}
                </div>
            </div>
        )}
        </>
    )
}

CommonOverlay.propTypes = {
    // showMore : affiche ou pas l overlay
    show: PropTypes.bool.isRequired,

    // set du bool show
    setShow: PropTypes.func.isRequired,

    // couleur du layer 
    ColorOverlayLayer: PropTypes.oneOf(ColorOverlayLayer),

    // est ce que le commonOverlay est sur tout l ecran ou une seule partie
    isOverlayTransparent: PropTypes.bool,
}

export default CommonOverlay