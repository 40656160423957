import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { homePath } from '../../../navigation/routes';
import PanelLogo from '../inhovate-identity/PanelLogo';
import './TopNav.scss'
import TopNavButtons from './TopNavButtons';
import { useNavigate } from 'react-router';

const TopNav = (props) => {

    let navigate = useNavigate()
    const goHome = () => {
        navigate(homePath)
    }

    return (

        <div className="top-nav">
            <Row nogutters={"true"}>
                <Col sm={{ order: 1, span: 6 }}
                    lg={{ order: 1, span: 6 }}
                    xl={{ order: 1, span: 2 }}
                    xxl={{ order: 1, span: 2 }}
                >
                    <div onClick={goHome} className='logo-and-maintenance'>
                        <PanelLogo />
                    </div>
                </Col>

                <Col className="children-container"
                    sm={{ order: 3, span: 12 }}
                    lg={{ order: 3, span: 12 }}
                    xl={{ order: 2, span: 8 }}
                    xxl={{ order: 1, span: 8 }}
                >
                    {props.children}
                </Col>

                <Col sm={{ order: 2, span: 6 }}
                    lg={{ order: 2, span: 6 }}
                    xl={{ order: 3, span: 2 }}>
                    <TopNavButtons hasDefaultCompany={props.hasDefaultCompany} />
                </Col>
            </Row>
        </div>


    )

}


export default TopNav