import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'
import { useContextI18n } from '../../../../context/ContextI18n'
import PopupAdminContent from '../popup-admin/PopupAdminContent'



const ButtonAdmin = ({isAllowed, activated}) => {

    const { i18n } = useContextI18n()

    const popover = (
        <Popover className="top-menu-popover-options">
            <Popover.Header  as="h3">{i18n("top-menu.admin.title")}</Popover.Header>
            <Popover.Body>
                <PopupAdminContent/>
            </Popover.Body>
        </Popover>
    )

    return (
        isAllowed && (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
            <Button variant="default" disabled={!activated}>
                <FontAwesomeIcon icon={faCrown} />
            </Button>
        </OverlayTrigger>
        )
    )

}


export default ButtonAdmin

