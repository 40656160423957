import { faBug, faEraser, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './LogPanel.scss'
import LogItem from './LogItem'
import { useContextAppVersionning } from '../../../context/ContextAppVersionning'
import { useContextI18n } from '../../../context/ContextI18n'

const LogPanel = ({ logList, onClear, moreThanMax }) => {

    const { i18n } = useContextI18n();
    const { appVersionning } = useContextAppVersionning();

    const [collapsed, setCollapsed] = useState(true)


    return (
        <div className="log-panel-infos">
            {collapsed ? (

                <span className={`button-open ${logList?.length > 0 ? 'errors' : 'empty'}`} onClick={() => setCollapsed(false)}>
                    <FontAwesomeIcon icon={faBug} /> {logList?.length}
                </span>

            ) : (
                <div className="log-panel-infos-content">
                    <div className='header'>
                        <div className="info-item eraser">

                            {logList?.length > 0 && (
                                <>
                                    <FontAwesomeIcon icon={faBug} /> {logList?.length} {moreThanMax ? '+' : ''}
                                    <span onClick={onClear}>
                                        <FontAwesomeIcon icon={faEraser} />
                                    </span>
                                </>
                            )}
                        </div>

                        <div className="info-item version">
                            {!!appVersionning &&
                                <span className='version-number' title={`${i18n("app.versionning.build.date")}: ${appVersionning?.buildNumber} (UTC)`}>
                                    {appVersionning?.version}
                                </span>
                            }

                        </div>

                        <div className="info-item close">
                            <span onClick={() => setCollapsed(true)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </div>
                    </div>

                    {logList?.length === 0 && (
                        <p>No logs</p>
                    )}

                    {/* <ReactJson src={logList} theme="monokai" /> */}
                    <ul>
                        {logList?.map((log, index) => {
                            return (
                                <li key={index} className={`log-item ${log.type}`}>
                                    <LogItem log={log} />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </div>
    )
}

LogPanel.propTypes = {
    // liste des logs (cf. ContextLog)
    logList: PropTypes.array.isRequired,

    // action clear logs
    onClear: PropTypes.func.isRequired,
}

export default LogPanel

