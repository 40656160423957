import React, { createContext, useContext, useReducer } from "react";
import LoadingInfinityLogContexted from "../components/commons/navigation/LoadingInfinityLogContexted";

const ContextLoader = createContext({});
const useContextLoader = () => useContext(ContextLoader);

function UseContextLoaderProvider(props) {

  const actions = {
    SET_SHOW_LOADER: "SET_SHOW_LOADER",
    SET_CUSTOM_CRASH_MESSAGE: "SET_CUSTOM_CRASH_MESSAGE", // new action
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case actions.SET_SHOW_LOADER:
        return {
          ...state,
          showInfinityLoader: action?.show,
          infinityLoaderTitle: action?.title,
          infinityLoaderMessage: action?.message,
          infinityLoaderListErrorCode: action?.lstError,
          infinityLoaderCustomCrashMessage: action?.customCrashMessage,
        }
      case actions.SET_CUSTOM_CRASH_MESSAGE: // new case
        return {
          ...state,
          infinityLoaderCustomCrashMessage: action?.customCrashMessage,
        }
      default:
        return state
    }
  }

  const initialState = {
    showInfinityLoader: false,
    infinityLoaderTitle: undefined,
    infinityLoaderMessage: undefined,
    infinityLoaderListErrorCode: [],
    infinityLoaderCustomCrashMessage: undefined,
  }


  const [state, dispatch] = useReducer(reducer, initialState)

  const getLoader = () => {
    return (
      state.showInfinityLoader &&
      <LoadingInfinityLogContexted
        showLoading={state.showInfinityLoader}
        title={state.infinityLoaderTitle}
        message={state.infinityLoaderMessage}
        listErrorCode={state.infinityLoaderListErrorCode}
        customCrashMessage={state.infinityLoaderCustomCrashMessage}
        isOverlayTransparent={false}
        isWithPoints={true}
        onCloseCrashMode={() => dispatch({
          type: actions.SET_SHOW_LOADER,
          show: false,
          infinityLoaderTitle: undefined,
          infinityLoaderMessage: undefined,
          infinityLoaderListErrorCode: [],
          infinityLoaderCustomCrashMessage: undefined,
        })}
      />
    )
  }

  const value = {
    setShowLoader: (show, title, message, lstError, customCrashMessage) => {
      dispatch({ type: actions.SET_SHOW_LOADER, show, title, message, lstError, customCrashMessage });
    },
    setCustomCrashMessage: (customCrashMessage) => {
      dispatch({ type: actions.SET_CUSTOM_CRASH_MESSAGE, customCrashMessage });
    },
    getLoader
  };




  return (
    <ContextLoader.Provider
      value={
        { ...value }}
      {...props}
    />
  );
}

export { UseContextLoaderProvider, useContextLoader };
