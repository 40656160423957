import { useState, useEffect } from "react";
import moment from 'moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useContextI18n } from "../../../../../context/ContextI18n";
import { Tooltip } from 'devextreme-react/tooltip';
import { v4 as uuid } from "uuid"
import './Clock.scss'


export const renderClock = (time, isUTC, displayDate, contextI18n, showTooltip = false) => {



    const id = `clock-${uuid()}`

    const localeTime = contextI18n.getLocaleDateTimeFromUtc(time)


    return (
        <div className="clock" id={id}>

            <FontAwesomeIcon icon={faClock} />
            {isUTC ? (
                <>
                    <span className="clock-mode">{contextI18n.i18n('clock.UTC')}</span>
                    {displayDate ?
                        <span className="clock-time">{contextI18n.getDateWithTime_HHMM(time)}</span>
                        :
                        <span className="clock-time">{contextI18n.getHHMM(time)}</span>
                    }
                </>
            ) : (
                <>
                    <span className="clock-mode">{contextI18n.i18n('clock.LOCALE')}</span>
                    {displayDate ?
                        <span className="clock-time">{contextI18n.getDateWithTime_HHMM(localeTime)}</span>
                        :
                        <span className="clock-time">{contextI18n.getHHMM(localeTime)}</span>
                    }
                </>
            )
            }

            {showTooltip && (
                <Tooltip
                    target={`#${id}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    hideOnOutsideClick={true}
                    position="top"
                >
                    <div className="clock-tooltip">
                        <span><em>{contextI18n.i18n('clock.LOCALE')} : </em>{contextI18n.getDateWithTime_HHMM(localeTime)}</span>
                        <span><em>{contextI18n.i18n('clock.UTC')} : </em>{contextI18n.getDateWithTime_HHMM(time)}</span>
                        <span><em>{contextI18n.i18n('clock.utc.offset')} : </em> {moment().format('Z')}</span>
                    </div>
                </Tooltip>
            )}
        </div>
    )
}


const Clock = ({ isUTC, displayDate = false }) => {

    const contextI18n = useContextI18n();
    const [now, setNow] = useState(moment());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(moment())
        }, 6000); // Update x10 per minute

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const nowDateUTC = contextI18n.getUTCdateFromLocalDateWithTZ(new Date(now))

    return (
        renderClock(nowDateUTC, isUTC, displayDate, contextI18n)
    );
};

export default Clock