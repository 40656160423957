import { useState } from "react";
import { useEffect } from "react";
import { MAX_LOG_ITEMS, useContextLog } from "../../../context/ContextLog";
import LogPanel from "./LogPanel";

const LogPanelContexted = () => {
  const { getLogList, clear } = useContextLog();

  const [logList, setLogList] = useState([]);

  // loop to get log items
  useEffect(() => {
    const getDatasAtInterval = setInterval(() => {
      setLogList(getLogList().reverse()); // last items in top of the list
    }, 5000);

    return () => clearInterval(getDatasAtInterval);
  }, [getLogList]);

  const moreThanMax = logList.length >= MAX_LOG_ITEMS;

  const onClear = ()=>{
    setLogList([])
    clear()
  }

  return (
    <LogPanel logList={logList} onClear={onClear} moreThanMax={moreThanMax} />
  );
};

export default LogPanelContexted;
