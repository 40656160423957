import React, { useState } from 'react'
import { useContextI18n } from '../../../../context/ContextI18n'
import { useContextUser } from '../../../../context/ContextUser'
import './PopupUserContent.scss'
import CompanyFieldsGrid from "../../../param/company/CompanyFieldsGrid";
import { Formik } from "formik";
import CustomStore from "devextreme/data/custom_store";
import { CommonDataSourceHandle } from "../../dx-grid/CommonDataSourceHandle";
import { getEndPointCompany } from "../../../../api/endPoints";
import PopupConfirm from "../../utils/popup-confirm/PopupConfirm";
import { notifyError } from "../../utils/notification/notification";
import { FormUserService } from "../../../../service/FormUserService";
import { useContextLog } from "../../../../context/ContextLog";
import { addColumnKeyAndDefaultSort } from "../../dx-form/utils/dataUtils";
import FieldDropdownGridSelector2 from '../../formik/field/FieldDropdownGridSelector2'
import ButtonLogout from '../../button/ButtonLogout'
import PopupDeleteLocalDataConfirm from './PopupDeleteLocalDataConfirm'
import ButtonReset from '../../button/ButtonReset'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PictoWithTooltip from '../../utils/picto/PictoWithTooltip';

const PopupUserContent = () => {

    const { i18n } = useContextI18n()
    const { user, signOut, userCompany, urlApi, getRequestHeaders, resolveAccessDictionary } = useContextUser();
    const [dataSource] = useState(new CustomStore(CommonDataSourceHandle(getEndPointCompany(urlApi), getRequestHeaders, "id", undefined)));
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmDeleteLocalData, setShowConfirmDeleteLocalData] = useState(false);

    const formUserService = FormUserService(urlApi, getRequestHeaders, useContextLog());

    const changeCurrentCompany = (company) => {
        formUserService.updateUserWithDefaultCompany({
            data: {
                user: user,
                company: company,
            },
            onSuccess: (response) => {
                resolveAccessDictionary()
            },
            onError: (error) => {
                notifyError(error);
            },
            finally: (response) => {
                
            }
        });
    }

    if (!!user) {

        return (
            <div className='popup-user-content'>
                <span className='name-content' hidden={!user?.name}>
                    {user?.name}
                </span>
                <span className='name-content' hidden={!user?.firstName}>
                    {user?.firstName}
                </span>
                <span className='name-content' hidden={!user?.lastName}>
                    {user?.lastName}
                </span>
                <span className='name-content email' hidden={!user?.email}>
                    {user?.email}
                </span>
              
                 <Formik
                    validateOnMount={true}
                    initialValues={{ company: [userCompany.id] }}
                    onSubmit={() => { }}
                >
                    {(propsForm) => {

                        if (propsForm.dirty) {
                            setShowConfirm(true);
                        }
                        return (
                            <form>


                                <FieldDropdownGridSelector2
                                    className="field-company-selector"
                                    name={"company"}
                                    keyExpr="id"
                                    renderItemToggle={c => c?.name}
                                    needItemToggleDataSourceLoaded={true}
                                    dataSource={dataSource}
                                    datagridChildren={addColumnKeyAndDefaultSort(CompanyFieldsGrid()
                                        .filter((e, index) => e.props.dataField !== "client")
                                        .map((e, index) => {

                                            switch (e.props.dataField) {
                                                case "name":
                                                    return { ...e, props: { ...e.props, width: 150 } };
                                                case "shortName":
                                                    return { ...e, props: { ...e.props, width: 50 } };
                                                default:
                                            }
                                            return e;
                                        })
                                    )}
                                    selection="single"
                                /> 

                                <PopupConfirm
                                    show={showConfirm}
                                    setShow={setShowConfirm}
                                    textTitle={i18n("user.change.current.company.title")}
                                    textBody={i18n("user.change.current.company.body")}
                                    textConfirm={i18n("user.change.current.company.confirm")}
                                    textCancel={i18n("user.change.current.company.cancel")}
                                    onConfirm={() => changeCurrentCompany(propsForm.values.company)}
                                    onCancel={() => propsForm.resetForm()}
                                />
                            </form>
                        )
                    }
                    }
                </Formik>
                <div className="buttons">

                    <div className='reset-button-with-info'>
                        <PictoWithTooltip
                            icon={faInfoCircle}
                            className="info-delete"
                            size="lg"
                            tooltipText={i18n("user.delete.local.data.info")}
                        />

                        <ButtonReset onClick={() => setShowConfirmDeleteLocalData(true)}
                            variant="default"
                            text={i18n("user.delete.local.data.title")} />
                    </div>
                    <ButtonLogout onClick={() => signOut(console.log('DISCONNECTED'))}
                        text={i18n("user.logout")} />

                </div>

                {showConfirmDeleteLocalData &&
                    <PopupDeleteLocalDataConfirm
                        showPopup={showConfirmDeleteLocalData}
                        setShowPopup={setShowConfirmDeleteLocalData}
                    />
                }

            </div>
        )
    } else {

        return (
            <div>
                <p>
                    Unlogged
                </p>
            </div>
        )
    }

}


export default PopupUserContent