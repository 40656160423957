import {getEndPointCompany, getEndPointUsers} from "../api/endPoints";
import { ERROR_FORM_USER } from "../components/assets/const/ErrorCode";
import {GenericInhovateService} from "./GenericInhovateService";

export const FormUserService = (urlApi, requestHeaders, contextLog) => {

    // error log
    const codeLogger = ERROR_FORM_USER;

    const genericService = GenericInhovateService(requestHeaders, contextLog, codeLogger);
    return {
        resolveFormUserById: (params) => genericService.genericAPIGet(getEndPointUsers(urlApi, params.data), params),
        updateUser: (params) => genericService.genericAPIPut(getEndPointUsers(urlApi), params),
        createUser: (params) => genericService.genericAPIPost(getEndPointUsers(urlApi), params),
        deleteFormUserById: (params) => genericService.genericAPIDelete(getEndPointUsers(urlApi, params.data), params),
        updateUserWithDefaultCompany: (params) => genericService.genericAPIPost(getEndPointCompany(urlApi)+"/default_company", params),
    }
};

