import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import useStateWithCallback from 'use-state-with-callback'
import _ from 'lodash'
import { useContextDatasetGroup } from './ContextDatasetGroup'
import { useContextDatasetList } from './ContextDatasetList'
import { useContextEntity } from '../ContextEntity'
import { getEndPointScenarioTypeList } from '../../api/endPoints'
import axios from 'axios'
import { useContextUser } from '../ContextUser'
import { notifySuccess } from '../../components/commons/utils/notification/notification'
import { useContextI18n } from '../ContextI18n'
import { mapScenarioTypesAndVersions } from '../../components/analysis/mappers/mapperScenarioTypesAndVersions'
import { useContextLog } from '../ContextLog'
import { useContextAnalysis } from './ContextAnalysis'

// ContextDataset updates ContextDatasetList
// ContextDataset doesn t update ContextDatasetGroup
// ContextDataset needs idDatasetStructureSelected from ContextDatasetGroup
// ContextDataset needs getDatasetFromEditionList from ContextDatasetList
const ContextDataset = createContext({})
const useContextDataset = () => useContext(ContextDataset)

function UseContextDatasetProvider(props) {

    const { entityList } = useContextEntity()
    const { urlApi, getRequestHeaders } = useContextUser()
    const { i18n } = useContextI18n()
    const { addErrorLog } = useContextLog()
    const { needResolveUserDatasets } = useContextAnalysis()
    const {
        datasetListEdited,
        updateDataset: updateDatasetInContextDatasetList,
        getDatasetFromEditionList,
        needResolveCurrentDataset,
        setNeedResolveCurrentDataset
    } = useContextDatasetList()

    const { idDatasetStructureSelected, getDatasetStructureSelected } = useContextDatasetGroup()

    //const [isNeedUpdateDatasetStructure, setIsNeedUpdateDatasetStructure] = useState(false)

    const [needResolveScenarioTypeList, setNeedResolveScenarioTypeList] = useState(true);
    const [scenarioTypeList, setScenarioTypeList] = useState([])

    const [dataset, setDataset] = useStateWithCallback(undefined,
        (e) => {

            if (!_.isEqual(e, getDatasetFromEditionList(idDatasetStructureSelected))) {
                //     setIsNeedUpdateDatasetStructure(true)
                updateDatasetInContextDatasetList(e)
            }
        }
    )

    const getBodyRequest = useCallback(() => {
        if (!!dataset?.propertyIdList) {
            return { 'entities': dataset?.propertyIdList }
        } else {
            return { 'entities': [] }
        }

    }, [dataset?.propertyIdList])


    // SCENARIO TYPE LIST ---------------------------------------------
    const resolveScenarioTypeList = useCallback(async () => {

        const endPoint = getEndPointScenarioTypeList(urlApi)

        axios.post(endPoint, getBodyRequest(), getRequestHeaders())
            .then(response => {
                if (!!response?.erreur) throw response.erreur
                setNeedResolveScenarioTypeList(false)

                const deserializedMap = mapScenarioTypesAndVersions(response)

                let isEqual = true;

                isEqual = !!deserializedMap && !!scenarioTypeList && deserializedMap.length === scenarioTypeList.length
                if (isEqual) {
                    for (let index = 0; index < deserializedMap.length; index++) {
                        isEqual = isEqual && _.isEqual(deserializedMap[index], scenarioTypeList[index]);
                    }
                }

                if (!isEqual) {
                    setScenarioTypeList(deserializedMap)
                }
                notifySuccess(i18n('scenario.type.and.version.list.load.success'))
            }).catch(function (err) {
                addErrorLog(err)
                setNeedResolveScenarioTypeList(false)
            })

    }, [addErrorLog, getBodyRequest, getRequestHeaders, i18n, scenarioTypeList, urlApi])

    // Use effect used to update the current dataset if the datasetlist has changed
    useEffect(() => {
        if (needResolveCurrentDataset) {
            if (dataset !== datasetListEdited.find(ds => ds?.id === dataset?.id)) {
                setNeedResolveCurrentDataset(false)
                setDataset(getDatasetFromEditionList(idDatasetStructureSelected))
            }
        }
    }, [dataset, datasetListEdited, getDatasetFromEditionList, idDatasetStructureSelected, needResolveCurrentDataset, setDataset, setNeedResolveCurrentDataset])

    useEffect(() => {
        if (needResolveUserDatasets && !!dataset) {
            setDataset(undefined)
            setNeedResolveScenarioTypeList(true)
        }
    }, [dataset, needResolveUserDatasets, setDataset])

    useEffect(() => {
        setNeedResolveScenarioTypeList(true)
    }, [dataset?.propertyIdList])

    useEffect(() => {
        if (needResolveScenarioTypeList && !needResolveUserDatasets && !!dataset) {
            resolveScenarioTypeList()
        }
    }, [dataset, needResolveScenarioTypeList, needResolveUserDatasets, resolveScenarioTypeList])

    useEffect(() => {
        if (!needResolveUserDatasets) {
            if (!dataset || dataset.id !== idDatasetStructureSelected) {

                const dataset = getDatasetFromEditionList(idDatasetStructureSelected)
                setDataset(dataset)
            }
        }
    }, [dataset, getDatasetFromEditionList, idDatasetStructureSelected, needResolveUserDatasets, setDataset])

    // // when idDatasetStructureSelected change, we change dataset 
    useEffect(() => {
        if (!needResolveUserDatasets) {

            if (!!idDatasetStructureSelected && dataset?.id !== idDatasetStructureSelected) {

                if (!!getDatasetFromEditionList(idDatasetStructureSelected)) {
                    setDataset(getDatasetFromEditionList(idDatasetStructureSelected))
                } else {
                    setDataset(getDatasetStructureSelected(idDatasetStructureSelected))
                }
            }
        }
    }, [idDatasetStructureSelected, getDatasetFromEditionList, datasetListEdited, setDataset, getDatasetStructureSelected, dataset?.id, needResolveUserDatasets])



    const onDatasetModification = (value, attrName) => {
        onDatasetModificationArray([value], [attrName])
    }

    const onDatasetModificationArray = (arrayValue, arrayAttrName) => {
        //dataset[attrName] = e.target.value
        //const attrModified = {}
        const ds = { ...dataset }
        let isChanged = false;
        for (let i = 0; i < arrayAttrName.length; i++) {
            const attrName = arrayAttrName[i];
            const value = (i < arrayValue.length) ? arrayValue[i] : null;

            if (!_.isEqual(ds[attrName], value)) {
                ds[attrName] = value
                isChanged = true;
            }
        }

        if (isChanged) {
            setDataset(ds)
        }
    }

    // get of the currency list used by the porperties linked to the dataset
    const getLstCodeCurrencyFrom = () => {
        return getLstCodeCurrencyFromStatic(dataset, entityList)
    }


    return <ContextDataset.Provider value={{
        dataset,
        onDatasetModification,
        onDatasetModificationArray,
        getLstCodeCurrencyFrom,
        scenarioTypeList,
    }} {...props} />

}

export { UseContextDatasetProvider, useContextDataset }


// get of the currency list used by the porperties linked to the dataset
export const getLstCodeCurrencyFromStatic = (dataset, entityList) => {
    const set = new Set()

    //entityList
    dataset?.propertyIdList?.forEach(id => {

        const prop = entityList?.find(p => p?.id === id)
        if (!!prop) {
            set.add(prop?.hotel?.curr)
        }

    })

    return Array.from(set)
}


export const isScenarioValid = (scenario, dataset, entityList) => {

    return getLstFieldInErrorScenario(scenario, dataset, entityList).length < 1

}

export const getLstFieldInErrorScenario = (scenario, dataset, entityList) => {

    const lstFieldError = []

    if (!scenario?.label)
        lstFieldError.push("label")

    if (!scenario?.typeId)
        lstFieldError.push("typeId")

    if (!scenario?.versionId)
        lstFieldError.push("versionId")

    if (!scenario?.ranges || scenario?.ranges.length < 1)
        lstFieldError.push("ranges")

    const lstCodeCurrencyFrom = getLstCodeCurrencyFromStatic(dataset, entityList)

    const noNeedForex = !!dataset?.currencyCode && !!lstCodeCurrencyFrom && lstCodeCurrencyFrom.length === 1 && lstCodeCurrencyFrom[0] === dataset?.currencyCode
    if (!noNeedForex && !scenario?.forexOptions)
        lstFieldError.push("forexOptions")

    return lstFieldError;
}