import { Col, Row } from "react-bootstrap"
import { useContextI18n } from "../../../../../context/ContextI18n"
import InputInt from "../../numeric/InputInt"
import CommonSelector from "../../selector/CommonSelector"
import { FormatNegatifDisplay } from "../display/FormatNegatifDisplay"
import { PositionSymbolDisplay } from "../display/PositionSymbolDisplay"
import { SeparatorDecimalDisplay } from "../display/SeparatorDecimalDisplay"
import { SeparatorThousandDisplay } from "../display/SeparatorThousandDisplay"
import { ShorterFormatDisplay } from "../display/ShorterFormatDisplay"
import { FormatZeroDisplay, FormatZeroZero } from "../display/FormatZeroDisplay"
import CellNumberFormatted from "../cell/CellNumberFormatted"
import { Table } from "semantic-ui-react"
import _ from "lodash"

const FormPreferenceFormatterNumber = ({ formatterNumber, formatterNumberContexted, onChangeValue, readOnly, displayColorMode }) => {
    const { i18n } = useContextI18n()
    const prefixLabelFormInput = "form.preference.formatter.number."
    const prefixPositionSymbol = "formatter.position.symbol."
   // const prefixFormatNegatif = "formatter.format.negatif."
    const prefixSeparator = "formatter.separator."
    const prefixShorterFormat = "formatter.shorter.format."
    const prefixZeroFormat = "formatter.zero.format."
    const formatterNumberFusion = { ...formatterNumberContexted, ...formatterNumber }

    const keepOnlyFormatNegatif = (value) => {
        let res = _.cloneDeep(formatterNumberFusion)
        res.formatNegatif = value
        const formatterNumberFusionFormMinusDisplay = { ...formatterNumberContexted, ...res }
        return <CellNumberFormatted value={-123} symbol={""} formatterNumber={formatterNumberFusionFormMinusDisplay} />
    }


    return <>
        <div className="form-preference-formatter-number">
            <Row>
                <Col sm={6}>
                    <CommonSelector
                        value={formatterNumber?.positionSymbolCurrency}
                        labelInput={i18n(`${prefixLabelFormInput}positionSymbolCurrency`)}
                        onChangeValue={(value) => onChangeValue("positionSymbolCurrency", value)}
                        renderValue={(value) => !!value ? i18n(`${prefixPositionSymbol}${value}`) : i18n("formatter.format.default")}
                        valueList={PositionSymbolDisplay}
                        readOnly={readOnly}
                        displayColorMode={displayColorMode} />
                </Col>
                <Col sm={6}>
                    <CommonSelector
                        value={formatterNumber?.positionSymbol}
                        labelInput={i18n(`${prefixLabelFormInput}positionSymbol`)}
                        onChangeValue={(value) => onChangeValue("positionSymbol", value)}
                        renderValue={(value) => !!value ? i18n(`${prefixPositionSymbol}${value}`) : i18n("formatter.format.default")}
                        valueList={PositionSymbolDisplay}
                        readOnly={readOnly}
                        displayColorMode={displayColorMode} />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <CommonSelector
                        value={formatterNumber?.formatNegatif}
                        labelInput={i18n(`${prefixLabelFormInput}formatNegatif`)}
                        onChangeValue={(value) => onChangeValue("formatNegatif", value)}
                        renderValue={value => keepOnlyFormatNegatif(value)}
                        //renderValue={(value) => <CellNumberFormatted value={-1234} symbol={"$"} formatterNumber={keepOnlyFormatNegatif(formatterNumberFusion)} />}
                        //renderValue={(value) => !!value ? i18n(`${prefixFormatNegatif}${value}`) : i18n("formatter.format.default")}
                        valueList={FormatNegatifDisplay}
                        readOnly={readOnly}
                        displayColorMode={displayColorMode} />
                </Col>
                <Col sm={6}>
                    <CommonSelector
                        value={formatterNumber?.separatorThousand}
                        labelInput={i18n(`${prefixLabelFormInput}separatorThousand`)}
                        onChangeValue={(value) => onChangeValue("separatorThousand", value)}
                        renderValue={(value) => !!value ? i18n(`${prefixSeparator}${value?.name}`) : i18n("formatter.format.default")}
                        valueList={SeparatorThousandDisplay}
                        readOnly={readOnly}
                        displayColorMode={displayColorMode} />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <CommonSelector
                        value={formatterNumber?.separatorDecimal}
                        labelInput={i18n(`${prefixLabelFormInput}separatorDecimal`)}
                        onChangeValue={(value) => onChangeValue("separatorDecimal", value)}
                        renderValue={(value) => !!value ? i18n(`${prefixSeparator}${value?.name}`) : i18n("formatter.format.default")}
                        valueList={SeparatorDecimalDisplay}
                        readOnly={readOnly}
                        displayColorMode={displayColorMode} />
                </Col>
                <Col sm={4}>
                    <CommonSelector
                        value={formatterNumber?.shorterFormat}
                        labelInput={i18n(`${prefixLabelFormInput}shorterFormat`)}
                        onChangeValue={(value) => onChangeValue("shorterFormat", value)}
                        renderValue={(value) => !!value ? i18n(`${prefixShorterFormat}${value?.name}`) : i18n("formatter.format.default")}
                        valueList={ShorterFormatDisplay}
                        readOnly={readOnly}
                        displayColorMode={displayColorMode} />
                </Col>
                <Col sm={3}>
                    <InputInt
                        value={+formatterNumber?.precision}
                        label={i18n(`${prefixLabelFormInput}precision`)}
                        onChange={(value) => onChangeValue("precision", value)}
                        min={0}
                        max={6}
                        defaultValue={2}
                        displayColorMode={displayColorMode}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <CommonSelector
                        value={formatterNumber?.formatZero ?? FormatZeroZero}
                        labelInput={i18n(`${prefixZeroFormat}label`)}
                        onChangeValue={(value) => onChangeValue("formatZero", value)}
                        renderValue={(value) => !!value ? i18n(`${prefixZeroFormat}${value}`) : i18n("formatter.format.default")}
                        valueList={FormatZeroDisplay}
                        readOnly={readOnly}
                        displayColorMode={displayColorMode}
                    />
                </Col>
            </Row>
        </div>
        <Table className="preference-number-format-example">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='2'>
                        {i18n("form.preference.formatter.example")}
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>
                        {i18n("form.preference.formatter.number.no.formatted.value")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {i18n("form.preference.formatter.number.formatted.value")}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Row>
                <Table.Cell><div>0 AED</div></Table.Cell>
                <Table.Cell><CellNumberFormatted value={0} formatterNumber={formatterNumberFusion} symbol={"AED"} /></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell><div>67.89%</div></Table.Cell>
                <Table.Cell><CellNumberFormatted value={67.89} formatterNumber={formatterNumberFusion} symbol={"%"} /></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell><div>1000€</div></Table.Cell>
                <Table.Cell><CellNumberFormatted value={1000} formatterNumber={formatterNumberFusion} symbol={"€"} /></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell><div>-34567.89€</div></Table.Cell>
                <Table.Cell><CellNumberFormatted value={-34567.89} formatterNumber={formatterNumberFusion} symbol={"€"} /></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell><div>1234567.89</div></Table.Cell>
                <Table.Cell> <CellNumberFormatted value={1234567.89} formatterNumber={formatterNumberFusion} symbol={""} /></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell><div>-1234567890.12$</div></Table.Cell>
                <Table.Cell><CellNumberFormatted value={-1234567890.12} formatterNumber={formatterNumberFusion} symbol={"$"} /></Table.Cell>
            </Table.Row>
        </Table>
    </>
}

export default FormPreferenceFormatterNumber