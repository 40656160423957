import { mapperList } from "./mapperList"

export const mapperCurrency = (json, id) => {

    const currency = {
        "id" : !!json?.id ? json.id : id,
        "code" :  json["code"],
        "symbol" :  json["symbol"],
        "name" :  json["name"],
        "symbol_native" :  json["symbolNative"],
        "name_plural" :  json["namePlural"],
        "decimal_digits" :  json["decimalDigits"],
        "rounding" :  json["rounding"],
        "update_dt" :  json["updateDt"],
    }
    return currency
}

const mapperCurrencyList = (json) => mapperList(json, mapperCurrency) 

export default mapperCurrencyList