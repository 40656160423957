import axios from 'axios'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import useStateWithCallback from 'use-state-with-callback'
import { getEndPointCurrency, getEndPointUserPreferenceCRUD, PREF_TYPE_DATASETGROUP } from '../../api/endPoints'
import mapperCurrencyList from '../../components/analysis/mappers/mapperCurrencyList'
import { mapperDatasetGroupList, mapperDatasetList } from '../../components/analysis/mappers/mapperDataSetGroupList'
import { ERROR_RESOLVE_DATASET_GROUP, ERROR_RESOLVE_USER_DATASETS } from '../../components/assets/const/ErrorCode'
import { notifySuccess } from '../../components/commons/utils/notification/notification'
import { useContextI18n } from '../ContextI18n'
import { useContextLog } from '../ContextLog'
import { useContextModule } from '../ContextModule'
import { useContextUser } from '../ContextUser'
import _ from "lodash"
import { PreferenceCommonService } from '../../service/PreferenceCommonService'

const ContextAnalysis = createContext({})
const useContextAnalysis = () => useContext(ContextAnalysis)

function UseContextAnalysisProvider(props) {

    const { module } = useContextModule()

    const [topFilterOpened, setTopFilterOpened] = useState(false)
    const [needResolveUserDatasets, setNeedResolveUserDatasets] = useState(false)

    // Liste des groups 
    const [datasetGroupList, setDatasetGroupList] = useState()

    // Liste des datasets 
    const [datasetList, setDatasetList] = useState()

    const [lstPropertyLibrary, setLstPropertyLibraryPROTECTED] = useState()


    const setLstPropertyLibrary = (library) => {
        const checkedLibrary = Array.isArray(library) ? library : [];
        const uuidList = []
        let isError = false
        checkedLibrary.forEach((lib) => {
            if (uuidList.includes(lib.id)) {
                isError = true
                alert.error("Check library ERROR", library)
            } else {
                uuidList.push(lib.id)
            }

        })

        if (!isError) {
            setLstPropertyLibraryPROTECTED(library)
        }


    }

    const [needResolveCurrencyList, setNeedResolveCurrencyList] = useState(true)
    const [currencyList, setCurrencyList] = useStateWithCallback([], () => {
        setNeedResolveCurrencyList(false)
    })

    const { i18n } = useContextI18n()
    const { urlApi, getRequestHeaders } = useContextUser()
    const { addErrorLog } = useContextLog()

    const preferenceCommonService = PreferenceCommonService(urlApi, getRequestHeaders, useContextLog())

    const resolveUserDatasets = useCallback(async () => {

        if (needResolveUserDatasets) {
            setNeedResolveUserDatasets(false)
            preferenceCommonService.getPreference({
                onSuccess: (response) => {
                    if (!!response?.erreur) throw response.erreur
                    setDatasetGroupList(mapperDatasetGroupList(response?.data))
                    setDatasetList(mapperDatasetList(response?.data))
                    setLstPropertyLibrary(response?.data?.lstPropertyLibrary ?? [])
                    notifySuccess(i18n('datasets.list.load.success'))
                },
                onError: (e) => {
                    addErrorLog(e, ERROR_RESOLVE_USER_DATASETS)
                    setDatasetGroupList([])
                    setDatasetList([])
                    setLstPropertyLibrary([])
                },
                finally: () => { }
            }, null, module?.name, PREF_TYPE_DATASETGROUP)

        }
    }, [needResolveUserDatasets, preferenceCommonService, module?.name, i18n, addErrorLog])


    // CURRENCY LIST ------------------------------------------------
    const resolveCurrencyList = useCallback(async () => {
        const endPoint = getEndPointCurrency(urlApi)
        axios.get(endPoint, getRequestHeaders())
            .then(response => {
                if (!!response?.erreur) throw response.erreur
                setCurrencyList(mapperCurrencyList(response))
                notifySuccess(i18n('currency.list.load.success'))

            }).catch(function (err) {
                addErrorLog(err)
                setCurrencyList([])
            })

    }, [addErrorLog, getRequestHeaders, i18n, setCurrencyList, urlApi])

    useEffect(() => {
        if (needResolveCurrencyList) resolveCurrencyList()
    }, [currencyList, needResolveCurrencyList, resolveCurrencyList])


    useEffect(() => {

        if (needResolveUserDatasets) {
            resolveUserDatasets()
        }

    }, [needResolveUserDatasets, resolveUserDatasets])

    useEffect(() => {
        if (!!module) {
            setNeedResolveUserDatasets(true)
        }
    }, [module])


    const getBodyRequest = useCallback(() => {
        return {
            //                screen: screenDef.name,
            idScreenDef: null,
            preferenceType: PREF_TYPE_DATASETGROUP,
            code: 'default',
            module: module?.name

        }
    }, [module?.name])

    const makeRequestSave = useCallback(async (datasetGroupListCopy, datasetListCopy) => {

        const bodyRequest = getBodyRequest()
        const endPoint = getEndPointUserPreferenceCRUD(urlApi)

        bodyRequest.jsonValue = {
            "lstDatasetGroup": datasetGroupListCopy,
            "lstDatasets": datasetListCopy,
            "lstPropertyLibrary": lstPropertyLibrary
        }

        axios.put(endPoint, bodyRequest, getRequestHeaders())
            .then(response => {
                if (!!response?.erreur) throw response.erreur

                setNeedResolveUserDatasets(true)

            }).catch(function (err) {
                addErrorLog(err, ERROR_RESOLVE_DATASET_GROUP)
            })
    }, [addErrorLog, getBodyRequest, getRequestHeaders, lstPropertyLibrary, urlApi])

    // remove datasetGroup is allowed only when there is no changes in datasetGroupList and datasetList
    const onSaveRemoveDatasetGroup = useCallback(async (datasetGroupToRemove) => {
        var datasetGroupListCopy = Array.from(datasetGroupList).filter(dsg => dsg?.id !== datasetGroupToRemove?.id);
        makeRequestSave(datasetGroupListCopy, datasetList)
    }, [datasetGroupList, datasetList, makeRequestSave])


    const onSaveContextAnalysis = useCallback(async (groupEdited, lstDatasetCreateToBeSaved, lstDatasetUpdateToBeSaved, lstDatasetDeleteToBeSaved) => {
        var datasetGroupListCopy = Array.from(datasetGroupList);
        if (!datasetGroupListCopy) datasetGroupListCopy = []

        if (!!groupEdited) {
            const indexInList = datasetGroupListCopy?.findIndex(dsg => dsg.id === groupEdited?.id)
            if (indexInList !== -1) {
                datasetGroupListCopy.splice(indexInList, 1, groupEdited)
            } else {

                datasetGroupListCopy.push(groupEdited)
            }
        }

        var datasetListCopy = Array.from(datasetList);
        if (!datasetListCopy) datasetListCopy = []

        for (let index = 0; index < lstDatasetCreateToBeSaved.length; index++) {
            datasetListCopy.push(lstDatasetCreateToBeSaved[index]);
        }

        for (let index = 0; index < lstDatasetUpdateToBeSaved.length; index++) {
            const indexInList = datasetListCopy?.findIndex(dsg => dsg.id === lstDatasetUpdateToBeSaved[index]?.id)
            if (indexInList !== -1) datasetListCopy.splice(indexInList, 1, lstDatasetUpdateToBeSaved[index])
        }

        for (let index = 0; index < lstDatasetDeleteToBeSaved.length; index++) {
            const indexInList = datasetListCopy?.findIndex(dsg => dsg.id === lstDatasetDeleteToBeSaved[index]?.id)
            if (indexInList !== -1) datasetListCopy.splice(indexInList, 1)
        }


        // clean of the datasetGroup?.structure if missing dataset
        for (let index = 0; index < datasetGroupListCopy.length; index++) {
            const datasetGroup = datasetGroupListCopy[index];
            for (let index2 = datasetGroup?.datasetStructure?.length - 1; index2 >= 0; index2--) {
                const structure = datasetGroup?.datasetStructure[index];
                if (structure?.type === 'dataset') {
                    const indexInList = datasetListCopy?.findIndex(dsg => dsg.id === structure?.id)
                    if (indexInList === -1) datasetGroup?.datasetStructure.splice(index2, 1) // we remove the missing datasets in datasetStructure
                }
            }
        }

        makeRequestSave(datasetGroupListCopy, datasetListCopy)

    }, [datasetGroupList, datasetList, makeRequestSave])



    const addDatasetGroupInDatasetGroupList = (datasetGroup) => {
        var datasetGroupListCopy = Array.from(datasetGroupList);
        datasetGroupListCopy.push(datasetGroup)
        setDatasetGroupList(datasetGroupListCopy);
    }

    const updateDatasetGroupInDatasetGroupList = (datasetGroup) => {
        var datasetGroupListCopy = Array.from(!!datasetGroupList ? datasetGroupList : []);
        const indexInList = datasetGroupListCopy?.findIndex(dsg => dsg.id === datasetGroup?.id)
        if (indexInList !== -1) {
            datasetGroupListCopy.splice(indexInList, 1, datasetGroup)
            setDatasetGroupList(datasetGroupListCopy);
        } else {
            addDatasetGroupInDatasetGroupList(datasetGroup)
        }
    }

    const getDsGroupById = (idDsGroup) => {
        return { ...datasetGroupList?.find(d => d?.id === idDsGroup) }
    }

    // we take dataset from DatasetList
    const getDatasetFromEditionList = (idDataset) => {
        return { ...datasetList?.find(d => d?.id === idDataset) }
    }

    const isDatasetExist = (idDataset) => !!getDatasetFromEditionList(idDataset)?.id

    const isVarianceExist = (idVariance) => !!datasetGroupList?.flatMap(g => g?.datasetStructure)?.filter(s => s?.type === 'variance')?.find(s => s?.id === idVariance)


    const updatePropertyLibraryItem = (libraryItem) => {
        // in all relevant datasets (propertyLibraryId === libraryItem.id)
        // => update propertyListLabel (label) and propertyIdList (lstProperties)
        const newDatasetList = datasetList.map(dataset => {
            if (dataset?.propertyLibraryId === libraryItem?.id) {
                const newDataset = _.cloneDeep(dataset)
                newDataset.propertyListLabel = libraryItem.label
                newDataset.propertyIdList = libraryItem.lstProperties
                return newDataset
            } else {
                return dataset
            }
        })
        setDatasetList(newDatasetList)

        // update the library item
        const newLstPropertyLibrary = lstPropertyLibrary?.filter(item => item.id !== libraryItem?.id) ?? []
        newLstPropertyLibrary.push(libraryItem)


        setLstPropertyLibrary(newLstPropertyLibrary)
    }

    // Delete item from the library (idLibraryItem is uuid)
    const supprPropertyLibraryItem = (idLibraryItem) => {
        // in all relevant datasets, update attribute propertyLibraryId to undefined 
        const newDatasetList = datasetList.map(dataset => {
            if (dataset.propertyLibraryId === idLibraryItem) {
                const newDataset = _.cloneDeep(dataset)
                newDataset.propertyLibraryId = undefined
                return newDataset
            } else {
                return dataset
            }
        })
        setDatasetList(newDatasetList)

        // delete the library item
        const newLibrary = lstPropertyLibrary.filter(item => item.id !== idLibraryItem)
        setLstPropertyLibrary(newLibrary)
    }




    return <ContextAnalysis.Provider value={{

        currencyList,
        datasetGroupList,
        datasetList, // full dataset list of the user
        needResolveUserDatasets,
        topFilterOpened,
        setTopFilterOpened,
        getDatasetFromEditionList, isDatasetExist, isVarianceExist,
        onSaveContextAnalysis,
        onSaveRemoveDatasetGroup,
        addDatasetGroupInDatasetGroupList,
        updateDatasetGroupInDatasetGroupList,
        getDsGroupById,
        setNeedResolveUserDatasets, // set to true to reset all context
        lstPropertyLibrary, setLstPropertyLibrary,
        updatePropertyLibraryItem, supprPropertyLibraryItem
    }} {...props} />
}


export { UseContextAnalysisProvider, useContextAnalysis }
