import { createContext, useCallback, useContext, useEffect, useReducer } from 'react'
import { notifyError } from '../components/commons/utils/notification/notification'
import { useContextLog } from './ContextLog'
import { useContextUser } from './ContextUser'
import { ERROR_METRIC } from '../components/assets/const/ErrorCode'
import { useContextLoader } from './ContextLoader'
import { AppVersionningService } from '../service/AppVersionningService'

const ContextAppVersionning = createContext({})
const useContextAppVersionning = () => useContext(ContextAppVersionning)

function UseContextAppVersionningProvider({ children }) {

    /***************************** CONTEXTS *******************************/
    const { setShowLoader } = useContextLoader();
    const { addErrorLog } = useContextLog();
    const { urlApi, getRequestHeaders } = useContextUser()

    /***************************** SERVICE *******************************/
    const appVersionningService = AppVersionningService(urlApi, getRequestHeaders, useContextLog());

    /***************************** ACTIONS FOR REDUCER *******************************/
    const actions = {
        SET_APP_VERSIONNING: "SET_APP_VERSIONNING",
    }

    /***************************** INITIAL STATE  *******************************/
    const initialState = {
        appVersionning: undefined
    }

    /***************************** REDUCER  *******************************/
    const reducer = (state, action) => {
        switch (action.type) {
            case actions.SET_APP_VERSIONNING:
                return {
                    ...state,
                    appVersionning: action.appVersionning
                }
            default:
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState);

    const setAppVersionning = useCallback((appVersionning) => {
        dispatch({ type: actions.SET_APP_VERSIONNING, appVersionning: appVersionning })
    }, [actions.SET_APP_VERSIONNING])

    const resolveAppVersionning = useCallback(() => {
        appVersionningService.resolveAppVersionning({
            onSuccess: (response) => {
                setAppVersionning(response.data);
                setShowLoader(false)
            },
            onError: (error) => {
                addErrorLog(error, ERROR_METRIC)
                notifyError(error);
            },
            finally: (response) => {
            }
        });
    }, [addErrorLog, appVersionningService, setShowLoader, setAppVersionning]);



    useEffect(() => {
        if (!state?.appVersionning) {
            resolveAppVersionning()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.appVersionning])


    return <ContextAppVersionning.Provider value={{
        appVersionning: state.appVersionning
    }} children={children} />

}

export { UseContextAppVersionningProvider, useContextAppVersionning }