import { Form } from "react-bootstrap";
import PictoMandatory from '../../utils/picto/common/PictoMandatory';

const InputWithLabel = ({ label, isMandatory, input, textComplementaire, className = '', errorMsg }) => {

    return (
        <Form.Group className={`input-with-label ${className}`}>
            {!!label && (
                <Form.Label className="input-with-label-label">{label}
                    {isMandatory && (
                        <PictoMandatory />
                    )}
                </Form.Label>
            )}

            {!!input && (input)}

            {!!textComplementaire && (
                <Form.Text className="text-muted">
                    {textComplementaire}
                </Form.Text>
            )}

            {!!errorMsg && (
                <Form.Text className="text-muted input-error">
                    {errorMsg}
                </Form.Text>
            )}
        </Form.Group>
    )
}

export default InputWithLabel