import CommonPictoContainer from './CommonPictoContainer'
import PropTypes from 'prop-types'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import './PictoMandatory.scss'


const PictoMandatory = ({onClick, text, tooltipText, tooltipPlacement="top", disabled=false,  className, sizePicto}) => {
    return  <CommonPictoContainer icon={faStar} className={`picto-mandatory ${!!className?className:""}`} onClick={onClick} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement} disabled={disabled} sizePicto={sizePicto} />
}

PictoMandatory.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default PictoMandatory