import { faCheck } from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import CommonButton from "./CommonButton"

const ButtonValidate = ({onClick, text, tooltipText, tooltipPlacement, disabled=false, variant="primary"}) => {
    return <CommonButton variant={variant} className="button-validate" onClick={onClick} icon={faCheck} disabled={disabled} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement}/>
}

ButtonValidate.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default ButtonValidate