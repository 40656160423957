
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './PictoWithTooltip.scss'

const PictoWithTooltip = ({ icon, tooltipText, placement = 'top', className, size, onClick }) => {

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {tooltipText}
    </Tooltip>
  );

  

  return (
    <>

      {!!tooltipText ? (
        <OverlayTrigger
          placement={placement}
          delay={{ show: 150, hide: 400 }}
          overlay={renderTooltip}
        >
          <FontAwesomeIcon className={`picto-with-tooltip ${className}`} icon={icon} size={size} onClick={onClick}  />
        </OverlayTrigger>
      ): (
        <>
           <FontAwesomeIcon className={`picto-with-tooltip ${className}`} icon={icon} size={size} onClick={onClick}  />
        </>
      )}
    </>
  )
}

PictoWithTooltip.propTypes = {
  icon: PropTypes.any.isRequired,
  tooltipText: PropTypes.any,
  placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  size: PropTypes.string,
  onClick: PropTypes.func
}


export default PictoWithTooltip





