import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './CommonTooltip.scss';

const CommonTooltip = ({ tooltipText, placement = 'top', children, displayContainer="inline-block", className="default"}) => {

    const renderTooltip = (props) => (
        <Tooltip {...props} className={`${className}-tooltip-common`}>
          {tooltipText}
        </Tooltip>
    );
    
    return (
        <>
            <OverlayTrigger
                placement={placement}
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip}
            >
                <div className={`container-tooltip ${className}`} style={{display: displayContainer}}>{children}</div>
            </OverlayTrigger>
        </>
    )

}

CommonTooltip.propTypes = {
    placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    size: PropTypes.string
}
  
  
export default CommonTooltip