import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  analisysPath,
  dimensionsPath,
  formulaPath,
  homePath,
  variablesPath,
  usersPath,
  companyPath,
  entityPath,
  licensePath,
  transactionDetailsPath,
  predicTemplateDeployerPath,
  predicSectionInitiatorPath,
  predicDeployedFinalizerPath,
  narrativeCategoryPath,
  fileImportPath,
  fileUploadForJsonPath,
  metricsPath,
  predicHomePath,
  panelPredicTemplateDeployerPath,
  panelPredicTemplateDesignerMasterPath,
  panelPredicTemplateDesignerNoMasterPath,
  panelPredicSectionInitiatorPath,
  panelPredicSectionReviewerPath,
  panelPredicSectionApproverPath,
  panelPredicDeployedFinalizerPath,
  panelPredicDeployedUserImpliedPath,
  predicDeployedGanttMonitoring,
  predicDeployedGlobalModification,
  predicSectionReviewerPath,
  predicSectionApproverPath,
  predicDeployedGlomi,
  predicTemplateDesignerPathPrivate,
  predicTemplateDesignerPathPublic,
  perspectivePath,
  panelPredicInitiatorPath,
  panelPredicReviewerPath,
  panelPredicApproverPath,
  maintenancePath
} from "../../../navigation/routes";
import { useContextUser } from "../../../context/ContextUser";
import { useContextI18n } from "../../../context/ContextI18n";
import LogPanelContexted from "../log-panel/LogPanelContexted";
import LoadingInfinityLogContexted from "../navigation/LoadingInfinityLogContexted";
import { ERROR_RESOLVE_ACCESS_DICTIONNARY, ERROR_RESOLVE_FORMATTER_NUMBER, ERROR_RESOLVE_USER_DATASETS } from "../../assets/const/ErrorCode";
import PanelDefaultCompany from "../../landing/defautCompany/PanelDefaultCompany";
import { APPROVER, DEPLOYER, DESIGNER, FINALIZER, INITIATOR, REVIEWER } from "../../predict/predicTemplate/template/constants/PredicUserType";
import { useState } from "react";
import { useEffect } from "react";
import { PREDIC_DEPLOYED_APPROVER, PREDIC_DEPLOYED_USER_IMPLIED, TEMPLATES_DEPLOYER, TEMPLATES_DESIGNER_NO_MASTER, PREDIC_DEPLOYED_FINALIZER, PREDIC_DEPLOYED_INITIATOR, PREDIC_DEPLOYED_REVIEWER, PREDIC_DEPLOYED_GANTT_MONITORING, PREDIC_DEPLOYED_GLOBAL_MODIFICATION } from "../../predict/ListPredicNavigationItem";
import { TEMPLATES_DESIGNER_MASTER } from "../../predict/ListPredicNavigationItem";
//import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { notifyError } from "../utils/notification/notification";
import { TypeDescriptiveRight_RW } from "../../analysis/dynamic_dashboard/users/TypeDescriptiveRight";
import { useParams as props } from "react-router-dom";

const AnalysisDashboard = React.lazy(() => import("../../analysis/dashboard/AnalysisDashboard"));
const PanelPredictList = React.lazy(() => import("../../predict/PanelPredictList"));
const PredicHome = React.lazy(() => import("../../predict/PredicHome"));
const PanelPredic = React.lazy(() => import("../../predict/predicTemplate/form/PanelPredic"));
const PanelPredicDeployedTodoList = React.lazy(() => import("../../predict/predicDeployedTodoList/PanelPredicDeployedTodoList"));
const PanelPredicSectionTodoList = React.lazy(() => import("../../predict/predicSectionTodoList/PanelPredicSectionTodoList"));
const PanelPredicDeployedSection2 = React.lazy(() => import("../../predict/predicDeployedSection/PanelPredicDeployedSection2"));
const PanelPredicDeployedMultiPurposeContexted = React.lazy(() => import("../../predict/predicDeployedMultiPurpose/common/PanelPredicDeployedMultiPurposeContexted"));
const PanelPredicDeployedSectionFinalizerContexted = React.lazy(() => import("../../predict/predicDeployedSectionGrid/PanelPredicDeployedSectionFinalizerContexted"));
const PanelLanding = React.lazy(() => import("../../landing/PanelLanding"));
const PanelFormulaContexted = React.lazy(() => import("../../param/formula/PanelFormulaContexted"));
const PanelDimensions = React.lazy(() => import("../../param/dimensions/PanelDimensions"));
const PanelMetricsContexted = React.lazy(() => import("../../param/metrics/PanelMetricsContexted"));
const PanelVariablesContexted = React.lazy(() => import("../../param/variable/PanelVariablesContexted"));
const PanelUsersContexted = React.lazy(() => import("../../param/users/PanelUsersContexted"));
const PanelCompanyContexted = React.lazy(() => import("../../param/company/PanelCompanyContexted"));
const PanelEntityContexted = React.lazy(() => import("../../param/entity/PanelEntityContexted"));
const PanelPerspectiveContexted = React.lazy(() => import("../../param/perspective/PanelPerspectiveContexted"));

const PanelLicense = React.lazy(() => import("../../param/licence/PanelLicense"));
const PanelTransactionDetails = React.lazy(() => import("../../analysis/dynamic_dashboard/transaction_details/PanelTransactionDetails"));
const PanelNarrativeCategoryContexted = React.lazy(() => import("../../param/category/PanelNarrativeCategoryContexted"));
const PanelFileImportContexted = React.lazy(() => import("../../param/fileImport/PanelFileImportContexted"));
const PanelMaintenanceContexted = React.lazy(() => import("../../param/manageMaintenance/PanelMaintenanceContexted"));
const FileUploadForJson = React.lazy(() => import("../../param/fileUploadForJson/fileUploadForJson"));
const PanelPredicDeployedGlomiContexted = React.lazy(() => import("../../predict/predicDeployedMultiPurpose/globalModification/glomi/PanelPredicDeployedGlomiContexted"));


const Spa = () => {

  const { i18n } = useContextI18n();

  const { getFunctionalModuleTree, userCompany, user } = useContextUser();
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (showLoading && getFunctionalModuleTree) {
      setShowLoading(false)
    }
  }, [getFunctionalModuleTree, showLoading])

  const loaderSuspense = <LoadingInfinityLogContexted
    title={i18n("MainViewer.loading")}
    message={i18n("loading.pref.user")}
    showLoading={true}
    onCloseCrashMode={() => setShowLoading(false)}
  />

  const isUserAllowed = user?.descriptiveRight === TypeDescriptiveRight_RW

  const ProtectedRoute = ({ path, children }) => {
    if (isUserAllowed) {
      return (
        children
      )
    } else {
      notifyError(i18n("protected.route.right"))
      return (
        <span>{path}</span>

        /* <Redirect to="/" /> */

      )
    }
  }

  return (
    <div className="spa">

      {userCompany === null &&
        <PanelDefaultCompany />
      }

      {userCompany !== null &&
        <Router>
          <Routes>

            <Route path={homePath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelLanding />
              </Suspense>
            } />

            <Route path={analisysPath} element={
              <Suspense fallback={loaderSuspense} >
                <AnalysisDashboard />
              </Suspense>
            } />


            <Route path={predicHomePath} element={
              <Suspense fallback={loaderSuspense}>
                <PredicHome />
              </Suspense>
            } />


            <Route path={homePath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelLanding />
              </Suspense>
            } />


            <Route path={analisysPath} element={
              <Suspense fallback={loaderSuspense} >
                <AnalysisDashboard />
              </Suspense>
            } />


            <Route path={predicHomePath} element={
              <Suspense fallback={loaderSuspense}>
                <PredicHome />
              </Suspense>
            } />


            <Route path={panelPredicTemplateDesignerNoMasterPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredictList view={TEMPLATES_DESIGNER_NO_MASTER}
                  titleButtonMenu={i18n("predic.navigation.design.private.templates.page")} />
              </Suspense>
            } />

            <Route path={panelPredicTemplateDesignerMasterPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredictList view={TEMPLATES_DESIGNER_MASTER}
                  titleButtonMenu={i18n("predic.navigation.design.public.templates.page")} />
              </Suspense>
            } />

            <Route
              path={predicTemplateDesignerPathPrivate + "/:id"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredic predicUserType={DESIGNER}
                    pathParent={panelPredicTemplateDesignerNoMasterPath}
                    titleButtonMenu={i18n("predic.navigation.design.title.page")}
                    {...props} />
                </Suspense>
              }
            />

            <Route
              path={predicTemplateDesignerPathPublic + "/:id"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredic predicUserType={DESIGNER} pathParent={panelPredicTemplateDesignerMasterPath} titleButtonMenu={i18n("predic.navigation.design.title.page")} {...props} />
                </Suspense>
              }
            />


            <Route path={panelPredicTemplateDeployerPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredictList view={TEMPLATES_DEPLOYER} titleButtonMenu={i18n("predic.navigation.templates.ready.to.deploy.page")} />
              </Suspense>
            } />

            <Route
              path={predicTemplateDeployerPath + "/:id"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredic predicUserType={DEPLOYER} pathParent={panelPredicTemplateDeployerPath} titleButtonMenu={i18n("predic.navigation.templates.ready.to.deploy.title.page")} {...props} />
                </Suspense>
              }
            />

            <Route path={panelPredicDeployedUserImpliedPath + "/:templateStatut"} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredictList view={PREDIC_DEPLOYED_USER_IMPLIED} titleButtonMenu={i18n("predic.navigation.templates.deployed")} path={panelPredicDeployedUserImpliedPath} />
              </Suspense>
            } />


            <Route
              path={predicDeployedGlobalModification + "/:id"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedMultiPurposeContexted {...props} view={PREDIC_DEPLOYED_GLOBAL_MODIFICATION} predicUserType={FINALIZER} />
                </Suspense>
              }
            />

            <Route
              path={predicDeployedGlomi + "/:idPredic/:uuidSection"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedGlomiContexted  {...props} />
                </Suspense>
              }
            />

            <Route
              path={predicDeployedGanttMonitoring + "/:id"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedMultiPurposeContexted {...props} view={PREDIC_DEPLOYED_GANTT_MONITORING} />
                </Suspense>
              }
            />

            <Route path={panelPredicInitiatorPath + "/:templateStatut"} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredicDeployedTodoList predicUserType={INITIATOR} view={PREDIC_DEPLOYED_INITIATOR} path={panelPredicInitiatorPath} />
              </Suspense>
            } />


            <Route
              path={panelPredicSectionInitiatorPath + "/:idPredic"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicSectionTodoList predicUserType={INITIATOR} view={PREDIC_DEPLOYED_INITIATOR}  {...props} />
                </Suspense>
              }
            />

            <Route
              path={predicSectionInitiatorPath + "/:idPredic/:uuidSection"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedSection2 predicUserType={INITIATOR} titleButtonMenu={i18n("predic.navigation.initiator.sections.to.fill.title.page")} {...props} />
                </Suspense>
              }
            />

            <Route path={panelPredicReviewerPath + "/:templateStatut"} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredicDeployedTodoList predicUserType={REVIEWER} view={PREDIC_DEPLOYED_REVIEWER} path={panelPredicReviewerPath} />
              </Suspense>
            } />

            <Route
              path={panelPredicSectionReviewerPath + "/:idPredic"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicSectionTodoList view={PREDIC_DEPLOYED_REVIEWER} predicUserType={REVIEWER}  {...props} />
                </Suspense>
              }
            />

            <Route
              path={predicSectionReviewerPath + "/:idPredic/:uuidSection"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedSection2 predicUserType={REVIEWER} titleButtonMenu={i18n("predic.navigation.reviewer.sections.to.fill.title.page")} {...props} />
                </Suspense>
              }
            />

            <Route path={panelPredicApproverPath + "/:templateStatut"} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredicDeployedTodoList predicUserType={APPROVER} view={PREDIC_DEPLOYED_APPROVER} path={panelPredicApproverPath} />
              </Suspense>
            } />

            <Route
              path={panelPredicSectionApproverPath + "/:idPredic"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicSectionTodoList view={PREDIC_DEPLOYED_APPROVER} predicUserType={APPROVER}  {...props} />
                </Suspense>
              }
            />

            <Route
              path={predicSectionApproverPath + "/:idPredic/:uuidSection"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedSection2 predicUserType={APPROVER} titleButtonMenu={i18n("predic.navigation.approver.sections.to.fill.title.page")} {...props} />
                </Suspense>
              }
            />

            <Route path={panelPredicDeployedFinalizerPath + "/:templateStatut"} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPredictList view={PREDIC_DEPLOYED_FINALIZER} titleButtonMenu={i18n("predic.navigation.finalizer.sections.to.fill.page")} path={panelPredicDeployedFinalizerPath} />
              </Suspense>
            } />

            <Route
              path={predicDeployedFinalizerPath + "/predic/:id"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedSectionFinalizerContexted view={PREDIC_DEPLOYED_FINALIZER} predicUserType={FINALIZER} />
                </Suspense>
              }
            />

            <Route
              path={predicDeployedFinalizerPath + "/section/:idPredic/:uuidSection"}
              element={
                <Suspense fallback={loaderSuspense}>
                  <PanelPredicDeployedSection2 predicUserType={FINALIZER} titleButtonMenu={i18n("predic.navigation.finalizer.sections.to.fill.title.page")} {...props} />
                </Suspense>
              }
            />

            <Route path={formulaPath} element={
              <ProtectedRoute>
                <Suspense fallback={loaderSuspense}>
                  <PanelFormulaContexted />
                </Suspense>
              </ProtectedRoute>
            } />

            <Route path={dimensionsPath} element={
              <ProtectedRoute>
                <Suspense fallback={loaderSuspense}>
                  <PanelDimensions />
                </Suspense>
              </ProtectedRoute>
            } />

            <Route path={metricsPath} element={
              <ProtectedRoute>
                <Suspense fallback={loaderSuspense}>
                  <PanelMetricsContexted />
                </Suspense>
              </ProtectedRoute>
            } />

            <Route path={variablesPath} element={
              <ProtectedRoute>
                <Suspense fallback={loaderSuspense}>
                  <PanelVariablesContexted />
                </Suspense>
              </ProtectedRoute>
            } />

            <Route path={usersPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelUsersContexted />
              </Suspense>
            } />

            <Route path={companyPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelCompanyContexted />
              </Suspense>
            } />

            <Route path={entityPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelEntityContexted />
              </Suspense>
            } />

            <Route path={perspectivePath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelPerspectiveContexted />
              </Suspense>
            } />

            <Route path={licensePath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelLicense />
              </Suspense>
            } />

            <Route path={transactionDetailsPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelTransactionDetails />
              </Suspense>
            } />

            <Route path={narrativeCategoryPath} element={
              <ProtectedRoute>
                <Suspense fallback={loaderSuspense}>
                  <PanelNarrativeCategoryContexted />
                </Suspense>
              </ProtectedRoute>
            } />

            <Route path={fileImportPath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelFileImportContexted />
              </Suspense>
            } />

            <Route path={fileUploadForJsonPath} element={
              <Suspense fallback={loaderSuspense}>
                <FileUploadForJson />
              </Suspense>
            } />

            <Route path={maintenancePath} element={
              <Suspense fallback={loaderSuspense}>
                <PanelMaintenanceContexted />
              </Suspense>
            } />

            <Route path="/" element={
              <Suspense fallback={loaderSuspense}>
                <PanelLanding />
              </Suspense>
            } />

          </Routes>
        </Router>
      }
      <LoadingInfinityLogContexted
        title={i18n("MainViewer.loading")}
        message={i18n("loading.pref.user")}
        listErrorCode={[ERROR_RESOLVE_ACCESS_DICTIONNARY,
          ERROR_RESOLVE_FORMATTER_NUMBER,
          ERROR_RESOLVE_USER_DATASETS]}
        showLoading={showLoading}
        onCloseCrashMode={() => setShowLoading(false)}
      />

      <LogPanelContexted />
    </div >
  );
};

Spa.propTypes = {};

export default Spa;
