import { DataGrid } from "devextreme-react";
import { Selection } from "devextreme-react/data-grid";
import { useCallback, useEffect, useState } from "react";
import { useContextI18n } from "../../../../context/ContextI18n";
import { wait } from "../../../assets/utils";
import ButtonTimes from "../../button/ButtonTimes";
import ButtonValidate from "../../button/ButtonValidate";
import { dataGridPagingAndPager, reduceObjectToKey } from "../../dx-form/utils/dataUtils";
import { buildSelectedRowKeys } from "../../dx-form/utils/dataUtils";

const MenuGrid = ({
  className,
  fieldValue,
  dataSource, // MANDATORY
  dataGridRef,
  keyExpr, //
  remoteOperations,
  datagridChildren,
  selection = "single", // "single" by default or "multiple"
  columnMinWidth = 50,
  afterDblClickOnRow,
  onCancel,
  onValidate,
  valueStateOnGrid,
  onCellPrepared,
  onEditorPreparing,
  notDeletableKeys
}) => {


  const getInitialTemporaryValue = useCallback((fieldValue) => {
    let initValue = fieldValue


    if (!Array.isArray(fieldValue) && (typeof fieldValue !== 'object')) {

      initValue = [fieldValue]

    } else if (Array.isArray(fieldValue)) {

      initValue = fieldValue.map((o) => {
        if (typeof o === 'object') {
          return reduceObjectToKey(keyExpr, o)
        } else {
          return o
        }
      })
    } else if (typeof fieldValue === 'object') {
      initValue = reduceObjectToKey(keyExpr, fieldValue)
    }

    return initValue

  }, [keyExpr])

  // valeur stockée ici pour éviter les renders sur la liste
  const [temporaryValue, setTemporaryValue] = useState();



  const { i18n } = useContextI18n();

  const onDblClick = async (e) => {
    if (selection === "single") {
      const grid = dataGridRef?.current?.instance;
      const datas = grid?.getSelectedRowsData();
      await wait(500);
      afterDblClickOnRow(datas);
    }
  };

  const [focusedRowKey, setFocusedRowKey] = useState()

  // Initialisation
  useEffect(() => {
    const v = getInitialTemporaryValue(fieldValue)
    if (Array.isArray(v)) setTemporaryValue(v)
    // if single value, wrap it in an array
    else setTemporaryValue([v])

    // set Focused row for auto navigate to focused row
    if (selection === 'single') {
      //setFocusedRowKey is waiting for an item
      if (Array.isArray(v) && v.length === 1) {
        setFocusedRowKey(v[0])
      } else if (!!v) {
        setFocusedRowKey(v)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [lastTimeChange, setLastTimeChange] = useState(Date.now())

  return (
    <div className={`${className} menu-grid`}>
      {valueStateOnGrid ? (
        <DataGrid
          ref={dataGridRef}
          columnMinWidth={columnMinWidth}
          dataSource={dataSource}
          showBorders={true}
          hoverStateEnabled={true}
          keyExpr={Array.isArray(dataSource) ? keyExpr : undefined} // keyExpr is allowed in dxGrid only if datasource is an Array
          selectedRowKeys={buildSelectedRowKeys(keyExpr, temporaryValue)}

          onSelectionChanged={(e) => {

            // time delay on clicks
            const millis = Date.now()
            setLastTimeChange(millis)
            if (millis - lastTimeChange < 300) return

            const lstRows = e.selectedRowsData
            const lstKeys = lstRows.map((o) =>
              reduceObjectToKey(keyExpr, o)
            );

            if (Array.isArray(notDeletableKeys) && notDeletableKeys.length > 0) {
              // if some items are not deletable
              lstKeys.push(...notDeletableKeys)
            }

            setTemporaryValue(lstKeys);
          }}
          onRowDblClick={onDblClick}
          allowColumnResizing={true}
          columnResizingMode="widget"
          columnAutoWidth={true}
          remoteOperations={remoteOperations}
          onCellPrepared={onCellPrepared}
          onEditorPreparing={onEditorPreparing}
          onInitialized={(e) => {
            if (selection === "single") {
              setFocusedRowKey(e?.component?.getSelectedRowKeys()[0])
            }
          }}
          autoNavigateToFocusedRow={selection === "single"}
          focusedRowKey={focusedRowKey}
          style={{ maxHeight: "calc(100vh - 140px)" }}
          syncLookupFilterValues={false} // IMPORTANT : following to packages updates, we need to disable syncLookupFilterValues to let the lookup work properly
        >

          {/* {dataGridPagingAndPager()} */}
          {dataGridPagingAndPager(50, [10, 20, 30, 50, 100])}

          <Selection mode={selection} />
          {datagridChildren}
        </DataGrid>
      ) : (
        <DataGrid

          ref={dataGridRef}
          columnMinWidth={columnMinWidth}
          dataSource={dataSource}
          showBorders={true}
          hoverStateEnabled={true}
          keyExpr={keyExpr}
          onRowDblClick={onDblClick}
          allowColumnResizing={true}
          columnResizingMode="widget"
          columnAutoWidth={true}
          remoteOperations={remoteOperations}
          onCellPrepared={onCellPrepared}
          onEditorPreparing={onEditorPreparing}
          style={{ maxHeight: "calc(100vh - 140px)" }}
        >
          {dataGridPagingAndPager()}
          <Selection mode={selection} />
          {datagridChildren}
        </DataGrid>
      )
      }

      <div className="footer">
        <ButtonTimes text={i18n("cancel")} onClick={onCancel} />
        <ButtonValidate
          disabled={false}
          text={i18n("validation")}
          onClick={onValidate}
        />
      </div>
    </div >
  );
};

export default MenuGrid;
