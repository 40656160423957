export const FormatZeroZero = "zero" ;

export const FormatZeroHyphen = "hyphen" ;

export const FormatZeroDisplay = [FormatZeroHyphen, FormatZeroZero]

export const applyFormatZero = (formatZero, val) => { 
    switch (formatZero) {
        case FormatZeroHyphen :
            return "-"
        case FormatZeroZero :
        default : 
            return val
    }
}
