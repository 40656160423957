import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useContextAnalysis } from './ContextAnalysis'
import { v4 as uuid } from "uuid"
import { useContextI18n } from '../ContextI18n'
import { getNewUniqueCode } from '../../components/assets/utils'
import { isDatasetValid, useContextDatasetList } from './ContextDatasetList'
import { useContextEntity } from '../ContextEntity'
import { onBeanContextModification, onBeanContextModificationArray } from '../CommonMethodContext'
import { useContextPerspective } from '../ContextPerspective'
import { useContextScreenDef } from '../ContextScreenDef'
import useStateWithCallback from 'use-state-with-callback'


const ContextDatasetGroup = createContext({})
const useContextDatasetGroup = () => useContext(ContextDatasetGroup)

function UseContextDatasetGroupProvider(props) {

    const { getDsGroupById, datasetGroupList } = useContextAnalysis()
    const { datasetListEdited, getLstCodesUsed, addDataset, getDatasetFromEditionList, isDatasetListModified, isVarianceValid } = useContextDatasetList()

    const {perspective, setPerspective} = useContextPerspective()
    const { i18n } = useContextI18n();
    const { entityList } = useContextEntity();

    const { datasetGroupIdSelected, setDatasetGroupIdSelected} = useContextScreenDef()

    const [datasetGroup, setDatasetGroup] = useStateWithCallback(null,
        (dsg) => {
            if (!! dsg && dsg?.id !== datasetGroupIdSelected) {
                setDatasetGroupIdSelected(dsg.id)
            }
        }
    );
    
    const [needResolveDatasetGroup, setNeedResolveDatasetGroup] = useState(true);


    const [idDatasetStructureSelected, setIdDatasetStructureSelected] = useState(null);


    useEffect(() => {

        if(needResolveDatasetGroup && !!datasetGroupList && datasetGroupList.length > 0) {
           
            if(! datasetGroup || datasetGroupList.findIndex((ds) => ds.id === datasetGroup?.id) === -1 ) {
                
                // cas ou on prend le premier par defaut
                setDatasetGroup(datasetGroupList[0])
                setNeedResolveDatasetGroup(false)
            } else {
                // cas ou on prend le bon
                setDatasetGroup(datasetGroupList.find((ds) => ds.id === datasetGroup?.id))
                setNeedResolveDatasetGroup(false)
            }
        }
    }, [datasetGroup, datasetGroupList, needResolveDatasetGroup, setDatasetGroup])


    useEffect(() => {
        if(!!datasetGroupList && datasetGroupList.length > 0 )
            setNeedResolveDatasetGroup(true)
    }, [datasetGroupList])


    // initialisation idDatasetStructureSelected
    useEffect(() => {

       // console.log(`ContextDatasetGroup useEffect idDatasetStructureSelected = ${idDatasetStructureSelected}`)
        if (!idDatasetStructureSelected && !!datasetGroup?.datasetStructure && datasetGroup?.datasetStructure?.length > 0)
            setIdDatasetStructureSelected(datasetGroup?.datasetStructure[0]?.id)

        if (idDatasetStructureSelected && !!datasetGroup?.datasetStructure && !datasetGroup?.datasetStructure?.find(d => d?.id === idDatasetStructureSelected)) 
            setIdDatasetStructureSelected(datasetGroup?.datasetStructure[0]?.id)

        if (idDatasetStructureSelected && !datasetGroup?.datasetStructure) 
            setIdDatasetStructureSelected(undefined)

    }, [idDatasetStructureSelected, datasetGroup])


    const isNewDatasetGroupMode = useMemo(()=>{
        return !((!!datasetGroup ? getDsGroupById(datasetGroup?.id) : null)?.id)
    },[datasetGroup, getDsGroupById]) 

    
    const isDataGroupModified = useCallback(() => {
        const oldDatasetGroup = !!datasetGroup ? getDsGroupById(datasetGroup?.id) : null

        // on verifie si il y a eu une modification
        if (!datasetGroup && !oldDatasetGroup)
            return (false)
        else
            return (!_.isEqual(datasetGroup, oldDatasetGroup))
    }, [datasetGroup, getDsGroupById])


    const isFormModified = useCallback(() => {
        return isDataGroupModified() || isDatasetListModified

    }, [isDataGroupModified, isDatasetListModified])

    useEffect (() => {
        if (!!datasetGroup?.perspective && perspective?.id !== datasetGroup?.perspective?.id ) {
            setPerspective(datasetGroup?.perspective)
        }
    },[datasetGroup?.perspective, perspective?.id, setPerspective])


    const getDatasetStructureSelected = (idDatasetStructure) => {
        if (!!datasetGroup) {
            return datasetGroup?.datasetStructure?.find(d => d?.id === idDatasetStructure)
        }
    }




    const getDatasetStructureSelectedCode = (idDatasetStructure) => {
        const struct = getDatasetStructureSelected(idDatasetStructure)
        if (!!struct) {
            if (struct.type === 'dataset') {
                const ds = datasetListEdited?.find(dataset => dataset?.id === struct?.id)
                return ds?.code
            } else {
                // variance
                return struct?.code
            }

        }
    }

    const getDatasetStructureSelectedVisibility = (idDatasetStructure) => {
        const struct = getDatasetStructureSelected(idDatasetStructure)
        return struct?.visible
    }

    const updateDatasetStructureSelected = (datasetStructure) => {

        console.log("updateDatasetStructureSelected")
        const index = datasetGroup?.datasetStructure?.indexOf(datasetGroup?.datasetStructure?.find(d => d?.id === idDatasetStructureSelected));
        if (index !== -1) {
            const dsg = JSON.parse(JSON.stringify(datasetGroup));
            dsg.datasetStructure[index] = datasetStructure;
            setDatasetGroup(dsg)
        }
    }

    const updateDatasetStructure = (datasetStructure) => {

        console.log("updateDatasetStructure")
        const index = datasetGroup?.datasetStructure?.indexOf(datasetGroup?.datasetStructure?.find(d => d?.id === datasetStructure?.id));
        if (index !== -1) {
            const dsg = JSON.parse(JSON.stringify(datasetGroup));
            dsg.datasetStructure[index] = datasetStructure;
            setDatasetGroup(dsg)
        }
    }

    const updateAllDatasetStructure = (lstDatasetReordered) => {

        const dsg = JSON.parse(JSON.stringify(datasetGroup));
        dsg.datasetStructure = lstDatasetReordered;
        setDatasetGroup(dsg)
    }

    const createDatasetForStructure = () => {
        const newDataset = {
            id: uuid(),
            label: i18n("dataset.new.default.label"),
            code: getNewUniqueCode("DATASET", getLstCodesUsed(datasetGroup)),
            currencyCode: 'USD',
            lstScenarios : [], 
            propertyIdList: undefined,
            propertyListLabel: undefined
        }
        const newDatasetStructure = {
            id: newDataset.id,
            type: "dataset",
            visible: true
        }
        const dsg = JSON.parse(JSON.stringify(datasetGroup));
        if (!dsg.datasetStructure) {
            dsg.datasetStructure = []
        }
        dsg.datasetStructure.push(newDatasetStructure);
        setDatasetGroup(dsg)
        addDataset(newDataset)
        setIdDatasetStructureSelected(newDataset.id)
    }

    const createVarianceForStructure = () => {
        const newStructVariance = {
            type: 'variance',
            id: uuid(),
            label: i18n("dataset.variance.new.default.label"),
            code: getNewUniqueCode("VARIANCE", getLstCodesUsed(datasetGroup)),
            id_1: undefined,
            id_2: undefined,
            visible: true
        }
        var dsg = JSON.parse(JSON.stringify(datasetGroup));
        if (!dsg.datasetStructure) {
            dsg.datasetStructure = []
        }
        dsg.datasetStructure.push(newStructVariance);
        setDatasetGroup(dsg);
        setIdDatasetStructureSelected(newStructVariance.id)
    }

    const onDuplicateElementStructure = (oldId) => {
        let elementCopied = JSON.parse(JSON.stringify(getDatasetStructureSelected(oldId)))

        const newId = uuid()
        elementCopied.id = newId;

        if (elementCopied.type === "dataset") {
            //let dataSetSelected = JSON.parse(JSON.stringify(getDatasetFromEditionList(oldId)))

            const dataSetSelected =  _.cloneDeep(getDatasetFromEditionList(oldId))
            dataSetSelected.id = newId;
            dataSetSelected.code = getNewUniqueCode(dataSetSelected.code, getLstCodesUsed(datasetGroup));
            
            addDataset(dataSetSelected)

        } else if (elementCopied.type === "variance") {
            elementCopied.code = getNewUniqueCode(elementCopied.code, getLstCodesUsed(datasetGroup));
        }
        const dsg =  _.cloneDeep(datasetGroup)
        dsg.datasetStructure.push(elementCopied);
        setDatasetGroup(dsg)
        setIdDatasetStructureSelected(newId)
    }

    const onDeleteElementStructure = (idStructure) => {
        const dsg =  _.cloneDeep(datasetGroup)
        dsg.datasetStructure = dsg.datasetStructure.filter(e => e.id !== idStructure)
        setDatasetGroup(dsg)
        if (idStructure === idDatasetStructureSelected)
            setIdDatasetStructureSelected(null)
    }



    // gere les modifs dans le datasetGroup
    const onDatasetGroupModification = (value, param) => {
        onBeanContextModification(datasetGroup, param, value, (bean) => { setDatasetGroup(bean) })
    }
       

    const onDatasetGroupModificationArray = (value, param) => onBeanContextModificationArray(datasetGroup, param, value, (bean) => { setDatasetGroup(bean) })

    const onReorderEditedGroup = (structElements) => {

        const lstReorderIds = structElements.map(el => { return el.id })
        //console.log(lstReorderIds)
        const groupModified = JSON.parse(JSON.stringify(datasetGroup));
        // reorder structures inside the group
        groupModified.datasetStructure = lstReorderIds.map(id => {
            return datasetGroup.datasetStructure.find(structure => structure.id === id)
        })
        //console.log(groupModified)
        setDatasetGroup(groupModified)
        //setNeedResolveDatasetObjectList(true)
    }

    const getLstFieldInErrorDataGroup = useCallback((datasetGroupTest) => {

        if (!datasetGroupTest)
            datasetGroupTest = datasetGroup

        const lstFieldError = []

        if (!datasetGroupTest?.label)
            lstFieldError.push("label")

        if (!datasetGroupTest?.perspective)
            lstFieldError.push("perspective")

        if (!datasetGroupTest?.datasetStructure || datasetGroupTest?.datasetStructure.length < 1)
            lstFieldError.push("datasetStructure")

        return lstFieldError;
    }, [datasetGroup])

    const getNbDatasetInErrorInDatasetGroup = useCallback((datasetGroupTest) => {

        if (!datasetGroupTest)
            datasetGroupTest = datasetGroup

        let nbDatatsetInError = 0;

        datasetGroupTest?.datasetStructure?.filter(e => e.type === 'dataset').map(e => getDatasetFromEditionList(e?.id)).forEach(d => { if (!isDatasetValid(d, entityList)) nbDatatsetInError++ })
        return nbDatatsetInError
    }, [datasetGroup, getDatasetFromEditionList, entityList])

    const getNbVarianceInErrorInDatasetGroup = useCallback((datasetGroupTest) => {

        if (!datasetGroupTest)
            datasetGroupTest = datasetGroup

        let nbVarianceInError = 0;

        datasetGroupTest?.datasetStructure?.filter(e => e.type === 'variance').forEach(v => { if (!isVarianceValid(v)) nbVarianceInError++ })
        return nbVarianceInError
    }, [datasetGroup, isVarianceValid])

    const isDataGroupValid = useCallback((datasetGroupTest) => {

        if (!datasetGroupTest)
            datasetGroupTest = datasetGroup

        return getLstFieldInErrorDataGroup(datasetGroupTest).length + getNbDatasetInErrorInDatasetGroup(datasetGroupTest) + getNbVarianceInErrorInDatasetGroup(datasetGroupTest) < 1
    }, [datasetGroup, getLstFieldInErrorDataGroup, getNbDatasetInErrorInDatasetGroup, getNbVarianceInErrorInDatasetGroup])


    const renderMessageListErrorInDatasetGroup = (messageBefore, datasetGroupTest) => {

        if (!datasetGroupTest)
            datasetGroupTest = datasetGroup

        let render = getLstFieldInErrorDataGroup(datasetGroupTest).map(e => <li key={e}>{i18n("datasetgroup.error." + e)}</li>)
        if (!render)
            render = []

        if (getNbDatasetInErrorInDatasetGroup(datasetGroupTest) > 1)
            render.push(<li key={"ndataset"} >{i18n("datasetgroup.error.n.dataset", { nb: getNbDatasetInErrorInDatasetGroup(datasetGroupTest) })}</li>)

        if (getNbDatasetInErrorInDatasetGroup(datasetGroupTest) === 1)
            render.push(<li key={"1dataset"}>{i18n("datasetgroup.error.1.dataset")}</li>)

        if (getNbVarianceInErrorInDatasetGroup(datasetGroupTest) > 1)
            render.push(<li key={"nvariance"}>{i18n("datasetgroup.error.n.variance", { nb: getNbVarianceInErrorInDatasetGroup(datasetGroupTest) })}</li>)

        if (getNbVarianceInErrorInDatasetGroup(datasetGroupTest) === 1)
            render.push(<li key={"1variance"}>{i18n("datasetgroup.error.1.variance")}</li>)

        return <>{i18n(messageBefore)}<ul style={{ textAlign: "left" }}>{render}</ul></>
    }

    return <ContextDatasetGroup.Provider value={{
        datasetGroup, setDatasetGroup,
        onDatasetGroupModification,
        onDatasetGroupModificationArray,
        getDatasetStructureSelected,
        getDatasetStructureSelectedCode,
        getDatasetStructureSelectedVisibility,
        idDatasetStructureSelected,
        setIdDatasetStructureSelected,
        updateDatasetStructureSelected,
        updateDatasetStructure,
        onDuplicateElementStructure,
        updateAllDatasetStructure,
        onDeleteElementStructure,
        isNewDatasetGroupMode,
        isFormModified,
        createVarianceForStructure,
        createDatasetForStructure,
        onReorderEditedGroup,
        isDataGroupValid,
        renderMessageListErrorInDatasetGroup
    }} {...props} />
}

export { UseContextDatasetGroupProvider, useContextDatasetGroup }



