//PENSER A TRADUIRE DANS LES FICHIERS I18N

export const ERROR_DEFAULT = 'defaultError'
export const ERROR_RESOLVE_ACCESS_DICTIONNARY = 'resolveAccessDictionary'
export const ERROR_LOADING_DATA_GRID_PROPERTIES = 'dataGridProperties'
export const ERROR_LOADING_LST_FORMULA = 'lstFormula'
export const ERROR_FORMULA = "formula"
export const ERROR_VARIABLE = "variable"
export const ERROR_METRIC = "metric"
export const ERROR_ANALYSIS_EVAL_SCREEN = "analysisEvalScreen"
export const ERROR_DSC_SECREEN_DEF = "dscScreenDef"
export const ERROR_APP_VERSIONNING = "appVersionning"
export const ERROR_DIMENSION_MAPPING = "dimensionMapping"
export const ERROR_PREDIC_TEMPLATE = "predicTemplate"
export const ERROR_PREDIC_TEMPLATE_PATCH_LST_HISTORIC = "predicTemplatePatchLstHistoric"
export const ERROR_PREDIC_DEPLOYED_PATCH_LST_HISTORIC = "predicDeployedPatchLstHistoric"
export const ERROR_GLOBAL_UPDATE_HISTORICS = "globalUpdateHistorics"
export const ERROR_GLOBAL_UPDATE_CALCULATIONS_COMPONENTS_LST_SECTIONS = "globalUpdateCalculationComponentsLstSections"
export const ERROR_GLOBAL_UPDATE_HISTORICS_DEPLOYED = "globalUpdateHistoricsDeployed"
export const ERROR_PREDIC_TEMPLATE_SECTION_PATCH_LST_HISTORIC = "predicTemplateSectionPatchLstHistoric"
export const ERROR_PREDIC_TEMPLATE_GET_HISTORIC_LST_SECTIONS = "getHistoricLstSections"
export const ERROR_PREDIC_DEPLOYED_GET_HISTORIC_LST_SECTIONS = "getHistoricLstSections"
export const ERROR_PREDIC_TEMPLATES_AVAILABLE = "lstPredicTemplateAvailable"
export const ERROR_PREDIC_TEMPLATE_DELETE = "predicTemplateDelete"
export const ERROR_PREDIC_TEMPLATE_DUPLICATE = "predicTemplateDuplicate"
export const ERROR_PREDIC_TEMPLATE_CHECKING = "predicTemplateChecking"
export const ERROR_PREDIC_TEMPLATE_DEPLOYING = "predicTemplateDeploying"
export const ERROR_PREDIC_TEMPLATE_TRANSACTION_VERSIONING_INVOLVED = "predicTemplateTransactionVersioningInvolved"
export const ERROR_PREDIC_TEMPLATE_PATCH_LINK_SECTIONS = "predicTemplatePatchLinkSections"
export const ERROR_PREDIC_SECTIONS_CHECKING_PARTIAL = "predicSectionsCheckingPartial"
export const ERROR_PREDIC_TEMPLATE_EXPORT = "predicTemplateExport"
export const ERROR_PREDIC_SECTIONS_DISABLE_CHECKING = "predicSectionsDisableChecking"
export const ERROR_PREDIC_SECTIONS_ACTIVATE_CHECKING = "predicSectionsActivateChecking"
export const ERROR_PREDIC_TEMPLATE_SECTION = "predicTemplateSection"
export const ERROR_PREDIC_TEMPLATE_SECTION_IMPORT_EXTERNAL_SECTION = "predicTemplateSectionImportExternalSection"
export const ERROR_PREDIC_TEMPLATE_LST_VERY_LAZY_SECTIONS = "predicTemplateVeryLazySections"
export const ERROR_PREDIC_TEMPLATE_SECTION_PATCH_LINE_DATAS = "predicTemplateSectionPatchLineDatas"
export const ERROR_PREDIC_TEMPLATE_SECTION_WFL = "predicTemplateSectionWorkflow"
export const ERROR_PREDIC_TEMPLATE_SECTION_VIEWS = "predicTemplateSectionViews"
export const ERROR_PREDIC_TEMPLATE_SECTION_HISTORIC = "predicTemplateSectionHistoric"
export const ERROR_PREDIC_TEMPLATE_SECTION_DUPLICATE = "predicTemplateSectionDuplicate"
export const ERROR_PREDIC_TEMPLATE_SECTION_GLOBAL_UPDATE_CALCULATION_COMPONENTS = "predicTemplateSectionGlobalUpdateCalculationComponents"
export const ERROR_PREDIC_TEMPLATE_SECTION_DELETE = "predicTemplateSectionDelete"
export const ERROR_PREDIC_TEMPLATE_SECTION_DUPLICATE_DETAIL = "predicTemplateSectionDuplicateDetail"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE_DUPLICATE = "predicTemplateSectionLineDuplicate"
export const ERROR_PREDIC_TEMPLATE_SECTION_DUPLICATE_BLOC_LINES = "predicTemplateSectionDuplicateBlocLines"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE = "predicTemplateSectionLine"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE_FROM_LST_UUID = "predicTemplateSectionLineFromLstUuid"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE_REMINDERS = "predicTemplateSectionLineReminders"
export const ERROR_PREDIC_TEMPLATE_SECTION_LINE_DEPENDENCIES = "predicTemplateSectionLineDependencies"
export const ERROR_PREDIC_TEMPLATE_SECTION_GLOBAL_UPDATE = "predicTemplateSectionGlobalUpdate"
export const ERROR_PREDIC_TEMPLATE_SLIPPING_RULES = "predicTemplateSlippingRules"
export const ERROR_PREDIC_TEMPLATE_CURRENCY_MANUAL_LINES = "predicTemplateUpdateCurrencyManualLines"
export const ERROR_PREDIC_TEMPLATE_SECTION_CLEAR_UNHELPFUL_RELATIONSHIPS = "predicTemplateSectionClearUnhelpfulRelationships"
export const ERROR_PREDIC_TEMPLATE_SECTION_SUBMIT = "predicTemplateSectionSubmit"
export const ERROR_PREDIC_TEMPLATE_SECTION_APPROVE = "predicTemplateSectionApprove"
export const ERROR_PREDIC_TEMPLATE_SECTION_DISAPPROVE = "predicTemplateSectionDisapprove"
export const ERROR_PREDIC_TEMPLATE_SECTION_REVIEW = "predicTemplateSectionReview"
export const ERROR_PREDIC_TEMPLATE_SECTION_UPDATE_WORKFLOW_DESIGN_GLOBAL = "predicTemplateSectionUpdateWorfklowDesignGlobal"
export const ERROR_PREDIC_TEMPLATE_SECTION_UPDATE_WORKFLOW_DPT_POS_USER = "predicTemplateSectionUpdateWorfklowDptPosUser"
export const ERROR_PREDIC_DEPLOYED_SECTION_UPDATE_WORKFLOW_DPT_POS_USER = "predicDeployedSectionUpdateWorfklowDptPosUser"
export const ERROR_PREDIC_DEPLOYED_PATCH_INFOS = "predicDeployedPatchInfos"
export const ERROR_PREDIC_DEPLOYED = "predicDeployed"
export const ERROR_PREDIC_DEPLOYED_SECTION_LINE_DUPLICATE = "predicDeployedSectionLineDuplicate"
export const ERROR_PREDIC_WFL_COUNTER = "predicWflCounter"
export const ERROR_PREDIC_DEPLOYED_CHECK_FINALIZE = "predicDeployedCheckFinalize"
export const ERROR_PREDIC_DEPLOYED_GET_LST_TYPE_DIM_USED = "predicDeployedGetLstTypeDimUsed"
export const ERROR_PREDIC_DEPLOYED_GET_MAP_DIM_USED = "predicDeployedGetMapDimUsed"
export const ERROR_PREDIC_DEPLOYED_FINALIZE = "predicDeployedFinalize"
export const ERROR_PREDIC_DEPLOYED_UNFINALIZE = "predicDeployedUnfinalize"
export const ERROR_PREDIC_DEPLOYED_TRANSPOSE = "predicDeployedErrorTranspose"
export const ERROR_PREDIC_DEPLOYED_CHECK_DRIVER_BASE_VALIDITY = "predicDeployedCheckDriverBaseValidity"
export const ERROR_PREDIC_DEPLOYED_CALCUL_DRIVER_BASE = "predicDeployedErrorCalculDriverBase"
export const ERROR_PREDIC_DEPLOYED_CALCUL_HISTORIC_DATA = "predicDeployedErrorCalculHistoricData"
export const ERROR_PREDIC_DEPLOYED_SECTION_EXPORT = "predicDeployedSectionExport"
export const ERROR_PREDIC_DEPLOYED_LINES_CHARACTERISTICS_EXPORT = "predicDeployedLinesCharacteristics"
export const ERROR_PREDIC_DEPLOYED_EXPORT_SECTIONS = "predicDeployedExportSections"
export const ERROR_PREDIC_DEPLOYED_SECTION_IMPORT = "predicDeployedSectionImport"
export const ERROR_PREDIC_DEPLOYED_SECTION_HISTORIC = "predicDeployedSectionHistoric"
export const ERROR_PREDIC_DEPLOYED_RESOLVE_LAZY_LST_PREDIC_SECTION = "predicDeployedResolveLazyLstPredicSection"
export const ERROR_PREDIC_CALCULATION_QUEUE = "predicCalculationQueue"
export const ERROR_PREDIC_DEPLOYED_CALCUL_AND_APPROVE_LST_SECTIONS = "calculAndApproveLstSections"
export const ERROR_PREDIC_DEPLOYED_DISAPPROVE_ALL_SECTIONS = "disapproveAllSections"
export const ERROR_PREFERENCE_COMMON = "preferenceCommon"
export const ERROR_FORM_USER = "formUser"
export const ERROR_COMPANY = "company"
export const ERROR_CALCULATION_QUEUE = "calculationQueue"
export const ERROR_RESOLVE_COMPANY_LIST = "companyList"
export const ERROR_RESOLVE_LICENSE_LIST = "licenseList"
export const ERROR_CURRENCY_CONVERSION = "currencyConversion"
export const ERROR_ENTITY = "entity"
export const ERROR_FILE_IMPORT = "fileImport"
export const ERROR_TRANSACTION_VERSIONING = "transactionVersioning"
export const ERROR_NARRATIVE_CATEGORY = "narrativeCategory"
export const ERROR_STATUS_INPUT = "statusInput"
export const ERROR_FORM_DATASETGROUP = "formDatasetGroup"
export const ERROR_LICENSE = "license"
export const ERROR_LOADING_CONTEXT_FOREX_OPTIONS = 'contextForexOptions'
export const ERROR_RESOLVE_ENTITY_LIST = 'resolveEntityList'
export const ERROR_RESOLVE_FORMATTER_NUMBER = 'resolveFormatterNumber'
export const ERROR_RESOLVE_DATASET_GROUP = 'resolveDatasetGroup'
export const ERROR_RESOLVE_USER_DATASETS = 'resolveUserDatasets'
export const ERROR_RESOLVE_USER_GRID = 'resolveUserGrid'
export const ERROR_RESOLVE_USER = 'resolveUser'
export const ERROR_SAVE_USER = 'saveUser'
export const ERROR_TRANSACTION_DETAIL = 'resolveTransactionDetailsDimensions'
export const ERROR_NUMBER_FORMATTER_BLOC_LINES = 'resolveNumberFomatterBlocLines'
export const ERROR_PREDIC_DEPLOYED_DELETE = "predicDeployedDelete"
export const ERROR_PREDIC_DEPLOYED_PARAMS_IRA_SAVE = "predicDeployedParamsIraSave"
export const ERROR_PREDIC_DEPLOYED_PARAMS_IRA_SAVE_PROCESS = "predicDeployedParamsIraSaveProcess"
export const ERROR_PERSPECTIVE_UPDATE_NOMENCLATURE = "perspectiveUpdateNomenclature"
export const ERROR_PREDIC_TEMPLATE_EXPORT_SECTIONS_CHARACTERISTICS = "exportPredicTemplateSectionsCharacteristics"
export const ERROR_REMARKS = "remarks"
export const ERROR_PREDIC_DEPLOYED_EXPORT_SECTIONS_CHARACTERISTICS = "exportPredicDeployedSectionsCharacteristics"
export const ERROR_PREDIC_FINALIZED_EXPORT_REVERSE_MAPPING = "exportPredicFinalizedExportReverseMapping"
export const ERROR_PREDIC_FINALIZED_PERFORMS_REVERSE_MAPPING = "performsPredicFinalizedReverseMapping"
export const ERROR_PREDIC_FINALIZED_GET_REVERSE_MAPPING_ARBITRARY_DATA = "getReverseMappingArbitraryData"
export const ERROR_PREDIC_FINALIZED_PATCH_REVERSE_MAPPING_ARBITRARY = "patchReverseMappingArbitrary"
export const ERROR_RESOLVE_LST_USERS = "resolveLstUsers"
export const ERROR_EXPORT_PREDICTIVE_STATISTICS = "predictiveStatistics"
export const ERROR_MAINTENANCE = "manageMaintenance"
export const ERROR_IMPORT_DEPLOYED_SECTIONS = "importDeployedSections"
export const ERROR_RESOLVE_CALCULATION_QUEUE_DETAIL = "resolveClaculationQueueDetail"
export const ERROR_EXPORT_TRANSACTION_DATA = "exportTransactionData"

