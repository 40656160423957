import React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import PopupParamsContent from '../popup-params/PopupParamsContent'
import { useContextI18n } from '../../../../context/ContextI18n'



const ButtonParams = ({showPopupFormattage, setShowPopupFormattage, activated, isDescriptiveRightReadOnly}) => {

    const { i18n } = useContextI18n()

    const popover = (
        <Popover className="top-menu-popover-options">
            <Popover.Header as="h3">{i18n("top-menu.params.title")}</Popover.Header>
            <Popover.Body>
                <PopupParamsContent 
                    showPopupFormattage={showPopupFormattage} 
                    setShowPopupFormattage={setShowPopupFormattage}
                    isDescriptiveRightReadOnly={isDescriptiveRightReadOnly} />
            </Popover.Body>
        </Popover>
    )

    return (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
            <Button variant="default" disabled={!activated}>
                <FontAwesomeIcon icon={faGlobe} />
            </Button>
        </OverlayTrigger>

    )

}


export default ButtonParams

