import _ from "lodash"
import { getLstCodeCurrencyFromStatic } from "../ContextDataset";


const getDate = (d) => {
    let date = new Date()
    if(!!d){
        date = (d instanceof Date) ? d : new Date(d)
    }
    return date
}

/**
 * It takes a date object and returns a string in the format of "YYYY/MM/DD"
 * @param date - Date
 * @returns A string as '2024/12/31'
 */
export const parseDateWithTzToYYYYMMDD = (date) => {
    return  `${date?.getFullYear()}/${date?.getMonth() + 1}/${date?.getDate()}`
}

/**
 * 
 * @param {Date} date 
 * @returns String as '2024/12/31 00:00:00'
 */
export const parseDateWithTzToYYYYMMDD_HHMMSS = (date) => {
    return `${parseDateWithTzToYYYYMMDD(date)} ${date?.getHours()}:${date?.getMinutes()}:${date?.getSeconds()}`
}

export const getDateFromString = (str) => {
    return getDate(str)
}

/**
 * It takes a date range object with a start and end date, and returns a date range object with the
 * start and end date in the format YYYY-MM-DD
 * @param range - {start: "2020-01-01T00:00:00.000Z", end: "2020-01-31T23:59:59.999Z"}
 * @returns {
 *     start: "2020-01-01",
 *     end: "2020-01-31"
 * }
 */
export const parseRangeDateWithTzToRangeYYYMMDD = (range) => {
    const rangeTmp = {}
    const dateStart = new Date(range?.start)
    rangeTmp.start = parseDateWithTzToYYYYMMDD(dateStart) 
    const dateEnd = new Date(range?.end)
    rangeTmp.end = parseDateWithTzToYYYYMMDD(dateEnd)
    return rangeTmp
}



export const getRangeDateFromRangeStringYYYYMMDD = (rangeStrings) => {

    const rangeWithDates = {
        start : getDate(rangeStrings?.start),
        end :  getDate(rangeStrings?.end)
    }

    return rangeWithDates

}


export const parserDatasetListShown = (dsGroup, datasetList, entityList) => {

    let res = _.cloneDeep(datasetList); 


    // we need to limit the datasetList we send depending on datasetGroup structure
    const isDataStructUseDataset = (dStruct, dataset) => {
        return (dStruct?.type === "dataset" && dStruct?.id === dataset?.id) ||
                (dStruct?.type === "variance" && ( dStruct?.id_1 === dataset?.id || dStruct?.id_2 === dataset?.id ) )
    }

    res = res?.filter(dataset => dsGroup?.datasetStructure?.filter(dStruct => isDataStructUseDataset(dStruct, dataset))?.length > 0 ) 

    res?.forEach(( dataset, index) => {
        const currency = dataset.currencyCode;
        const currencyLst = getLstCodeCurrencyFromStatic(dataset, entityList);

        const isForexNotNeeded = (!!currencyLst && currencyLst.length === 1 && currency === currencyLst[0]) 
        dataset?.lstScenarios?.forEach((scenario, index2) => {
            
            // control on ranges
            scenario?.ranges?.forEach((range, index3) => {
              
                res[index] = {...res[index]} 
                res[index].lstScenarios[index2] = {...res[index].lstScenarios[index2]}
                res[index].lstScenarios[index2].ranges[index3] = parseRangeDateWithTzToRangeYYYMMDD(range)
            })

            // control on forexOption (if codeCurrency === all properties currency, no forexOption)
            if(isForexNotNeeded) {
                res[index].lstScenarios[index2].forexOptions = undefined
            }
        })
    });

    return res

}