import { createContext, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { useContextI18n } from "./ContextI18n";
import { useContextLog } from "./ContextLog";
import { useContextLoader } from "./ContextLoader";
import { ERROR_MAINTENANCE } from "../components/assets/const/ErrorCode";
import { useContextUser } from "./ContextUser";
import { MaintenanceService } from "../service/MaintenanceService";
import { notifyError } from "../components/commons/utils/notification/notification";
import { cloneDeep } from "lodash"
import { parseDateWithTzToYYYYMMDD_HHMMSS } from "./analysis/utils/parserDatasetListShown";


const ContextMaintenance = createContext({})
const useContextMaintenance = () => useContext(ContextMaintenance)

function UseContextMaintenanceProvider({ children }) {

    /***************************** CONTEXTS *******************************/
    const { i18n, getUTCdateFromLocalDateWithTZ } = useContextI18n();
    const { addErrorLog } = useContextLog();
    const { setShowLoader } = useContextLoader();
    const { user, urlApi, getRequestHeaders } = useContextUser();


    /***************************** SERVICE *******************************/
    const maintenanceService = MaintenanceService(urlApi, getRequestHeaders);


    /***************************** ACTIONS FOR REDUCER *******************************/
    const actions = {
        SET_ID_MAINTENANCE: "SET_ID_MAINTENANCE",
        SET_MAINTENANCE: "SET_MAINTENANCE",
        SET_LAST_MAINTENANCE: "SET_LAST_MAINTENANCE",
        SET_IS_READ: "SET_IS_READ"
    }

    /***************************** INITIAL STATE  *******************************/
    const initialState = {
        idMaintenance: undefined,
        maintenance: undefined,
        lastMaintenance: undefined,
        lastMaintenanceIsRead: false
    }

    /***************************** REDUCER  *******************************/
    const reducer = (state, action) => {
        switch (action.type) {
            case actions.SET_ID_MAINTENANCE:
                return {
                    ...state,
                    idMaintenance: action.idMaintenance
                }
            case actions.SET_MAINTENANCE:
                return {
                    ...state,
                    maintenance: action.maintenance
                }
            case actions.SET_LAST_MAINTENANCE:
                return {
                    ...state,
                    lastMaintenance: action.lastMaintenance
                }
            case actions.SET_IS_READ:
                return {
                    ...state,
                    lastMaintenanceIsRead: action.isRead
                }
            default:
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState);

    const setIdMaintenance = (idMaintenance) => {
        dispatch({ type: actions.SET_ID_MAINTENANCE, idMaintenance })
    }

    const setMaintenance = (maintenance) => {
        dispatch({ type: actions.SET_MAINTENANCE, maintenance })
    }

    const setLastMaintenance = (lastMaintenance) => {
        dispatch({ type: actions.SET_LAST_MAINTENANCE, lastMaintenance })
    }
    const setLastMaintenanceIsRead = useCallback((bool) => {
        dispatch({ type: actions.SET_IS_READ, isRead: bool })
    }, [actions.SET_IS_READ])

    const getLastMaintenance = () => {
        setShowLoader(true, i18n("form.maintenance.loading.progress"), "", [ERROR_MAINTENANCE])
        maintenanceService.getLastMaintenance({
            onSuccess: (response) => {
                setShowLoader(false)
                if (!!response?.data && response?.data !== "") {
                    setLastMaintenance(response.data)
                } else {
                    setLastMaintenance()
                }
            },
            onError: (error) => {
                addErrorLog(error, ERROR_MAINTENANCE)
                notifyError(error);
            },
            finally: (response) => {
            }
        });
    }

    const resolveMaintenance = (idMaintenance) => {
        setShowLoader(true, i18n("form.maintenance.loading.progress"), "", [ERROR_MAINTENANCE])
        maintenanceService.resolveMaintenanceById({
            data: idMaintenance,
            onSuccess: (response) => {
                setMaintenance(response.data);
                setShowLoader(false)
            },
            onError: (error) => {
                addErrorLog(error, ERROR_MAINTENANCE)
                notifyError(error);
            },
            finally: (response) => {
            }
        });
    }




    const upsertMaintenance = (maintenance) => {
        setShowLoader(true, i18n("form.maintenance.loading.progress"), "", [ERROR_MAINTENANCE])
        return new Promise((resolve, reject) => {
            const copyDataMaintenance = cloneDeep(maintenance)
            copyDataMaintenance.startDate = parseDateWithTzToYYYYMMDD_HHMMSS(getUTCdateFromLocalDateWithTZ(maintenance?.startDate))
            copyDataMaintenance.user = user
            maintenanceService.upsertMaintenance({
                data: copyDataMaintenance,
                onSuccess: (response) => {
                    setMaintenance(response.data);
                    setShowLoader(false);
                    getLastMaintenance()
                    if (!!resolve) resolve()
                    gridMaintenanceRef?.current?.instance?.refresh();
                },
                onError: (error) => {
                    notifyError(error);
                    addErrorLog(error, ERROR_MAINTENANCE);
                    if (!!reject) reject()
                },
                finally: () => {
                }
            });

        })
    }

    const deleteMaintenance = (maintenanceToDelete) => {
        setShowLoader(true, i18n("form.maintenance.delete.progress"), "", [ERROR_MAINTENANCE])
        return new Promise((resolve, reject) => {
            maintenanceService.deleteMaintenanceById({
                data: maintenanceToDelete.id,
                onSuccess: () => {
                    setIdMaintenance(undefined)
                    setShowLoader(false);
                    if (!!resolve) resolve()
                    gridMaintenanceRef?.current?.instance?.refresh();
                },
                onError: (error) => {
                    notifyError(error);
                    addErrorLog(error, ERROR_MAINTENANCE)
                    if (!!reject) reject()
                },
                finally: (response) => {
                }
            });
        })
    }

    useEffect(() => {
        if (!!state?.idMaintenance) {
            resolveMaintenance(state?.idMaintenance)
        } else {
            setMaintenance(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.idMaintenance])


    useEffect(() => {
        getLastMaintenance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const gridMaintenanceRef = useRef();

    return (

        <ContextMaintenance.Provider
            value={{
                idMaintenance: state?.idMaintenance,
                maintenance: state?.maintenance,
                lastMaintenance: state?.lastMaintenance,
                lastMaintenanceIsRead: state?.lastMaintenanceIsRead,
                setLastMaintenanceIsRead,
                gridMaintenanceRef,
                setIdMaintenance,
                resolveMaintenance,
                upsertMaintenance,
                deleteMaintenance
            }}

            children={children} />
    )

}

export { UseContextMaintenanceProvider, useContextMaintenance }