import { createContext, useContext, useEffect, useReducer } from "react";
import { UserService } from "../service/UserService";
import { useContextLog } from "./ContextLog";
import { useContextUser } from "./ContextUser";
import { ERROR_RESOLVE_LST_USERS } from "../components/assets/const/ErrorCode";
import { sortAlphabeticallyListOfObjectByKey } from "../components/commons/utils/list/utilsListSort";

const ContextUserLst = createContext({});
const useContextUserLst = () => useContext(ContextUserLst)

function UseContextUserLstProvider({ props, children }) {

    /***************************** CONTEXTS *******************************/
    const { urlApi, getRequestHeaders } = useContextUser();
    const contextLog = useContextLog();
    const { addErrorLog } = useContextLog();


    /***************************** SERVICES *******************************/
    const userService = UserService(urlApi, getRequestHeaders, contextLog);

    /***************************** ACTIONS FOR REDUCER *******************************/
    const actions = {
        GET_LST_USERS: "GET_LST_USERS"
    }

    /***************************** INITIAL STATE  *******************************/
    const initialState = {
        lstUsers: []
    }

    /***************************** REDUCER  *******************************/
    const reducer = (state, action) => {
        switch (action.type) {
            case actions.GET_LST_USERS:
                return {
                    ...state,
                    lstUsers: action?.lstUsers
                }
            default:
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (state?.lstUsers?.length === 0) {
            resolveLstUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, no-undef
    }, [])


    const resolveLstUsers = () => {
        userService.resolveUserList({
            data: {
                userData: {
                    module: "FPA_AI02"
                }
            },
            onSuccess: (response) => {
                dispatch({
                    type: actions.GET_LST_USERS,
                    lstUsers: sortAlphabeticallyListOfObjectByKey(response?.data?.data?.filter(user => !user?.disabled), "name")
                })
            },
            onError: (error) => {
                addErrorLog(error, ERROR_RESOLVE_LST_USERS)
            },
        })
    }

    return (
        <ContextUserLst.Provider
            value={{
                lstUsers: state?.lstUsers,
                lstUsersDesigner: state?.lstUsers?.filter(u => u?.designer)
            }}
            children={children}
        />
    );
}

export { UseContextUserLstProvider, useContextUserLst };