import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types'
import './CustomToggleIcon.scss'
import PictoWithTooltip from '../../../commons/utils/picto/PictoWithTooltip'

const CustomToggleIcon = ({ collapsed,
    collapsedIcon = faChevronDown,
    nonCollapsedIcon = faChevronUp,
    collapsedIconTooltip,
    tooltipPlacement = "right"

}) => {

    return (
        <span className={`custom-toggle-icon ${collapsed ? "is" : "is-not"}-collapsed  ${!!collapsedIconTooltip ? 'custom-toggle-icon-with-tooltip' : 'standard'}`} >
            {!!collapsedIconTooltip ?
                (<PictoWithTooltip
                    icon={collapsed ? collapsedIcon : nonCollapsedIcon}
                    tooltipText={collapsedIconTooltip}
                    placement={tooltipPlacement}
                />)
                :
                (<FontAwesomeIcon icon={collapsed ? collapsedIcon : nonCollapsedIcon} />)}

        </span>
    )
}

CustomToggleIcon.propTypes = {
    collapsed: PropTypes.bool.isRequired
}



export default CustomToggleIcon