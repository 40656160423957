import axios from "axios";
import { isEmpty } from "lodash";

// additionnalRequestPayloadUserData allows to send param in object 'userData'
// additionalParamGridLvl allows to send param at same level of filter, sort, skip, etc
export const CommonDataSourceHandle = (urlApi, getRequestHeaders, keyDataSource, additionnalRequestPayloadUserData, customLoadUserData, additionalParamGridLvl = undefined) => {

    const handleLoadData = async (loadOptions) => {

        loadOptions.userData = additionnalRequestPayloadUserData

        if (!!customLoadUserData && typeof customLoadUserData === "function") {
            loadOptions.userData = { ...additionnalRequestPayloadUserData, ...customLoadUserData() }
        }

        if (!!additionalParamGridLvl) {
            loadOptions = { ...loadOptions, ...additionalParamGridLvl }
        }

        const response = await axios.post(urlApi + "/grid", loadOptions, !!getRequestHeaders ? getRequestHeaders() : null)
        return response?.data;

    };

    return {
        key: keyDataSource,
        load: async (loadOptions) => {
            // Security to prevent useless requests sent
            if (
                !!loadOptions.filter ||
                !!loadOptions.sort ||
                !!loadOptions.skip ||
                !!loadOptions.take ||
                (isEmpty(additionnalRequestPayloadUserData) && !!additionnalRequestPayloadUserData) ||
                (isEmpty(loadOptions.userData) && !!loadOptions.userData)
            ) {
                return await handleLoadData(loadOptions)
            }
        },
        byKey: async key => {
            return await handleLoadData({ filter: [keyDataSource, "=", key] });
        }
    };
};


