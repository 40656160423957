
export const PositionSymbolLeft = "left";

export const PositionSymbolRight = "right";

export const PositionSymbolNone = "noSymbol";

export const PositionSymbolDisplay = [PositionSymbolLeft, PositionSymbolRight, PositionSymbolNone];


export const applyPositionSymbol = (value, symbol, positionSymbol) => {
    switch (positionSymbol) {
        case PositionSymbolLeft:
            return symbol + " " + value
        case PositionSymbolRight:
            return value + " " + symbol
        default:
            return value;
    }
}
