import { getEndPointAccessDictionary, getEndPointUsers } from "../api/endPoints";
import { ERROR_RESOLVE_USER_GRID } from "../components/assets/const/ErrorCode";
import { GenericInhovateService } from "./GenericInhovateService";

export const UserService = (urlApi, requestHeaders, contextLog) => {

    const codeLogger = ERROR_RESOLVE_USER_GRID;
    const genericService = GenericInhovateService(requestHeaders, contextLog, codeLogger);

    return {
        getAccessDictionnary : (params) => genericService.genericAPIGet(getEndPointAccessDictionary(urlApi), params),
        resolveUserList: (params) => genericService.genericAPIPost(getEndPointUsers(urlApi)+"/grid", params),
    }
};

