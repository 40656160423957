export const sortAlphabeticallyListOfObjectByKey = (list, keyField) => {
    return list?.sort((a, b) => {
        const val1 = a[keyField].toUpperCase();
        const val2 = b[keyField].toUpperCase();

        if (val1 < val2) {
            return -1;
        }

        if (val1 > val2) {
            return 1;
        }

        return 0;
    })
}