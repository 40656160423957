// en utilisant ce contexte, on delegue le plus gros de la construction du grid au serveur 
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import { CommonDataSourceHandle } from "./CommonDataSourceHandle";

// cela permet de faire notamment de la pagination et de deleguer les divers calculs au serveur
const ContextCommonGrid = createContext({})

const useContextCommonGrid = () => useContext(ContextCommonGrid)


// additionnalRequestPayload permet d avoir des attributs en plus pour le loadData
// specificColumnAttr permet de donner des renders ou override n importe quel parametre
function UseContextCommonGridProvider({ urlApi, additionnalRequestPayload, getRequestHeaders, keyDatasource = "id", customLoadUserData, children }) {

    const [datasource, setDatasource] = useState()

    const gridRef = useRef(null)

    const [needResolveDatasource, setNeedResolveDatasource] = useState(true)

    const resolveDataSource = useCallback(async () => {

        setDatasource(new CustomStore(CommonDataSourceHandle(urlApi, getRequestHeaders, keyDatasource, additionnalRequestPayload, customLoadUserData)));
        setNeedResolveDatasource(false);

    }, [additionnalRequestPayload, customLoadUserData, getRequestHeaders, keyDatasource, urlApi])

    // force the building of the datasource
    useEffect(() => {
        if (needResolveDatasource) {
            setNeedResolveDatasource(false);
            resolveDataSource()
        }
    }, [needResolveDatasource, resolveDataSource])

    useEffect(() => {
        if (additionnalRequestPayload) {
            setNeedResolveDatasource(true)
        }
    }, [additionnalRequestPayload])

    return <ContextCommonGrid.Provider value={{
        datasource,
        gridRef
    }} children={children} />
}

export { UseContextCommonGridProvider, useContextCommonGrid }