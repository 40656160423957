const LOCAL_STORAGE_VERSION = "1.6"

export const keyLocalStorageVersion = "version"
export const keyLocalStoragePanelPredicTemplate = `panelPredicTemplate`
export const keyLocalStoragePanelPredicDeployedFinalizer = `panelPredicDeployedFinalizer`
export const keyToDoListTemplatesGrid = `toDoListTemplatesGrid`
export const keyToDoListLastTemplateGrid = `toDoListLastTemplateGrid`
export const keyToDoListSectionsGrid = `toDoListSectionsGrid`
export const keyLocalStoragePrefGridAllDimensions = `prefGridAllDimensions`
export const keyLocalStorageFlogShowStartEnd = `flogShowStartEnd`
export const keyLocalStoragePrefGridPredicTemplateSectionGridAppearance = `prefGridPredicTemplateSectionGridAppearance`
export const keyLocalStoragePrefGridPredicTemplateSectionGrid = `prefGridPredicTemplateSectionGrid`
export const keyLocalStoragePrefGridPredicTemplateSectionDisabledGrid = `prefGridPredicTemplateSectionDisabledGrid`
export const keyLocalStorageLogList = `logList`
export const keyLocalStorageUserList = `userListGrid`
export const keyLocalStorageFlogOrOther = `flogOrOther`
export const keyLocalStoragePrefGridPredicHistoricTimelineIRA = `prefGridPredicHistoricTimelineIRA`
export const keyLocalStoragePrefGridPredicHistoricTimeline = `prefGridPredicHistoricTimeline`
export const keyLocalStorageDeployedScreenViewParamsGridInputIRA = `screenViewParamsGridInputIRA`
export const keyRemarksAndNarrativeActivTab = "remarksAndNarrativeActivTab"
export const keyRemarksGrid = "keyRemarksGrid"
export const keyNarrativesGrid = "keyNarrativesGrid"
export const keyGlobalUpdateCalculationComponents = "keyGlobalUpdateCalculationComponents"
export const keyPrdDesignPopupGlobalUpdateForm = "keyPrdDesignPopupGlobalUpdateForm"
export const keyPopupSizeLstLines = "keyPopupSizeLstLines"
export const keyLocalStorageGlobalUpdateLinesSelection = `prefGlobalUpdateLinesSelection`
export const keyGlobalUpdateDatesRelatives = "keyGlobalUpdateDatesRelatives"
export const keyGlobalUpdateScenarioAndVersion = "keyGlobalUpdateScenarioAndVersion"
export const keyGlomiSectionsGrid = "glomiSectionsGrid"
export const keyLocalStorageCalculationQueue = "keyLocalStorageCalculationQueue"
export const keyGlomiLastTemplateGrid = "glomiLastTemplateGrid"
export const keyDeployedTemplatePanelTab = "deployedTemplatePanelTab"


export const keyLocalStorageDataHistoTotalsSection = (userId, sectionId) => `dataHistoTotalsSection${userId}_${sectionId}`
export const keyLocalStorageKeyCommonPredicGrid = (tabName) => `prefGridCommonPredicGrid__${tabName}`
export const keyLocalStorageGanttMonitoringFilter = (uuidPredic) => `ganttMonitoringFilter_${uuidPredic}`
export const keyLocalStorageParamPreferences = (user, predicSection) => `userParamPreferencesIRA_${user?.id}_${predicSection?.id}`
export const keyLocalStorageGanttFilterConfigPredic = (uuidPredic) => `ganttFilterConfigPredic_${uuidPredic}`
export const keyLocalStorageDataHisto = (lineUuid, uuidTimeline) => `dataHisto_${lineUuid}_${uuidTimeline}`
export const keyLocalStorageAnalysisPivotGrid = (datasetGroup, screenDef) => `analysisPivotGrid_${datasetGroup?.id}_${screenDef?.id}`	// keyLocalStorageAnalysisPivotGrid

///////////////////////////////////////////////////////// CHECK /////////////////////////////////////////////////////////
export const checkKeysLocalStorage = () => {
    const versionSavedInStorage = localStorage.getItem(keyLocalStorageVersion)
    if (versionSavedInStorage !== LOCAL_STORAGE_VERSION) {
        localStorage.clear()
        localStorage.setItem(keyLocalStorageVersion, LOCAL_STORAGE_VERSION)
    }

}





