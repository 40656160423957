import React from 'react';
import logoPng from './logo-inhovate-64abd6.png'
import './PanelLogo.scss'

import { homePath } from '../../../navigation/routes';

import { Link } from 'react-router-dom';



const PanelLogo = () => {

    return (

        <div className="inhovate-panel-logo">
            <Link to={homePath}>
                <img src={logoPng} alt="INHOVATE" />
            </Link>
        </div>

    )

}


export default PanelLogo