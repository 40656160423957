export const FormatNegatifMinus = "minus" ;

export const FormatNegatifRed = "red" ;

export const FormatNegatifParenthesis = "parenthesis" ;

export const FormatNegatifRedParenthesis = "redParenthesis" ;

export const FormatNegatifDisplay = [FormatNegatifMinus, FormatNegatifRed, FormatNegatifParenthesis, FormatNegatifRedParenthesis]

export const applyFormatNegatif = (value, formatNegatif) => {
    switch (formatNegatif) {
        case FormatNegatifMinus :
        case FormatNegatifRed :
            return '-'+ value
        case FormatNegatifParenthesis :
        case FormatNegatifRedParenthesis :
            return '('+ value + ')'
        default :
            return value
    }
}

export const isRedNegatif = (formatNegatif) => { 
    switch (formatNegatif) {
        case FormatNegatifRed :
        case FormatNegatifRedParenthesis :
            return true 
        default : 
            return false
    }
}

export const isParenthesisNegatif = (formatNegatif) => { 
    switch (formatNegatif) {
        case FormatNegatifParenthesis :
        case FormatNegatifRedParenthesis :
            return true 
        default : 
            return false
    }
}