import { getEndPointMaintenance } from "../api/endPoints";
import { GenericService } from "./GenericService";

export const MaintenanceService = (urlApi, requestHeaders) => {
    const genericService = GenericService(requestHeaders);
    return {
        resolveMaintenanceById: (params) => genericService.genericAPIGet(getEndPointMaintenance(urlApi, params.data), params),
        upsertMaintenance: (params) => genericService.genericAPIPut(getEndPointMaintenance(urlApi), params),
        deleteMaintenanceById: (params) => genericService.genericAPIDelete(getEndPointMaintenance(urlApi, params.data), params),
        getLastMaintenance: (params) => genericService.genericAPIGet(getEndPointMaintenance(urlApi, params.data) + "/last", params),

    }
};

