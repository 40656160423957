import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import CommonDropDown from '../../../assets/customs/dropdown/CommonDropdown'
import { LightMode } from "../../../assets/display/ColorDisplayMode"

const CommonSelector = ({value, valueList, renderValue, onChangeValue, labelInput, readOnly, className, displayColorMode = LightMode}) => {

    const [collapsed, setCollapsed] = useState(true)

    return  (
    <Form.Group className={`input-text common-selector ${displayColorMode}`}>
        { !!labelInput && (
            <Form.Label className="common-selector-label">{labelInput}</Form.Label>
        )}
        <CommonDropDown className={`common-selector ${className}`} displayMode={displayColorMode} collapsed={collapsed} setCollapsed={setCollapsed}
            toggle={
                <span className='item item-max-size'>{!!renderValue? renderValue(value) : value}</span>
            }
            menu={
                valueList.map((valueItem, index) => {
                    const active = value === valueItem
                    return (
                        <Dropdown.Item disabled={readOnly} as="li" onClick={() => {onChangeValue(valueItem);setCollapsed(true)}} key={`${index}`} active={active} >
                            <span>{!!renderValue? renderValue(valueItem) : valueItem}</span>
                        </Dropdown.Item>
                    )
                })
            }
        />
    </Form.Group>
    )
}

export default CommonSelector