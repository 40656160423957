import { isRedNegatif } from "../display/FormatNegatifDisplay";
import "./CellNumberFormatted.scss";
import DataOfCellNumberFormatted from "./DataOfCellNumberFormatted";

const CellNumberFormatted = ({
  as: Element = "div", // Default element is div
  value = "",
  formatterNumber,
  symbol,
  className = "default",
  showSymbolWhenUndefined = false,
  withSignInversion = false,
  withInputRatioAsPrct = false
}) => {
  const isNegatif = (withSignInversion?-1:1)*value < 0;
  const isRed = isNegatif && isRedNegatif(formatterNumber?.formatNegatif);

  const getValueToShow = (value) => {

    let valueToShow = value;
    if (withSignInversion) valueToShow *= -1
    if (withInputRatioAsPrct) valueToShow *= 100
    return valueToShow;
  }

  return (
    <Element
      className={`formatted-number-cell ${isRed ? "red-neg-cell" : ""
        } ${className}`}
      title={isNaN(value) ? "" : value}
    >
      {value !== undefined && value !== null && value !== "" ? (DataOfCellNumberFormatted(getValueToShow(value), formatterNumber, symbol))

        : (
          <>{showSymbolWhenUndefined ? symbol : ""}</>
        )}

    </Element>
  );
};

export default CellNumberFormatted;
