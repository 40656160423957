import { faCogs, faTools } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router';
import { useContextI18n } from '../../../../context/ContextI18n'
import { formulaPath, variablesPath, dimensionsPath, narrativeCategoryPath, metricsPath } from '../../../../navigation/routes'
import PanelLanguageChooser from '../../utils/pref-lang/PanelLanguageChooser'
import './PopupParamsContent.scss'

const PopupParamsContent = ({ showPopupFormattage, setShowPopupFormattage, isDescriptiveRightReadOnly }) => {

    const { i18n } = useContextI18n()

    let navigate = useNavigate()
    const redirection = (url) => {
        navigate(url)
    }


    return (
        <>
            <Row nogutters={"true"} className="popover-params-content">
                <Col>
                    <label>{i18n("params.language.label")}&nbsp;:</label>
                </Col>
                <Col>
                    <PanelLanguageChooser />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <span className="btn btn-sm" onClick={() => setShowPopupFormattage(!showPopupFormattage)}>
                        <FontAwesomeIcon icon={faTools} /> {i18n("modal.form.preference.formatter.title")}
                    </span>
                </Col>
            </Row>

            {!isDescriptiveRightReadOnly && (
                <>
                    <Row>
                        <Col>
                            <span className="btn btn-sm" onClick={() => redirection(formulaPath)}>
                                <FontAwesomeIcon icon={faCogs} /> {i18n("modal.form.preference.formula.title")}
                            </span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="btn btn-sm" onClick={() => redirection(variablesPath)}>
                                <FontAwesomeIcon icon={faCogs} /> {i18n("modal.form.preference.variables.title")}
                            </span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="btn btn-sm" onClick={() => redirection(dimensionsPath)}>
                                <FontAwesomeIcon icon={faCogs} /> {i18n("modal.form.preference.dimensions.title")}
                            </span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="btn btn-sm" onClick={() => redirection(metricsPath)}>
                                <FontAwesomeIcon icon={faCogs} /> {i18n("modal.form.preference.metrics.title")}
                            </span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="btn btn-sm" onClick={() => redirection(narrativeCategoryPath)}>
                                <FontAwesomeIcon icon={faCogs} /> {i18n("modal.form.preference.category.title")}
                            </span>
                        </Col>
                    </Row>
                </>
            )}
        </>

    )
}


export default PopupParamsContent