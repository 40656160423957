import { faBed, faBell, faCogs, faCube, faExchangeAlt, faFileExport, faFileImport, faHotel, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useContextI18n } from '../../../../context/ContextI18n'
import {
    companyPath,
    entityPath,
    usersPath,
    licensePath,
    transactionDetailsPath,
    fileImportPath,
    perspectivePath,
    maintenancePath
} from '../../../../navigation/routes'
import './PopupAdminContent.scss'
import { useContextUser } from "../../../../context/ContextUser";
import axios from 'axios'
import { getendPointExportPredictiveStatistics } from '../../../../api/endPoints'
import saveAs from "file-saver";
import { useContextLoader } from '../../../../context/ContextLoader'
import { useContextLog } from '../../../../context/ContextLog'
import { ERROR_EXPORT_PREDICTIVE_STATISTICS } from '../../../assets/const/ErrorCode'
import PopupConfirm from '../../utils/popup-confirm/PopupConfirm'

const PopupAdminContent = () => {

    const { urlApi, getRequestHeaders } = useContextUser();
    const { setShowLoader } = useContextLoader();
    const { addErrorLog } = useContextLog();
    const { i18n, getCsvDelimiter } = useContextI18n()
    const { isUserCompanyAdmin, isUserInhovateAdmin, isUserInhovateUser } = useContextUser();
    let navigate = useNavigate()
    const redirection = (url) => {
        navigate(url)
    }

    const [showPopupConfirmExport, setShowPopupConfirmExport] = useState(false);

    const onExportPredictiveStatistics = async () => {
        setShowLoader(true, i18n("modal.form.preference.export.stats.loading"), "", [ERROR_EXPORT_PREDICTIVE_STATISTICS])
        axios.get(getendPointExportPredictiveStatistics(urlApi) + "/" + getCsvDelimiter(), { ...getRequestHeaders(), responseType: 'blob' }).then(
            (response) => {
                const fileName = response?.headers["content-disposition"]?.split("filename = ")[1] ?? "export.csv"
                const fileBlob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })
                saveAs(fileBlob, fileName)
                setShowLoader(false)
                setShowPopupConfirmExport(false)
            },
            (err) => {
                const errorMsg = err.toJSON()?.message
                addErrorLog(errorMsg, ERROR_EXPORT_PREDICTIVE_STATISTICS)
            }
        )
    }

    return (
        <>
            {(isUserInhovateAdmin || isUserCompanyAdmin) &&
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(usersPath)}>
                        <FontAwesomeIcon icon={faUserEdit} /> {i18n("modal.form.preference.users.title")}
                        </span>
                    </Col>
                </Row>
            }
            {isUserInhovateAdmin &&
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(companyPath)}>
                            <FontAwesomeIcon icon={faHotel} /> {i18n("modal.form.preference.company.title")}
                        </span>
                    </Col>
                </Row>
            }
            {(isUserInhovateAdmin || isUserInhovateUser) &&
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(entityPath)}>
                            <FontAwesomeIcon icon={faBed} /> {i18n("modal.form.preference.entity.title")}
                        </span>
                    </Col>
                </Row>
            }
            {isUserInhovateAdmin &&
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(perspectivePath)}>
                            <FontAwesomeIcon icon={faCube} /> {i18n("modal.form.preference.perspective.title")}
                        </span>
                    </Col>
                </Row>
            }
            {isUserInhovateAdmin &&
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(licensePath)}>
                            <FontAwesomeIcon icon={faCogs} /> {i18n("modal.form.preference.license.title")}
                        </span>
                    </Col>
                </Row>
            }

            {(isUserInhovateAdmin || isUserCompanyAdmin || isUserInhovateUser) &&
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(transactionDetailsPath)}>
                            <FontAwesomeIcon icon={faExchangeAlt} /> {i18n("modal.form.preference.transaction.details.title")}
                        </span>
                    </Col>
                </Row>
            }

            {(isUserInhovateAdmin || isUserInhovateUser) && (
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(fileImportPath)}>
                            <FontAwesomeIcon icon={faFileImport} /> {i18n("modal.form.preference.file.import.title")}
                        </span>
                    </Col>

                </Row>
            )
            }

            {isUserInhovateAdmin && (
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => setShowPopupConfirmExport(true)}>
                            <FontAwesomeIcon icon={faFileExport} /> {i18n("modal.form.preference.export.stats.predictif")}
                        </span>
                    </Col>
                </Row>
            )}

            {isUserInhovateAdmin && (
                <Row>
                    <Col>
                        <span className="btn btn-sm" onClick={() => redirection(maintenancePath)}>
                            <FontAwesomeIcon icon={faBell} /> {i18n("modal.form.preference.manage.maintenance")}
                        </span>
                    </Col>
                </Row>
            )
            }

            {
                showPopupConfirmExport &&
                <PopupConfirm
                    show={showPopupConfirmExport}
                    setShow={setShowPopupConfirmExport}
                    onCancel={() => setShowPopupConfirmExport(false)}
                    onConfirm={onExportPredictiveStatistics}
                    textCancel={i18n("panel.cancel")}
                    textConfirm={i18n("confirm")}
                    textTitle={i18n("modal.form.preference.export.stats.predictif")}
                    textBody={i18n("modal.form.preference.export.stats.predictif.body")}
                    variant="primary"
                />
            }
        </>
    )
}


export default PopupAdminContent