import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useContextI18n } from '../../../../context/ContextI18n'
import PopupUserContent from '../popup-user/PopupUserContent'
import { useContextUser } from '../../../../context/ContextUser'
import { Popup } from 'devextreme-react'
import { Position } from 'devextreme-react/popup'

const ButtonUser = () => {

    const { i18n } = useContextI18n()
    const { user } = useContextUser();

    const [show, setShow] = useState();

    const toggleUserButton = () => {
        setShow(!show)
    }

    return (
        <>
            {!!user && (
                <>
                    <Button variant="default" onClick={toggleUserButton}>
                        <FontAwesomeIcon icon={faUser} />
                    </Button>

                    {show &&
                        <Popup
                            visible={show}
                            onHiding={() => setShow(false)}
                            dragEnabled={true}
                            hideOnOutsideClick={false} // important
                            hideOnParentScroll={false} // important
                            shading={true}
                            showCloseButton={true}
                            showTitle={true}
                            title={i18n("top-menu.user.title")}
                            width={400}
                            height="auto"
                        >
                            <Position at="top right" my="top right" of="body" />
                            <PopupUserContent />
                        </Popup>
                    }

                </>
            )}
        </>
    )

}


export default ButtonUser

