import { mapperList } from "./mapperList"

export const mapperCurrencyConversionCategory = (json, id) => {

    try {
        const currencyConversion = {
//            "id": !!json?.id ? json.id : id,
            "currencyConversionType": json["currencyConversionType"], // also forex typeCode
            "category" : json["category"],
            "versionName": json["versionName"],
            "source": json["source"], // also forex sourceCode

            "sourceCode": json["sourceCode"],
            "typeCode": json["typeCode"]
        }
        return currencyConversion

    } catch (e) {
        console.error("ERROR mapperCurrencyConversionCategory !!!")
        console.log(json)
    }
}

const mapperCurrencyConversionCategoryList = (json) => mapperList(json, mapperCurrencyConversionCategory)

export default mapperCurrencyConversionCategoryList