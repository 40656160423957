export const ROLE_INHOVATE_ADMIN = {
  name: "ROLE_INHOVATE_ADMIN",
  id: 999,
};

export const ROLE_INHOVATE_USER = {
  name: "ROLE_INHOVATE_USER",
  id: 850,
};

export const ROLE_COMPANY_ADMIN = {
  name: "ROLE_COMPANY_ADMIN",
  id: 550,
};

export const ROLE_COMPANY_USER = {
  name: "ROLE_COMPANY_USER",
  id: 100,
};

export const listRolesUsers = [
  ROLE_INHOVATE_ADMIN,
  ROLE_INHOVATE_USER,
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_USER,
];

export const getListRolesUsersByRoleName = (roleName) => {
  //console.log("ROLE", role);
  switch (roleName) {
    case ROLE_INHOVATE_ADMIN.name:
      return listRolesUsers;
    case ROLE_INHOVATE_USER.name:
      return [ROLE_INHOVATE_USER, ROLE_COMPANY_ADMIN, ROLE_COMPANY_USER];
    case ROLE_COMPANY_ADMIN.name:
      return [ROLE_COMPANY_ADMIN, ROLE_COMPANY_USER];
    case ROLE_COMPANY_USER.name:
      return [ROLE_COMPANY_USER];
    default:
      return [];
  }
};
