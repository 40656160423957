export const DESIGNER = "DESIGNER";
export const DEPLOYER = "DEPLOYER";
export const INITIATOR = "INITIATOR";
export const REVIEWER = "REVIEWER";
export const APPROVER = "APPROVER";
export const INITIATOR_APPROVER = "INITIATOR_APPROVER";
export const FINALIZER = "FINALIZER";

export const PredicUserType = [DESIGNER,DEPLOYER,INITIATOR,REVIEWER,APPROVER,INITIATOR_APPROVER,FINALIZER]

// mapping to match java model
export const getLstPrediUserTypeFromType = (put) => {

    if(put === INITIATOR_APPROVER){ return [INITIATOR,APPROVER]}
    if(!put) return undefined
    else return [put] 
}

export const getPredicUserTypeForManipulation = (listPredicUserType) => {
    
    if(listPredicUserType?.length===1){

        const put = listPredicUserType[0]
        if(PredicUserType.includes(put)){
            return put
        }

    }else if(listPredicUserType?.length===2){

        const put1 = listPredicUserType[0]
        const put2 = listPredicUserType[1]
        if(put1 === INITIATOR && put2 === APPROVER) return INITIATOR_APPROVER
    }
    
    // default
    return undefined
}

export const WFL_IAR_LIST = [INITIATOR_APPROVER, INITIATOR, REVIEWER, APPROVER];
