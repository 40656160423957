import { LightMode } from "../form/utils/ColorOverlayLayer"
import CommonOverlay from "../form/utils/CommonOverlay"
import SpinnerInfinity from "./spinner/SpinnerInfinity"
import './LoadingInfinity.scss'
import { useState } from "react"
import PictoTimes from "../utils/picto/common/PictoTimes"

const LoadingInfinity = ({title, message, crashMode=false, crashTitle, crashMessage, showLoading=true, isOverlayTransparent=true, isWithPoints=true, onCloseCrash}) => {
   
    const showTitle = !crashMode ? !!title : !!crashTitle
    const movingClassName = !crashMode? "moving" : ""
  
    const classComplementaire = (isOverlayTransparent ? "overlay-transparent" : "overlay-not-transparent") + (crashMode ? " crash-mode" : "");

    const [done, isDone] = useState(false);

    if(showLoading && (!!onCloseCrash || !done) ) {

        return (
            <div className={`component-loading-infinity ${classComplementaire} `}>
                <CommonOverlay setShow={() => {}} ColorOverlayLayer={LightMode} show={true} specificClassName={`overlay-loading-infinity ${classComplementaire}`} isOverlayTransparent={isOverlayTransparent} >
                    <div className="container-loading-message">
                        {crashMode && (
                            <PictoTimes className="close-crash-error" onClick={() => { if (!!onCloseCrash) onCloseCrash(); else isDone(true);}} />
                        )}

                        <SpinnerInfinity crashMode={crashMode} />
                        {
                            showTitle && (
                                <h3>
                                    <span className={movingClassName + " title"}>{crashMode ? crashTitle : title}</span>
                                    {
                                        isWithPoints && (
                                            <>
                                            <span className={movingClassName + " point1"}>.</span>
                                            <span className={movingClassName + " point2"}>.</span>
                                            <span className={movingClassName + " point3"}>.</span>
                                            </>
                                        )
                                    }
                                </h3>
                            )
                        }
                        <p>{crashMode? crashMessage : message}</p>
                    </div>
                </CommonOverlay>
            </div>

        )
    }else 
    return null
}

export default LoadingInfinity
