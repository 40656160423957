import React from 'react'

import './CustomToggle.scss'

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(
  ({ children, onClick }, ref) => {
 
      return (
        // cv_20220204 : on ne passe plus le ref={ref}
        // pb : Maximum call stack size exceeded
        <div className="inhovate-custom-toggle" onClick={(e) => {
              e.preventDefault()
              onClick(e)
            }}
          >
            {children}
        </div>

        )

  }
)




  export default CustomToggle