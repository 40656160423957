import { Popup } from "devextreme-react"
import { useContextI18n } from "../../../../context/ContextI18n";
import localforage from "localforage";
import { notifySuccess } from "../../utils/notification/notification";
import ButtonReset from "../../button/ButtonReset";
import ButtonTimes from "../../button/ButtonTimes";
import { wait } from "../../../assets/utils";

const PopupDeleteLocalDataConfirm = ({ showPopup, setShowPopup }) => {

    const { i18n } = useContextI18n();

    const onClose = () => setShowPopup(false);

    const onDeleteLocalData = async () => {
        // CLEAR LOCALSTORAGE
        localStorage.clear()
        // CLEAR INDEXED DBs 
        if (!!localforage.iterate) { // use localforage for Firefox compatibility
            const keys = []
            localforage.iterate(function (value, key, iterationNumber) {
                // Resulting key/value pair -- this callback
                // will be executed for every item in the
                // database.
                //console.log([key, value]);
                keys.push(key)
            }).then(async function () {
                //console.log('Iteration has completed', keys);
                keys.forEach((key) => {
                    //console.log('REMOVE ', key);
                    localforage.removeItem(key)
                })
                notifySuccess(i18n("user.delete.local.data.success"));
                await wait(700)
                window.location.reload();
            }).catch(function (err) {
                // This code runs if there were any errors
                console.log(err);
            });
        }

    }

    return (
        <Popup
            visible={showPopup}
            onHiding={onClose}
            title={i18n("user.delete.local.data.title")}
            width={500}
            height={200}
        >
            <div className="popup-delete-local-data-confirm">

                <p>{i18n("user.delete.local.data.body")}</p>
                <p>{i18n("user.delete.local.data.body.2")}</p>

                <div className="footer">
                    <ButtonTimes
                        onClick={() => onClose()}
                        text={i18n("panel.cancel")}
                    />

                    <ButtonReset
                        onClick={onDeleteLocalData}
                        text={i18n("user.delete.local.button.reset")}
                        variant="primary"
                    />
                </div>

            </div>
        </Popup>
    )
}

export default PopupDeleteLocalDataConfirm