import CommonPictoContainer from './CommonPictoContainer'
import PropTypes from 'prop-types'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


const PictoTimes = ({onClick, text, tooltipText, tooltipPlacement="top", disabled=false,  className, sizePicto}) => {
    return  <CommonPictoContainer icon={faTimes} className={`picto-times ${!!className?className:""}`} onClick={onClick} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement} disabled={disabled} sizePicto={sizePicto} />
}

PictoTimes.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default PictoTimes