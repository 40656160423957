import {Column, FilterRow} from "devextreme-react/data-grid"
import {useContextI18n} from "../../../context/ContextI18n"
import React from "react";
import DxGridColumnRenderBoolean from "../../commons/dx-form/renders/DxGridColumnRenderBoolean";

const CompanyFieldsGrid = () => {

    const { i18n } = useContextI18n();
    return [
            <FilterRow visible={true} />,
            <Column dataField="id" visible={false}/>,
            <Column dataField="name" caption={i18n("company.grid.name")} />,
            <Column dataField="shortName" caption={i18n("company.grid.shortName")} />,
            <Column dataField="client" caption={i18n("company.grid.client")} cellRender={DxGridColumnRenderBoolean} />,
        ]
    ;
};

export default CompanyFieldsGrid