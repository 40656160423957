import { useContextLog } from "../../../context/ContextLog"
import { useEffect, useMemo, useState } from "react"
import LoadingInfinity from "./LoadingInfinity"
import { useContextI18n } from "../../../context/ContextI18n"

const LoadingInfinityLogContexted = ({ title, message, listErrorCode, showLoading = true, isOverlayTransparent = true, isWithPoints = true, onCloseCrashMode, customCrashMessage }) => {
  const { i18n } = useContextI18n();
  const { getLogList } = useContextLog();
  const [time, setTime] = useState(new Date());
  const [crashed, setCrashed] = useState()

  const crashTitle = useMemo(()=>{

    if(!!customCrashMessage) return customCrashMessage

    if(!!crashed?.method) return i18n(`loading.error.${crashed?.method}`, { object: crashed?.code })

    if(!!crashed?.response?.developerMessage) return crashed?.response?.developerMessage

    return i18n( 'loading.error.default', { object: crashed?.code })
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[crashed])

 

  useEffect(() => {

    const checkCrash = setInterval(() => {

      const logList = getLogList();
      const error = logList.reverse().find(log => {
        const logTime = new Date(log.time);
        return (log.type === 'error'
          && listErrorCode?.includes(log.code)
          && logTime > time)
      }
      )

      if (!!error){
       // console.log(error)
        setCrashed(error)
      }
    }, 1000)

    return () => clearInterval(checkCrash);

  }, [getLogList, listErrorCode, time]);

  const onCloseCrash = () => {
    if (!!onCloseCrashMode) onCloseCrashMode();
    setTime(new Date())
    setCrashed(undefined)
  }

  return (
    <LoadingInfinity
      title={title}
      message={message}
      crashMode={!!crashed}
      crashTitle={crashTitle}
      onCloseCrash={onCloseCrash}
      showLoading={showLoading || !!crashed}
      isOverlayTransparent={isOverlayTransparent}
      isWithPoints={isWithPoints}
    />
  )

}


export default LoadingInfinityLogContexted
