import { getEndPointPreferenceRetreive, getEndPointUserPreferenceCRUD } from "../api/endPoints";
import { ERROR_PREFERENCE_COMMON } from "../components/assets/const/ErrorCode";
import { GenericInhovateService } from "./GenericInhovateService";

export const PreferenceCommonService = (urlApi, requestHeaders, contextLog) => {
    // error log
    const codeLogger = ERROR_PREFERENCE_COMMON;
    
    const genericService = GenericInhovateService(requestHeaders, contextLog, codeLogger);

    return {
        getPreference: (params, idScreenDef, nameModule, nameTypePref, codePref) => {
            genericService.genericAPIGet(getEndPointPreferenceRetreive(urlApi, idScreenDef, nameModule, nameTypePref, codePref), params)
        },    
        upsertPreference: (params, idScreenDef, nameModule, nameTypePref, codePref) => {
            genericService.genericAPIPut(getEndPointUserPreferenceCRUD(urlApi),{...params, data: 
                {   
                    idScreenDef:idScreenDef,
                    preferenceType: nameTypePref,
                    code: codePref ?? 'default',
                    module : nameModule, 
                    jsonValue:params.data
                } 
            })
        },
    }
}