import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const renderBoolean = (bool) => {
    return <div className="dxgrid-column-render-boolean">{bool===true ? (
        <FontAwesomeIcon icon={faCheck} />
    ):(
        <></>
    )}
    </div>
}

const DxGridColumnRenderBoolean = (cellData) => {

   return renderBoolean(cellData?.value)
}


export default DxGridColumnRenderBoolean