import axios from "axios";
import { useContextModule } from "./ContextModule";
import {
  getEndPointScreenDef,
  getEndPointScreenDefByModule,
  PREF_TYPE_FILTER_BY_SCREEN,
  PREF_TYPE_FILTER_BY_SCREEN_CUSTOM_LINES,
  PREF_TYPE_ORDER_SCREEN_DEF,
} from "../api/endPoints";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useContextUser } from "./ContextUser";
import { useContextI18n } from "./ContextI18n";
import { useContextLog } from "./ContextLog";
import {
  notifyError,
  notifySuccess,
} from "../components/commons/utils/notification/notification";
import { useContextPerspective } from "./ContextPerspective";
import { DimensionsService } from "../service/DimensionsService";
import { PreferenceCommonService } from "../service/PreferenceCommonService";
import _ from "lodash";

const ContextScreenDef = createContext({});
const useContextScreenDef = () => useContext(ContextScreenDef);

function UseContextScreenDefProvider(props) {
  const { module } = useContextModule();

  const { perspective } = useContextPerspective();
  const { urlApi, getRequestHeaders, user } = useContextUser();
  const { i18n } = useContextI18n();
  const { addErrorLog } = useContextLog();

  const [screenDefList, setScreenDefList] = useState();
  const [screenDef, setScreenDef] = useState();

  const [lstOrderIdScreen, setLstOrderIdScreen] = useState();
  const [filterAnalysis, setFilterAnalysis] = useState({});

  const [filterAnalysisByLine, setFilterAnalysisByLine] = useState({});
  const [needResolveLstTypeDimension, setNeedResolveLstTypeDimension] =
    useState(true);
  const [lstTypeDimension, setLstTypeDimension] = useState([]);

  const [datasetGroupIdSelected, setDatasetGroupIdSelected] = useState();

  const [needResolveScreenDefList, setNeedResolveScreenDefList] =
    useState(true);
  const [needResolvePreference, setNeedResolvePreference] = useState(false);

  const dimensionsService = DimensionsService(urlApi, getRequestHeaders);

  /***************************** SERVICES *******************************/
  const preferenceCommonService = PreferenceCommonService(urlApi, getRequestHeaders, useContextLog())

  const resolveScreenDefList = useCallback(async () => {
    try {
      if (!!module) {
        const endPoint = getEndPointScreenDefByModule(
          urlApi,
          module,
          perspective,
          datasetGroupIdSelected
        );
        axios
          .get(endPoint, getRequestHeaders())
          .then((response) => {
            if (!!response?.erreur) throw response.erreur;
            setScreenDefList(response.data);
            notifySuccess(i18n("screen.context.load.success"));
            setNeedResolveScreenDefList(false);
          })
          .catch(function (err) {
            console.warn("erreur");
            addErrorLog(err);
            setNeedResolveScreenDefList(false);
          });
      }
    } catch (e) {
      notifyError(e);
    }
  }, [
    module,
    urlApi,
    perspective,
    datasetGroupIdSelected,
    getRequestHeaders,
    i18n,
    addErrorLog,
  ]);

  useEffect(() => {
    if (!!screenDefList && screenDefList.length > 0) {
      // check if screenDef.id exists in screenDefList
      if (screenDefList?.findIndex((s) => s?.id === screenDef?.id) !== -1) {
        // if so we do nothing
      } else {
        // or else we set a new screenDef
        setScreenDef(screenDefList[0]);
      }
    }
  }, [screenDefList, screenDef]);

  useEffect(() => {
    if (!!module && !!perspective && needResolveScreenDefList)
      resolveScreenDefList();
  }, [needResolveScreenDefList, module, resolveScreenDefList, perspective]);

  useEffect(() => {
    if (!!module && !!perspective && !!datasetGroupIdSelected)
      setNeedResolveScreenDefList(true);
  }, [module, perspective, datasetGroupIdSelected]);

  const getActualMaxOrderPosit = useCallback(() => {
    return Math.max.apply(
      Math,
      screenDefList.map(function (o) {
        return o.orderPosit;
      })
    );
  }, [screenDefList]);

  const removeScreenDef = useCallback(
    (screenDef) => {
      try {
        if (!!screenDef) {
          const endPoint = getEndPointScreenDef(urlApi, screenDef?.id);
          axios
            .delete(endPoint, getRequestHeaders())
            .then((response) => {
              if (!!response?.erreur) throw response.erreur;
              setNeedResolveScreenDefList(true);
            })
            .catch(function (err) {
              setNeedResolveScreenDefList(true);
            });
        }
      } catch (e) {
        notifyError(e);
      }
    },
    [getRequestHeaders, urlApi]
  );

  const duplicateScreenDef = useCallback(
    (screenDef) => {
      try {
        if (!!screenDef) {
          const endPoint = getEndPointScreenDef(urlApi) + "/duplicate";
          axios
            .post(endPoint, screenDef, getRequestHeaders())
            .then((response) => {
              if (!!response?.erreur) throw response.erreur;
              setNeedResolveScreenDefList(true);
            })
            .catch(function (err) {
              setNeedResolveScreenDefList(true);
            });
        }
      } catch (e) {
        notifyError(e);
      }
    },
    [getRequestHeaders, urlApi]
  );

  const resolveLstTypeDimension = useCallback(() => {
    if (needResolveLstTypeDimension && !!perspective) {
      setNeedResolveLstTypeDimension(false);
      if (lstTypeDimension.length === 0) {
        dimensionsService.getAllTypeDimension(perspective.id, {
          onSuccess: (response) => {
            setLstTypeDimension(response.data);
          },
          onError: (error) => {
            notifyError(error);
          },
          finally: (response) => { },
        });
      }
    }
  }, [dimensionsService, lstTypeDimension.length, needResolveLstTypeDimension, perspective]);

  useEffect(() => {
    if (needResolveLstTypeDimension && !!user)
      resolveLstTypeDimension();
  }, [needResolveLstTypeDimension, resolveLstTypeDimension, user]);

  useEffect(() => {
    if (!!screenDef?.id) {
      setNeedResolvePreference(true);
      setFilterAnalysis(null);
      setFilterAnalysisByLine(null);
    }
  }, [screenDef?.id]);

  const updateFilterAnalysis = useCallback((filterAnalysisParam) => {
    preferenceCommonService.upsertPreference({
      data: filterAnalysisParam,
      onSuccess: (response) => {
        setFilterAnalysis(filterAnalysisParam);
      },
      onError: (e) => {
        notifyError(e);
      }
    }, screenDef?.id, module?.name, PREF_TYPE_FILTER_BY_SCREEN)
  },
    [module?.name, preferenceCommonService, screenDef?.id]
  );


  const updateFilterAnalysisByLine = useCallback((filterAnalysisByLine) => {

    return new Promise((resolve, reject) => {

      preferenceCommonService.upsertPreference({
        data: filterAnalysisByLine,
        onSuccess: (response) => {
          setFilterAnalysisByLine(filterAnalysisByLine);
          resolve({ done: true })
        },
        onError: (e) => {
          notifyError(e);
          reject(e)
        }
      }, screenDef?.id, module?.name, PREF_TYPE_FILTER_BY_SCREEN_CUSTOM_LINES)
    })
  },
    [module?.name, preferenceCommonService, screenDef?.id]
  );

  const updateLstOrderScreen = useCallback((lstOrderScreen) => {

    preferenceCommonService.upsertPreference({
      data: { lstOrderScreen: lstOrderScreen },
      onSuccess: (response) => {
        setLstOrderIdScreen(lstOrderScreen);
      },
      onError: (e) => {
        notifyError(e);
      }
    }, screenDef?.id, module?.name, PREF_TYPE_ORDER_SCREEN_DEF)
  },
    [module?.name, preferenceCommonService, screenDef?.id]
  );


  const resolveFilterAnalysisByLine = useCallback(() => {

    preferenceCommonService.getPreference({
      onSuccess: (response) => {
        setFilterAnalysisByLine(response.data === "" ? {} : response.data);
      },
      onError: (e) => {
        notifyError(e);
      },
      finally: () => {
        setNeedResolvePreference(false);
      }
    }, screenDef?.id, module?.name, PREF_TYPE_FILTER_BY_SCREEN_CUSTOM_LINES)

  }, [module?.name, preferenceCommonService, screenDef?.id])

  const resolveFilterAnalysis = useCallback(() => {
    if (needResolvePreference) {
      // need reload EACH time

      preferenceCommonService.getPreference({
        onSuccess: (response) => {
          setFilterAnalysis(response.data === "" ? {} : response.data);
        },
        onError: (e) => {
          notifyError(e);
        },
        finally: () => {
          resolveFilterAnalysisByLine()
        }
      }, screenDef?.id, module?.name, PREF_TYPE_FILTER_BY_SCREEN)


      // need load ONE time
      if (!lstOrderIdScreen) {

        preferenceCommonService.getPreference({
          onSuccess: (response) => {
            setLstOrderIdScreen(response.data === "" ? [] : response?.data?.lstOrderScreen)
          },
          onError: (e) => {
            notifyError(e);
          },
          finally: () => {
            setNeedResolvePreference(false)
          }
        }, screenDef?.id, module?.name, PREF_TYPE_ORDER_SCREEN_DEF)

      }
    }
  }, [lstOrderIdScreen, module?.name, needResolvePreference, preferenceCommonService, resolveFilterAnalysisByLine, screenDef?.id]);

  useEffect(() => {
    if (needResolvePreference)
      resolveFilterAnalysis();
  }, [needResolvePreference, resolveFilterAnalysis]);


  // function that aggregates data from filterAnalysis and filterAnalysisByLine
  // to match the structure expected by the endpoint 'evalScreen'
  // "optionalFilterByLine": [{"listIdLine":[10257],"optionalFilter": { "DP": [ "01" ] } } ]
  const getOptionnalFilterByLineForPayload = (fullIdLineList, filterParam, filterAnalysisByLineParam) => {
    const filter = []

    // All lines that do not have a reference in filterAnalysisByLine
    // are intended to be filtered by the global filter
    if (!_.isEmpty(filterParam)) {

      const idLineFilterGlobalList = fullIdLineList?.filter(idLine => {
        if (!!filterAnalysisByLineParam) {
          const fByLine = filterAnalysisByLineParam[idLine]
          return !fByLine
        } else {
          return true
        }
      })

      const filterCleaned = _.cloneDeep(filterParam)
      delete filterCleaned["excludeTransactionWithoutDimensionType"]

      const filterGlobal = {
        "listIdLine": idLineFilterGlobalList,
        "optionalFilter": filterCleaned,
        "excludeTransactionWithoutDimensionType": filterParam.excludeTransactionWithoutDimensionType
      }

      filter.push(filterGlobal);

      // const idLineFilterCustomList = fullIdLineList.filter(idLine => !!filterAnalysisByLine[idLine] && !_.isEmpty(filterAnalysisByLine[idLine]))
      // TODO make custom filters
    }



    return filter

  }


  return (
    <ContextScreenDef.Provider
      value={{
        datasetGroupIdSelected,
        setDatasetGroupIdSelected,
        screenDefList,
        screenDef,
        setScreenDef,
        setFilterAnalysis,
        filterAnalysis,
        updateFilterAnalysis,
        filterAnalysisByLine,
        updateFilterAnalysisByLine,
        getOptionnalFilterByLineForPayload,
        lstOrderIdScreen,
        updateLstOrderScreen,
        lstTypeDimension,
        setNeedResolveScreenDefList,
        getActualMaxOrderPosit,
        removeScreenDef,
        duplicateScreenDef,
      }}
      {...props}
    />
  );
}

export { UseContextScreenDefProvider, useContextScreenDef };
