import React, { createContext, useContext, useEffect } from 'react'
import { getBrowserLanguage, i18nWithLanguage, getJJMMAAAAWithLanguage, getHHMMWithLanguage, getDateWithTime_HHMMWithLanguage, getMomentumWithLanguage, localeWithoutRegionCode, getMMMdyyyyWithLanguage, getMMMyyWithLanguage, getDayDateAndMonthAndYearWithLanguage, getMonthAndYearWithLanguage, getDddWithLanguage, getYYYYWithLanguage, getDayOfWeekWithFirstDayContexted, getDdddWithLanguage, getYYMMDD_HHMMNoLanguage } from '../i18n/utils'
import useStateWithCallback from 'use-state-with-callback';
import { locale } from "devextreme/localization";
import { CSV_DELIMITER_COMMA, CSV_DELIMITER_SEMICOLON } from './utils/CsvDelimiter';

const ContextI18n = createContext({})
const useContextI18n = () => useContext(ContextI18n)

function UseContextI18nProvider(props) {

  const [language, setLanguage] = useStateWithCallback(sessionStorage?.getItem('language'),
    (lang) => {
      if (!!lang && lang !== sessionStorage.getItem('language')) {
        sessionStorage.setItem('language', lang);
        locale(lang);
        document.location.reload(); // Necessary to reload correctly devexteme components with new language
      }
    }
  )

  useEffect(() => {
    // Initiaize the language
    const lang = language ?? getBrowserLanguage()
    setLanguage(lang)
    locale(lang);
  }, [language, setLanguage])

  const i18n = (key, values) => {
    try {
      return i18nWithLanguage(key, values, language)
    } catch (e) {
      console.warn(`i18n ${key}`, values)
      return `${key}[${language}]`
    }
  }

  const getJJMMAAAA = (date) => {
    return getJJMMAAAAWithLanguage(date, language)
  }

  const getUtcJJMMAAAA = (date) => {
    return getJJMMAAAAWithLanguage(date, language, true)
  }

  const getMMMdyyyy = (date) => {
    return getMMMdyyyyWithLanguage(date, language)
  }

  // return date as 'jeudi 15 septembre 2022'/ 'Thursday, September 15, 2022'
  const getDayDateAndMonthAndYear = (date) => {
    return getDayDateAndMonthAndYearWithLanguage(date, language)
  }

  const getShortDayName = (date) => {
    return getDddWithLanguage(date, language)
  }

  const getDayName = (date) => {
    return getDdddWithLanguage(date, language)
  }

  const getMonthAndYear = (date) => {
    return getMonthAndYearWithLanguage(date, language)
  }

  const getMMMyy = (date) => {
    return getMMMyyWithLanguage(date, language)

  }
  const getHHMM = (date) => {
    return getHHMMWithLanguage(date, language)
  }

  const getUtcHHMM = (date) => {
    return getHHMMWithLanguage(date, language, true)
  }

  const getDateWithTime_HHMM = (date) => {
    return getDateWithTime_HHMMWithLanguage(date, language)
  }

  const getUtcDateWithTime_HHMM = (date) => {
    return getDateWithTime_HHMMWithLanguage(date, language, true)
  }

  const getLocaleDateTimeFromUtc = (date) => {
    const dateJS = new Date(date)
    return new Date(dateJS.getTime() - dateJS.getTimezoneOffset() * 60 * 1000);
  }

  const getYYYY = (date) => {
    return getYYYYWithLanguage(date, language)
  }

  const getMomentum = (date) => { //'morning','afternoon' or 'evening'
    return getMomentumWithLanguage(date, language)
  }

  const getDayOfWeek = (date) => {
    return getDayOfWeekWithFirstDayContexted(date, getFirstDayOfWeekForLibMoment())
  }

  const getUTCdateFromLocalDateWithTZ = (localDateString) => {
    const localDate = new Date(localDateString);
    const utcDateString = new Date(localDate.getTime()).toISOString();
    return new Date(utcDateString.slice(0, 19).replace('T', ' '));
  }

  // For the moment only used to generate csv file name
  // Example : 231025_14.50
  // Useful when having lot of files, it will be sorted
  const getYYYYMMDD_HHMM = (date) => {
    return getYYMMDD_HHMMNoLanguage(date)
  }

  const getFirstDayOfWeekForLibMoment = () => {
    switch (language) {
      case 'fr-FR': return 0; //monday
      case 'en-US': return 6; //sunday
      default: return 0;
    }
  }

  const getFirstDayOfWeekForJSDate = () => {
    switch (language) {
      case 'fr-FR': return 1; //monday
      case 'en-US': return 0; //sunday
      default: return 0;
    }
  }


  const getCsvDelimiter = () => {
    if (language.includes("fr")) {
      return CSV_DELIMITER_SEMICOLON
    }
    if (language.includes("en")) {
      return CSV_DELIMITER_COMMA
    }
    else {
      return CSV_DELIMITER_COMMA
    }
  }

  const getLocale = () => {
    if (!!language) {
      return localeWithoutRegionCode(language)
    } else {
      return undefined
    }
  }

  const getDayFirstLetterList = () => {
    switch (language) {
      case 'fr-FR': return ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
      case 'en-US':
      default: return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    }
  }


  return <ContextI18n.Provider value={{
    language, setLanguage,
    i18n,
    getJJMMAAAA,
    getUtcJJMMAAAA,
    getDayDateAndMonthAndYear,
    getShortDayName,
    getDayName,
    getMonthAndYear,
    getMMMdyyyy,
    getMMMyy,
    getHHMM,
    getUtcHHMM,
    getYYYY,
    getDateWithTime_HHMM,
    getLocaleDateTimeFromUtc,
    getUtcDateWithTime_HHMM,
    getYYYYMMDD_HHMM,
    getMomentum,
    getDayOfWeek,
    getFirstDayOfWeekForLibMoment,
    getFirstDayOfWeekForJSDate,
    getDayFirstLetterList,
    getCsvDelimiter,
    getLocale,
    getUTCdateFromLocalDateWithTZ
  }} {...props} />
}

export { UseContextI18nProvider, useContextI18n }
