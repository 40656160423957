import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getEndPointEntity } from "../api/endPoints";
import mapperEntities from "../components/analysis/mappers/mapperEntity";
import { ERROR_RESOLVE_ENTITY_LIST } from "../components/assets/const/ErrorCode";
import LoadingInfinityLogContexted from "../components/commons/navigation/LoadingInfinityLogContexted";
import {
  notifyError,
  notifySuccess,
} from "../components/commons/utils/notification/notification";
import { useContextI18n } from "./ContextI18n";
import { useContextLog } from "./ContextLog";
import { useContextModule } from "./ContextModule";
import { useContextUser } from "./ContextUser";

const ContextEntity = createContext({});
const useContextEntity = () => useContext(ContextEntity);

function UseContextEntityProvider(props) {
  const [entityList, setEntityList] = useState();
  const { i18n } = useContextI18n();
  const { urlApi, getRequestHeaders } = useContextUser();
  const { module } = useContextModule();
  const { addErrorLog } = useContextLog();

  const [needResolveEntityList, setNeedResolveEntityList] = useState(true);
  const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    if (showLoading && entityList) {
      setShowLoading(false)
    }
  }, [entityList, showLoading])

  const resolveEntityList = useCallback(async () => {
    try {
      if (!!module) {
        const endPoint = getEndPointEntity(urlApi, module?.name);
        axios
          .get(endPoint, getRequestHeaders())
          .then((response) => {
            if (!!response?.erreur) throw response.erreur;
            setEntityList(mapperEntities(response.data));
            notifySuccess(i18n("property.context.load.success"));
          })
          .catch(function (err) {
            addErrorLog(err, ERROR_RESOLVE_ENTITY_LIST)
            setNeedResolveEntityList(false);
          });
      }
      setNeedResolveEntityList(false);
    } catch (err) {
      addErrorLog(err, ERROR_RESOLVE_ENTITY_LIST)
      notifyError(err)
    }
  }, [module, urlApi, getRequestHeaders, i18n, addErrorLog]);

  useEffect(() => {
    if (!!module && needResolveEntityList) resolveEntityList();
  }, [needResolveEntityList, resolveEntityList, module]);




  return (
    <>
      <LoadingInfinityLogContexted
        message={i18n('context.entity.list.loading')}
        listErrorCode={[ERROR_RESOLVE_ENTITY_LIST]}
        showLoading={showLoading}
        isOverlayTransparent={false}
        onCloseCrashMode={() => setShowLoading(false)}
      />
      <ContextEntity.Provider
        value={{
          entityList,
        }}
        {...props}
      />
    </>
  );
}

export { UseContextEntityProvider, useContextEntity };
