import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const ButtonSearch = ({ activated }) => {

    return (
        <Button variant="default" disabled={!activated} >
            <FontAwesomeIcon icon={faSearch} />
        </Button>)

}


export default ButtonSearch

