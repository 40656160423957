import React from "react";
import { LightMode } from "../utils/ColorOverlayLayer";
import CommonOverlay from "../utils/CommonOverlay";

const CustomMenu = React.forwardRef(
  (
    {
      children,
      className,
      specificClassName,
      "aria-labelledby": labeledBy,
      show,
      onHide,
      containsGrid = false,
    },
    ref
  ) => {
    return (
      <CommonOverlay
        show={show}
        setShow={onHide}
        specificClassName={specificClassName}
        ColorOverlayLayer={LightMode}
      >
        <div className={className} aria-labelledby={labeledBy}>
          {containsGrid ? (
            <div className="list-in-grid">
              {React.Children.toArray(children).map((child, index) => {
                return child;
              })}
            </div>
          ) : (
            <div className="simple-item-list">
              <ul>
                {React.Children.toArray(children).map((child, index) => {
                  return child;
                })}
              </ul>
            </div>
          )}
        </div>
      </CommonOverlay>
    );
  }
);

export default CustomMenu;
