import { mapperList } from "./mapperList"

export const mapVersion = (json) => {

    const version = {
    //    id : json.id,
        id : json.tblIdTransactionVersion,
        label : json.label,
        code : json.code,
        coverageStart: !!json.coverageStart ? new Date(json.coverageStart) : null,
        coverageEnd: !!json.coverageEnd ? new Date(json.coverageEnd) : null
    }


    return version
}


export const mapType = (json) => {

    const lstVersions = []
    json.versions.map( jsonVersion => { 
        return lstVersions.push(mapVersion(jsonVersion))
    })

    const type = {
        id : json.id,
        label : json.label,
        code : json.code,
        versions : lstVersions
    }

    return type
}


export const mapScenarioTypesAndVersions = (json) => { return mapperList(json, mapType, "scenarios")}  
