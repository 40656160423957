import _ from "lodash"

const getMatchingVersionIdList = (scenario, lstMatchingErrors = [],  transactionTypeVersionsAvailable) => {

    if(!!transactionTypeVersionsAvailable && transactionTypeVersionsAvailable.length > 0){
        const typeTransaction = transactionTypeVersionsAvailable.find(t => t.id === scenario.typeId)
        if(!!typeTransaction){
            const version = typeTransaction.versions?.find(v => v.code === scenario.versionCode)
            if(!!version?.tblIdTransactionVersion) return version?.tblIdTransactionVersion
        }
    }else{
        console.warn("no transaction typeVersions available !")
    }

    lstMatchingErrors.push(scenario)
    return [];
}


/**
 * 
 * @param {*} lstScenario 
 * @param {*} transactionTypeVersionsAvailable 
 * update lstScenario with the new list of versionIds found in transactionTypeVersionsAvailable
 * 
 */
export const updateScenariosWithMatchingVersionTransaction = (lstScenario, lstMatchingErrors, transactionTypeVersionsAvailable) => {

    const newList = lstScenario?.map(scenario => {
        const newScenario = _.cloneDeep(scenario)
        const newVersionIds = getMatchingVersionIdList(scenario, lstMatchingErrors, transactionTypeVersionsAvailable)
        newScenario.versionId = newVersionIds
        return newScenario
    });
    lstScenario.length = 0; //empty list
    lstScenario.push(...newList)
}