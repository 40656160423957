import _ from 'lodash'

export const onBeanContextModification = (bean, attrName, value, onChange) => {
    onBeanContextModificationArray(bean, [attrName], [value],  onChange)
}

export const onBeanContextModificationArray = (bean, arrayAttrName, arrayValue, onChange) => {

    const beanCopy = { ...bean }
    let isChanged = false
    for (let i = 0; i < arrayAttrName.length; i++) {
        const attrName = arrayAttrName[i]
        const value = (i < arrayValue.length) ? arrayValue[i] : null

        if (! _.isEqual(beanCopy[attrName],value)) {

            beanCopy[attrName] = value
            isChanged = true 
        }    
    }

    if(isChanged) {
        onChange(beanCopy)
    }
}
