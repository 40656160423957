import { faTimes } from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import CommonButton from "./CommonButton"

// times like the icon [ X ]
const ButtonTimes = ({ onClick, text, tooltipText, tooltipPlacement, disabled = false, variant = "warning" }) => {
    return <CommonButton variant={variant} className="button-times" onClick={onClick} icon={faTimes} disabled={disabled} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement} />
}

ButtonTimes.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default ButtonTimes