import { createContext, useContext, useState } from 'react'



const ContextPerspective = createContext({})
const useContextPerspective = () => useContext(ContextPerspective)

function UseContextPerspectiveProvider(props) {

    const [perspective, setPerspective] = useState()

    return <ContextPerspective.Provider value={{
        perspective, setPerspective
    }} {...props} />
}

export { UseContextPerspectiveProvider, useContextPerspective}