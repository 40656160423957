import axios from "axios";
import { notifyError } from "../components/commons/utils/notification/notification";
import FileSaver from 'file-saver';


export const Method = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
}

function genericAPICall(url, method, params) {
    try {
        let call = undefined;
        switch (method) {
            case Method.GET:
                call = axios.get(url, params.header);
                break;
            case Method.POST:
                call = axios.post(url, params.data, params.header);
                break;
            case Method.PUT:
                call = axios.put(url, params.data, params.header);
                break;
            case Method.PATCH:
                call = axios.patch(url, params.data, params.header);
                break;
            case Method.DELETE:
                call = axios.delete(url, params.header);
                break;
            default:
                break;
        }
        if (call !== undefined) {
            call
                .then((response) => {
                    //console.log(`${method} : ${url} - SUCCESS`, response);
                    if (params.onSuccess instanceof Function) {
                        params.onSuccess(response);
                    }
                    if (params.finally instanceof Function) {
                        params.finally(response);
                    }
                }, (error) => {
                    if (params.onError instanceof Function) {
                        params.onError(error);
                    }
                    if (params.onCommonError instanceof Function) {
                        params.onCommonError(error);
                    }
                    if (params.finally instanceof Function) {
                        params.finally(error);
                    }
                })
        } else throw Error("Unable to get a promise");
    } catch (e) {
        console.log(e)
        notifyError(e)
    }
}

// implementation très bancale attention
function APIDownload(url, params, fileName, saveAsBlob = true) {
    if (saveAsBlob) {
        axios({
            ...params.header,
            url: url, //your url
            method: Method.GET,
        }).then(
            (response) => {
                FileSaver.saveAs(new Blob([response.data]), fileName);
            },
            (error) => {

            }
        );
    } else {
        FileSaver.saveAs(url, fileName);
    }
}

export const GenericService = (requestHeaders, commonInitParam = (params) => params) => {
    return {
        genericAPI: (url, method, params) => genericAPICall(url, method, { ...commonInitParam(params, method), header: requestHeaders() }),
        genericAPIGet: (url, params) => genericAPICall(url, Method.GET, { ...commonInitParam(params, Method.GET), header: requestHeaders() }),
        genericAPIPost: (url, params) => genericAPICall(url, Method.POST, { ...commonInitParam(params, Method.POST), header: requestHeaders() }),
        genericAPIPut: (url, params) => genericAPICall(url, Method.PUT, { ...commonInitParam(params, Method.PUT), header: requestHeaders() }),
        genericAPIDelete: (url, params) => genericAPICall(url, Method.DELETE, { ...commonInitParam(params, Method.DELETE), header: requestHeaders() }),
        genericAPIPatch: (url, params) => genericAPICall(url, Method.PATCH, { ...commonInitParam(params, Method.PATCH), header: requestHeaders() }),
        genericAPIDownload: (url, params, fileName, saveAsBlob) => APIDownload(url, { ...commonInitParam(params, Method.GET), header: requestHeaders() }, fileName, saveAsBlob)
    }
};


