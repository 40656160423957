import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

const ButtonAlert = ({ activated, id, onClick, className='' }) => {

    return (
        <Button variant="default"
            id={id}
            disabled={!activated}
            onClick={onClick}
            className={className}
        >
            <FontAwesomeIcon icon={faBell}  />
        </Button>)

}


export default ButtonAlert

