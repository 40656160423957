import React, { useEffect, useRef, useCallback, useState } from "react";
import CommonDropDown from "../../../assets/customs/dropdown/CommonDropdown";
import InputWithLabel from "../../form/commons/InputWithLabel";
import { LightMode } from "../../form/utils/ColorOverlayLayer";
import LoadingInfinity from "../../navigation/LoadingInfinity";
import "./FieldDropdownGridSelectorWrapper.scss";

import { useContextI18n } from "../../../../context/ContextI18n";
import PictoWithTooltip from "../../utils/picto/PictoWithTooltip";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import SpinnerInfinity from "../../navigation/spinner/SpinnerInfinity";
import PictoDelete from "../../utils/picto/common/PictoDelete";
import {
  getAttributeByPath,
  reduceObjectToKey,
} from "../../dx-form/utils/dataUtils";
import MenuGrid from "./MenuGrid";
import ShowMore from "../../form/utils/ShowMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const FieldDropdownGridSelectorWrapper = ({
  fieldValue, //setFieldValue,
  doIt, // appel à la fin pour valoriser le formulaire
  label,
  className = "",
  isMandatory,
  dataSource, // MANDATORY
  renderItemToggle, // ex : value => value?.description
  textComplementaire,
  needItemToggleDataSourceLoaded,
  onChangeReturnOnlyKey,
  keyExpr,
  datagridChildren,
  selection, // "single" by default or "multiple"
  //collapseAfterChange = false,
  remoteOperations = true,
  readOnly,
  // onChangeComplementary,
  gridWithFilter = true,
  displayMode = LightMode,

  showNbItemMax = 10,
  positionAt = "top",
  positionMy = "top center",
  positionOf,
  width,
  maxWidth,
  shading = true,
  valueStateOnGrid,
  onCellPrepared,
  onEditorPreparing,
  fullScreen,
  conditionItemIsDeletable = undefined, // Condition to validate the possibility of deletion of items 
  enableButtonResetInSingleMode = false,
  errorMsg,
}) => {

  const { i18n } = useContextI18n();
  const [collapsed, setCollapsed] = useState(true);
  const [fullValue, setFullValue] = useState() // object complet (alors que le value peut etre juste un id)
  const [notDeletableKeys, setNotDeletableKeys] = useState()
  const [showMore, setShowMore] = useState(false)
  const [isFullValueLoading, setIsFullValueLoading] = useState(false)
  const dataGridRef = useRef(null);

  const buildFilterKey = useCallback(
    (v) => {

      let filter;

      if (typeof v === "object" && v !== null) {
        filter = [keyExpr, "=", getAttributeByPath(v, keyExpr)];
      } else {
        filter = [keyExpr, "=", v];
      }

      return filter;
    },
    [keyExpr]
  );

  const loadCompleteValueFromDataSource = useCallback(async () => {

    if (!fieldValue || fieldValue === "" || !dataSource) return undefined;

    let valueLoaded;
    if (Array.isArray(dataSource)) {
      // gestion complete du cas ou le dataSource est un Array de values
      if (Array.isArray(fieldValue)) {

        valueLoaded = dataSource
          ?.filter((element) => element !== undefined)
          ?.filter((element) =>
            fieldValue.find((va) => va === element[keyExpr])
          );

      } else {
        valueLoaded = dataSource
          ?.filter((element) => element !== undefined)
          ?.filter((element) => element[keyExpr] === fieldValue);
      }

    } else {
      let filter;

      if (Array.isArray(fieldValue)) {
        if (fieldValue.length === 0) return [];
        filter = [];
        for (let index = 0; index < fieldValue.length; index++) {
          filter.push(buildFilterKey(fieldValue[index]));
          if (index !== fieldValue.length - 1) filter.push("or");
        }
      } else {
        filter = buildFilterKey(fieldValue);
      }
      setIsFullValueLoading(true);

      valueLoaded = await dataSource.load({ filter: filter }).then(
        () => setIsFullValueLoading(false),
        (err) => console.error(err));
      valueLoaded = valueLoaded?.data;
    }

    if (selection === "single") {
      if (
        !!valueLoaded &&
        Array.isArray(valueLoaded) &&
        valueLoaded.length > 0
      ) {
        valueLoaded = valueLoaded[0];
      } else {
        valueLoaded = undefined;
      }
    }

    let fullVal = !Array.isArray(valueLoaded) && selection === "multiple" ? [valueLoaded] : valueLoaded

    if (JSON.stringify(fullVal) !== JSON.stringify(fullValue)) {
      setFullValue(fullVal);
    }

  }, [buildFilterKey, dataSource, fieldValue, fullValue, keyExpr, selection]);


  const setFullValueFromFieldValue = useCallback(() => {

    if (needItemToggleDataSourceLoaded) {
      loadCompleteValueFromDataSource()

    } else {
      let fullVal =
        !Array.isArray(fieldValue) && selection === "multiple"
          ? [fieldValue]
          : fieldValue;

      if (typeof conditionItemIsDeletable === 'function') {

        const notDeletableList = fullVal?.filter(el => !conditionItemIsDeletable(el))
        if (Array.isArray(notDeletableList)) {
          const notDeletabelKeyList = notDeletableList.map(item => reduceObjectToKey(keyExpr, item))
          setNotDeletableKeys(notDeletabelKeyList);
        } else {
        }

      }

      if (JSON.stringify(fullVal) !== JSON.stringify(fullValue)) {
        setFullValue(fullVal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue])

  useEffect(() => {
    setFullValueFromFieldValue()
  }, [needItemToggleDataSourceLoaded, fieldValue, setFullValueFromFieldValue]);


  useEffect(() => {

    // test if fullValue data is anymore matching to fieldValue
    if (Array.isArray(fieldValue) && Array.isArray(fullValue) && selection === "multiple") {

      const v1 = fieldValue?.map(v => v?.[keyExpr]).sort().join(',')
      const v2 = fullValue?.map(v => v?.[keyExpr]).sort().join(',')

      if (v1 !== v2) {
        // need refresh fullValue
        setFullValueFromFieldValue()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue])

  const onChangeWrapper = useCallback(
    (res) => {
      let newValue = res;
      if (res === undefined && enableButtonResetInSingleMode) {
        doIt(undefined);
      }
      else if (Array.isArray(res)) {
        newValue = res?.map((e) =>
          onChangeReturnOnlyKey ? reduceObjectToKey(keyExpr, e) : e
        );
      } else {
        newValue = onChangeReturnOnlyKey
          ? reduceObjectToKey(keyExpr, res)
          : res;
      }
      if (JSON.stringify(newValue) !== JSON.stringify(fieldValue)) {
        if (typeof newValue === "object") {
          // clean
          delete newValue?.classCss;
          delete newValue?.gridButtonFunctions;
        }

        if (Array.isArray(newValue) && newValue.length > 0 && selection === 'single') {
          newValue = newValue[0]
        }

        // Valorise le field
        doIt(newValue);
      }
    },
    [enableButtonResetInSingleMode, fieldValue, doIt, onChangeReturnOnlyKey, keyExpr, selection]
  );




  ////////////////////////////////////////////////////

  //        VALIDATION

  ////////////////////////////////////////////////////

  const onFinalValidateWithValues = useCallback(async (datas) => {
    await onChangeWrapper(datas);
    setCollapsed(true);
  }, [onChangeWrapper]);

  // uniquement une fois que l'on sort de la popup avec le grid de sélection
  const onFinalValidate = useCallback(async () => {
    const grid = dataGridRef?.current?.instance;
    const datas = grid?.getSelectedRowsData();
    onFinalValidateWithValues(datas)

  }, [onFinalValidateWithValues]);



  const renderValueItem = useCallback((fieldValue) => {
    if (!renderItemToggle) {
      return "CommonDropdownGridSelector needs renderItemToggle";
    }
    else if (!!fieldValue) {
      return renderItemToggle(fieldValue)
    }
  }, [renderItemToggle]);


  const removeItemSingleMode = useCallback(async (v) => {
    if (enableButtonResetInSingleMode) {
      await onChangeWrapper()
    }
  },
    [enableButtonResetInSingleMode, onChangeWrapper]);

  const removeItem = useCallback(
    async (v) => {
      // forcement multiple mode
      await onChangeWrapper(
        fullValue.filter((val) => JSON.stringify(v) !== JSON.stringify(val))
      );
    },
    [fullValue, onChangeWrapper]);

  const removeAllItem = useCallback(async () => {
    // forcement multiple mode
    let newValue = []
    if (typeof conditionItemIsDeletable === 'function') {
      newValue = fullValue?.filter(el => !conditionItemIsDeletable(el))
    }
    await onChangeWrapper(newValue);
  }, [conditionItemIsDeletable, fullValue, onChangeWrapper]);

  const buildToggle = useCallback(() => {

    if (isFullValueLoading) {
      return (
        <div className="panel-multi-items-container">
          <span className="item"><FontAwesomeIcon icon={faSpinner} spin={true} /> {i18n("loading.data")}...</span>
        </div>
      );
    }

    if (
      selection === "multiple" &&
      (fullValue === undefined ||
        (Array.isArray(fullValue) && fullValue.length === 0) ||
        (Array.isArray(fieldValue) && fieldValue.length === 0))
    ) {
      return (
        <div className="panel-multi-items-container">
          <span className="item item-no-selected">{i18n("no.value")}</span>
        </div>
      );
    } else if (selection === "multiple") {
      return (
        <div className="panel-multi-items-container">
          <>
            {fullValue?.map((v, index) => {
              const isItemDeletable = conditionItemIsDeletable === undefined || conditionItemIsDeletable(v)
              if (showMore || (!showMore && index < showNbItemMax)) {
                return (
                  <span key={"toggle-item-" + index} className="item">
                    {renderValueItem(v)}{" "}
                    {!readOnly && isItemDeletable && (
                      <PictoDelete
                        className={"remove-item"}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeItem(v);
                        }}
                      />
                    )}{" "}
                  </span>
                );
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}
          </>
          <span className="remove-all">
            {fieldValue?.length > 1 && !readOnly && (
              <PictoWithTooltip
                icon={faTrashAlt}
                size="lg"
                tooltipText={i18n(`remove.all`)}
                onClick={(e) => {
                  e.stopPropagation();
                  removeAllItem();
                }}
              />
            )}
          </span>
          {fieldValue?.length > showNbItemMax && (
            <ShowMore
              showMore={showMore}
              setShowMore={setShowMore}
              nbItems={fieldValue?.length}
              showNbItemMax={showNbItemMax}
            />
          )}
        </div>
      );
    } else if (
      selection === "single" &&
      (fieldValue === undefined || fieldValue === null)
    ) {
      return (
        <span className="item item-max-size item-no-selected">
          {i18n("no.value")}
        </span>
      );
    } else if (selection === "single") {
      return (
        <span className="item item-max-size">
          {!isFullValueLoading ?
            renderValueItem(fullValue) :
            i18n("form.predicTemplate.predicTemplateSectionLine.PredicDriverBaseLine.request.loading")
          }
          {enableButtonResetInSingleMode ?
            <PictoDelete className={"remove-item"} onClick={(e) => { e.stopPropagation(); removeItemSingleMode(); }} /> :
            ""}
        </span>
      );
    }
    return <SpinnerInfinity size="xs" />;
  }, [selection, fullValue, fieldValue, i18n, readOnly, showNbItemMax, showMore, conditionItemIsDeletable,
    renderValueItem, removeItem, removeAllItem, isFullValueLoading, enableButtonResetInSingleMode, removeItemSingleMode]);

  const buildMenu = () => {

    return (
      <>
        {!!dataSource && (

          <MenuGrid
            className={className}
            fieldValue={fieldValue}
            dataSource={dataSource}
            dataGridRef={dataGridRef}
            keyExpr={keyExpr} //
            remoteOperations={remoteOperations}
            datagridChildren={datagridChildren}
            showFilterRow={gridWithFilter}
            selection={selection} // single or multiple
            afterDblClickOnRow={(value) => {
              onFinalValidateWithValues(value)
              setCollapsed(true);
            }}
            onCancel={() => setCollapsed(true)}
            onValidate={() => {
              onFinalValidate()
            }}
            valueStateOnGrid={valueStateOnGrid}
            onCellPrepared={onCellPrepared}
            onEditorPreparing={onEditorPreparing}
            notDeletableKeys={notDeletableKeys}
          />

        )}
      </>
    );
  };

  return (
    <InputWithLabel
      className={`${className} ${displayMode} field-dropdown-selector-wrapper`}
      label={label}
      isMandatory={isMandatory}
      textComplementaire={textComplementaire}
      input={
        <>
          {!dataSource && (
            <LoadingInfinity
              isOverlayTransparent={false}
              crashMode={true}
              crashTitle={`Field ${label} disconnected`}
            />
          )}

          <CommonDropDown
            label={label}
            collapsed={collapsed}
            setCollapsed={(bool) => {
              if(!isFullValueLoading) setCollapsed(bool);
            }}
            outlined={true}
            className={`${displayMode} dropdown-property dd-selector-wrapper ${isFullValueLoading?'full-value-loading':'full-value-ok'}`}
            toggle={buildToggle()}
            menu={buildMenu()}
            positionAt={positionAt}
            positionMy={positionMy}
            positionOf={positionOf}
            resizable={true}
            width={width}
            maxWidth={maxWidth}
            readOnly={readOnly}
            shading={shading}
            fullScreen={fullScreen}
          />
        </>
      }
      errorMsg={errorMsg}
    />
  );
};

export default FieldDropdownGridSelectorWrapper;
