import { getEndPointAppVersionning } from "../api/endPoints";
import { ERROR_APP_VERSIONNING } from "../components/assets/const/ErrorCode";
import { GenericInhovateService } from "./GenericInhovateService";

export const AppVersionningService = (urlApi, requestHeaders, contextLog) => {

    // error log
    const codeLogger = ERROR_APP_VERSIONNING;

    const genericService = GenericInhovateService(requestHeaders, contextLog, codeLogger);
    return {
        resolveAppVersionning: (params) => genericService.genericAPIGet(getEndPointAppVersionning(urlApi, params.data), params),
    }
};

