import { Form } from "react-bootstrap"
import FormPreferenceFormatterNumber from "../form/FormPreferenceFormatterNumber"
import './FormContextedPreferenceFormatterNumber.scss'

const FormContextedPreferenceFormatterNumber = ({ readOnly, displayColorMode, formatterNumber, formatterNumberContexted, onFormatterNumberModification }) => {
    return <Form className=" ">
        <FormPreferenceFormatterNumber
            formatterNumber={formatterNumber}
            onChangeValue={onFormatterNumberModification}
            readOnly={readOnly}
            displayColorMode={displayColorMode}
            formatterNumberContexted={formatterNumberContexted}
        />
    </Form>
}

export default FormContextedPreferenceFormatterNumber