
/*
This function simplifies the structure of a mapping object 
by reducing the complexity of the objects at the deepest level, 
which we'll refer to as 'entities'. 

It iterates over each level of the mapping object, 
extracting the IDs from the 'entities' and replacing them with a simplified list of IDs. 

Finally, it returns the simplified mapping object, 
where each entity's data is reduced to just a list of IDs.
*/

export const getSimplifiedMapOnlyWithEntitieIdList = (mapAuthorizedCompanyLicenseEntities) => {

    let simplifiedMap = {};

    for (let idCompany in mapAuthorizedCompanyLicenseEntities) {

        // Company level
        if (mapAuthorizedCompanyLicenseEntities.hasOwnProperty(idCompany)) {
            
            const mappingLicenseEntities = mapAuthorizedCompanyLicenseEntities[idCompany];
            const companyMap = {};

            // License level
            for (let idLicense in mappingLicenseEntities) {
                if (mappingLicenseEntities.hasOwnProperty(idLicense)) {
                    const entityList = mappingLicenseEntities[idLicense];
                    if (Array.isArray(entityList)) {
                        const idEntityList = entityList.map(entity => entity.id)
                        // Add the list of entities IDs to the result of the license mapping
                        companyMap[idLicense] = idEntityList;
                    }
                }
            }

            // Add the result of the company mapping to the global result
            simplifiedMap[idCompany] = companyMap;
        }
    }

    return simplifiedMap;
}