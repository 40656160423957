
/*
*

level 0 - groupFunctionalModule     => categorie
level 1 - functionalModule          => module group
level 4 - module avec listTopic     => module
                                    => topic
level 5 - screen                    => screen

// prévoir de rajouter une liste de metrics pour chaque screen

*/

const mapScreen = (jsonScreen) => {

    const screen = {
        name: jsonScreen.name,
        authorized: jsonScreen.authorized,
    }
    return screen
}


const mapTopic = (json, moduleName, topicName) => {
    const jsonTopicList = json.data.topic;
    const jsonScreenList = json.data.screen;
    if (!!jsonTopicList) {
        const jsonTopic = jsonTopicList.find(jt => jt.name === topicName)
        if (!!jsonTopic) {
            const topic = {
                name: jsonTopic.name,
                //authorized: jsonTopic.authorized, //en fonction des screens
                screenList: []
            }

            if (!!jsonScreenList) {
                
                topic.screenList = jsonScreenList
                    .filter(screen => screen.enumFields.topic === topicName
                        && screen.enumFields.module === moduleName)
                    .map(screen => mapScreen(screen))
                
                // set topic authorized attribut
                const nbAuthorizedScreens = jsonScreenList
                    .filter(screen => screen.enumFields.topic === topicName
                        && screen.enumFields.module === moduleName
                        && screen.authorized).length

                topic.authorized = nbAuthorizedScreens > 0
            }

            return topic
        }
    }
}



const mapModule = (json, jsonModule) => {

    const module = {
        name: jsonModule.name,
        authorized: jsonModule.authorized,
        topicList: []
    }

    module.topicList = jsonModule.enumFields.listTopic
        .map((topicName) => { return mapTopic(json, jsonModule.name, topicName) })

    return module
}


const mapGroup = (json, jsonGroup) => {

    const group = {
        name: jsonGroup.name,
        authorized: jsonGroup.authorized,
        moduleList: []
    }

    const jsonModuleList = json.data.module
    if (!!jsonModuleList) {
        group.moduleList = jsonModuleList
            .filter(module => { return module.enumFields.functionalModules === jsonGroup.name })
            .map((module) => { return mapModule(json, module) })
    }

    return group
}



const mapCategory = (json, catJson) => {

    //console.log('mapCategory')
    const jsonGroupModuleList = json.data.functionalModule

    const cat = {
        name: catJson.name,
        groupList: []
    }

    if (!!jsonGroupModuleList) {
        cat.groupList = jsonGroupModuleList
            .filter(group => { return group.enumFields.groupFunctionalModule === catJson.name })
            .map((group) => { return mapGroup(json, group) })
    }

    return cat
}


const mapperFunctionalModuleTree = (json) => {

    const jsonLstFuncGroupModuleList = json.data.groupFunctionalModule
    const listGroupFunctionalModule = []

    // console.log('mapperFonctionalModuleTree')
    // console.log(json.data)

    if (!!jsonLstFuncGroupModuleList) {

        jsonLstFuncGroupModuleList.map((cat) => {
            const funcCat = mapCategory(json, cat)
            return listGroupFunctionalModule.push(funcCat)
        })

    }

    return listGroupFunctionalModule

}

export default mapperFunctionalModuleTree