import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useContextAnalysis } from './ContextAnalysis'
import { isScenarioValid } from './ContextDataset'
import axios from 'axios'
import { getEndPointScenarioTypeList } from '../../api/endPoints'
import { useContextUser } from '../ContextUser'
import { useContextLog } from '../ContextLog'
import { updateScenariosWithMatchingVersionTransaction } from './utils/updateScenariosWithMatchingVersionTransaction'
import { wait } from '../../components/assets/utils'
import { useReducer } from 'react'

const ContextDatasetList = createContext({})
const useContextDatasetList = () => useContext(ContextDatasetList)

function UseContextDatasetListProvider(props) {

    const { urlApi, getRequestHeaders } = useContextUser()
    const { datasetList, lstPropertyLibrary } = useContextAnalysis()
    const [datasetListEdited, setDatasetListEdited] = useState([])
    const { addErrorLog } = useContextLog()
    const [needResolveCurrentDataset, setNeedResolveCurrentDataset] = useState(false)
    
    const actions = {
        ACTION_START_REPLACEMENT : "ACTION_START_REPLACEMENT",
        ACTION_INCONSITENCIES_OCCURED : "ACTION_INCONSITENCIES_OCCURED",
        ACTION_END_REPLACEMENT : "ACTION_END_REPLACEMENT",
        ACTION_CANCEL_REPLACEMENT : "ACTION_CANCEL_REPLACEMENT",
    }

    const initialState = {
        isGlobalDatasetsReplacementInProgress : false,
        globalDatasetsReplacementErrors : [],
        showPopupReplacementIfInconsistencies : false
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case actions.ACTION_START_REPLACEMENT:
                return {
                    ...state,
                    isGlobalDatasetsReplacementInProgress: true,
                    globalDatasetsReplacementErrors: [],
                    showPopupReplacementIfInconsistencies: false,
                }
            case actions.ACTION_INCONSITENCIES_OCCURED:
                return {
                    ...state,
                    globalDatasetsReplacementErrors: action.errorList,
                    showPopupReplacementIfInconsistencies: true
                }
            case actions.ACTION_END_REPLACEMENT:
            case actions.ACTION_CANCEL_REPLACEMENT:
                return {
                    ...initialState
                }
            default:
                return state;
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const isDatasetListModified = useMemo(() => {

        if ((!datasetListEdited && !!datasetList) || (!!datasetListEdited && !datasetList)) return true

        if (!datasetListEdited && !datasetList) return false

        if (datasetListEdited.length !== datasetList.length) return true

        let isModified = false

        for (let index = 0; index < datasetListEdited.length; index++) {
            isModified = !_.isEqual(datasetListEdited[index], datasetList[index]);
            if (isModified) {
                break
            }
        }
        return isModified
    }, [datasetList, datasetListEdited])


    useEffect(() => {
        if (!!datasetList) {
            setDatasetListEdited([...datasetList])
        }
    }, [datasetList, setDatasetListEdited])


    const getDatasetFromEditionList = (idDataset) => {

        if (!!datasetListEdited) {
            return datasetListEdited?.find(d => d?.id === idDataset)
        }
    }

    const updateDataset = (dataset) => {
        if (!!dataset) {
            const index = datasetListEdited?.indexOf(datasetListEdited?.find(d => d?.id === dataset?.id));
            const dslst = [...datasetListEdited]

            if (index !== -1) {
                dslst[index] = dataset;
            } else {
                // cas create 
                dslst.push(dataset)
            }
            setDatasetListEdited(dslst)
        }

    }

    const addDataset = (dataset) => {
        const dslst = [...datasetListEdited, dataset];
        setDatasetListEdited(dslst)
    }

    const getLstCodesUsed = (groupEdited) => {

        const lstCodes = datasetList?.map(ds => ds.code)

        // ajout des codes de variances
        groupEdited?.datasetStructure?.forEach(struct => {
            if (struct.type === 'variance') {
                lstCodes.push(struct.code)
            }
        })

        return lstCodes
    }

    const isVarianceValid = (varianceTest) => {

        return getLstFieldInErrorVariance(varianceTest).length < 1
    }

    const getLstFieldInErrorVariance = (varianceTest) => {

        const lstFieldError = []

        if (!varianceTest?.code)
            lstFieldError.push("code")


        if (!varianceTest?.id_1)
            lstFieldError.push("empty_id_1")

        if (!varianceTest?.id_2)
            lstFieldError.push("empty_id_2")


        if (!!varianceTest?.id_1 && !!varianceTest?.id_2) {
            const dataset1 = getDatasetFromEditionList(varianceTest?.id_1)
            const dataset2 = getDatasetFromEditionList(varianceTest?.id_2)

            if (!dataset1?.currencyCode || !dataset2?.currencyCode) {
                if (!dataset1?.currencyCode)
                    lstFieldError.push("missing_currency_1")
                if (!dataset2?.currencyCode)
                    lstFieldError.push("missing_currency_2")
            } else {
                if (dataset1?.currencyCode !== dataset2?.currencyCode)
                    lstFieldError.push("no_match_currency")
            }
        }

        return lstFieldError;
    }

    const updateDatasetPropertyIdList = (dataset, propertyIdList) => {

        return new Promise((resolve, reject) => {

            const bodyRequest = { entities: propertyIdList }
            
            axios.post(getEndPointScenarioTypeList(urlApi), bodyRequest, getRequestHeaders())
            .then( async(response) => {

                if (!!response?.erreur) throw response.erreur
                // update lstScenarios with transactionType versions matching to the new property list selected
                const transactionTypeVersionsAvailable = response?.data?.scenarios??[]
                const lstMatchingErrors = []
                updateScenariosWithMatchingVersionTransaction(dataset.lstScenarios, lstMatchingErrors, transactionTypeVersionsAvailable)

                if(lstMatchingErrors.length > 0){
                    const errorList = [] 
                    // to avoid duplicates
                    lstMatchingErrors.forEach(scenario => {
                        if(!errorList.find(e => e.typeId === scenario.typeId && e.versionCode === scenario.versionCode)){
                            errorList.push({typeId : scenario.typeId, versionCode : scenario.versionCode })
                        }
                    })
                    reject({done : false, errorList : errorList })
                }else{
                    resolve({done : true, scenarioList : dataset.lstScenarios })
                }

            }).catch(function (err) {
                addErrorLog(err)
                dispatch({ type: actions.ACTION_END_REPLACEMENT });
                reject(err)
            })


        })

    }

    const globalPropertyLibraryReplacement = (idLibrary, currentDatasetGroup, forceMode) => {

        // on all datasets of the current group,
        // update the attibutes : 
        // - propertyIdList with library.lstProperties
        // - propertyListLabel with library.label
        // - propertyLibraryId  with library.id

        return new Promise((resolve, reject) => {

        //setGlobalDatasetsReplacementInProgress(true)
        dispatch({ type: actions.ACTION_START_REPLACEMENT });
        //await wait(200)
        const library = lstPropertyLibrary.find(lib => lib.id === idLibrary)
        //const getDataSourceForexScenarios = new CustomStore(CommonDataSourceHandle(getEndPointForexScenarios(urlApi), getRequestHeaders, "", additionalRequestPayload));

        const bodyRequest = { entities: library.lstProperties }

        const transactionTypeVersionsAvailable = []
        axios.post(getEndPointScenarioTypeList(urlApi), bodyRequest, getRequestHeaders())
            .then( async(response) => {

                if (!!response?.erreur) throw response.erreur

                // update lstScenarios with transactionType versions matching to the new property list selected
                transactionTypeVersionsAvailable.push(...response?.data?.scenarios??[]);

                const lstIdDsOfCurrentGroup = currentDatasetGroup.datasetStructure.map(ds => ds.id)
                const copyDsList = _.cloneDeep(datasetList)
                const errorListOfScenario = []
                copyDsList.map(ds => {
                    if (lstIdDsOfCurrentGroup.includes(ds.id)) {
                        // update only datasets of the group
                        ds.propertyIdList = library.lstProperties
                        ds.propertyListLabel = library.label
                        ds.propertyLibraryId = library.id

                        updateScenariosWithMatchingVersionTransaction(ds.lstScenarios, errorListOfScenario, transactionTypeVersionsAvailable);
                    }
                    return ds
                })
                
                if(errorListOfScenario.length > 0 && !forceMode){
                    
                    const errorList = [] 
                    // to avoid duplicates
                    errorListOfScenario.forEach(scenario => {
                        if(!errorList.find(e => e.typeId === scenario.typeId && e.versionCode === scenario.versionCode)){
                            errorList.push({typeId : scenario.typeId, versionCode : scenario.versionCode })
                        }
                    })
                    
                    dispatch({ type: actions.ACTION_INCONSITENCIES_OCCURED, errorList : errorList });
                    resolve({nbErrors : errorList.length, forceMode: forceMode, done : false})

                }else{

                    setDatasetListEdited(copyDsList)
                    await wait(200)
                    setNeedResolveCurrentDataset(true)
                    await wait(200)
                    resolve({nbErrors : errorListOfScenario.length, forceMode: forceMode, done : true})
                }
                
              
            }).catch(function (err) {
                addErrorLog(err)
                dispatch({ type: actions.ACTION_END_REPLACEMENT });
                reject(err)
            })
        })
    }


    const globalCurrencyReplacement = (currency, currentDatasetGroup) => {

        // on all datasets of the current group,
        // update the currency 
        const lstIdDsOfCurrentGroup = currentDatasetGroup.datasetStructure.map(ds => ds.id)
        const copyDsList = _.cloneDeep(datasetList)
        copyDsList.map(ds => {
            if (lstIdDsOfCurrentGroup.includes(ds.id)) {
                ds.currencyCode = currency.code
            }
            return ds
        })
        setDatasetListEdited(copyDsList)
        // call context dataset to object the current dataset
        setNeedResolveCurrentDataset(true)
    }

    return <ContextDatasetList.Provider value={{
        datasetListEdited,
        isDatasetListModified,
        setDatasetListEdited,
        getDatasetFromEditionList,
        updateDataset,
        addDataset,
        getLstCodesUsed,
        isVarianceValid,
        needResolveCurrentDataset, setNeedResolveCurrentDataset,
        updateDatasetPropertyIdList,
        globalPropertyLibraryReplacement,
        globalCurrencyReplacement,
        getLstFieldInErrorVariance,
        isGlobalDatasetsReplacementInProgress : state.isGlobalDatasetsReplacementInProgress,
        showPopupReplacementIfInconsistencies : state.showPopupReplacementIfInconsistencies,
        globalDatasetsReplacementErrors : state.globalDatasetsReplacementErrors,
        cancelReplacement : () => dispatch({ type: actions.ACTION_CANCEL_REPLACEMENT })

    }} {...props} />
}

export { UseContextDatasetListProvider, useContextDatasetList }


export const isDatasetValid = (dataset, entityList) => {
    return getLstFieldInErrorDataset(dataset).length + getNbScenarioInErrorInDataset(dataset, entityList) < 1
}

export const getLstFieldInErrorDataset = (dataset) => {

    const lstFieldError = []

    if (!dataset?.code)
        lstFieldError.push("code")

    if (!dataset?.currencyCode)
        lstFieldError.push("currencyCode")


    if (!dataset?.propertyIdList || dataset?.propertyIdList.length < 1)
        lstFieldError.push("propertyIdList")

    if (!dataset?.lstScenarios || dataset?.lstScenarios.length < 1)
        lstFieldError.push("lstScenarios")

    // code label currencyCode
    return lstFieldError;
}

export const getNbScenarioInErrorInDataset = (dataset, entityList) => {

    let nbScenarionInError = 0;
    dataset?.lstScenarios?.forEach(s => { if (!isScenarioValid(s, dataset, entityList)) nbScenarionInError++ })

    return nbScenarionInError
}




