import notify from "devextreme/ui/notify"
import 'devextreme/dist/css/dx.material.blue.light.compact.css';

export const notifySuccess = (successMsg, width=320, duration=1000) => {
    notify({ message: successMsg, width: width }, 'success', duration)
}

export const notifyWarning = (warningMsg) => {
    notify({ message: warningMsg, width: 320 }, 'warning', 1000)
}

export const notifyError = (errorMsg, source, e) => {
    notify({ message: errorMsg, width: 320 }, 'error', 9000)
    if(!!source) console.warn(`ERROR source :  ${source} [${errorMsg}]`)
    if(!!e)console.error(e)
}