
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useContextI18n } from '../../../../context/ContextI18n'
import './PanelLanguageChooser.scss'


const PanelLanguageChooser = () => {

    const { language, setLanguage } = useContextI18n()
    const language_fr_FR = {code : 'fr', language : 'fr-FR'}
    //const language_nl_BE = {code : 'nl', language : 'nl-BE'}
    const language_en_US = {code : 'en', language : 'en-US'}
    const language_tr_TR = {code : 'tr', language : 'tr-TR'}

    const languageList =  [language_en_US, language_fr_FR, language_tr_TR,]


    const renderFlag = (langItem, index) => {
        const isSelected = langItem.language === language
        const className = isSelected ? `${langItem.code} selected`:`${langItem.code}`

        return(

            <Dropdown.Item  as='li' key={index} eventKey={index} className={className}
                onClick={()=>setLanguage(langItem.language)}>
                    <span className={`flag flag-${langItem.code}`}></span>
                    <span className="code">{langItem.code}</span>
            </Dropdown.Item>
        )
    }
  
    const listFlagItems = languageList.map((l,i) => renderFlag(l,i) )
    const selectedLanguage = languageList.find(l => l.language === language || l.code === language  )

    return( 
        <div className="panel-language-chooser">
           
            <Dropdown >
                <Dropdown.Toggle variant='Secondary'>

                    <span className={`flag flag-${selectedLanguage?.code}`}> </span>
                </Dropdown.Toggle>

                <Dropdown.Menu as='ul' >
                    {listFlagItems}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )

}


export default PanelLanguageChooser