const mapperEntities = (json) => {

    const jsonLstEntities = Object.values(json)
    const entitiesList = []
    if (!!jsonLstEntities) {
        jsonLstEntities.map( (vJson, index) => {
            let e = (vJson)
            if(!e?.id) {
                e.id = 1000+index
            }
            //e.hotel = mapperProperty(e.hotel)
            
            return entitiesList.push(e)
        })
    }
    return entitiesList
}



export default mapperEntities