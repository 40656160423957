import PropTypes from 'prop-types'
import PictoWithTooltip from '../PictoWithTooltip'
import './CommonPictoContainer.scss'


const CommonPictoContainer = ({icon, onClick=() => {}, text, tooltipText, tooltipPlacement="top", className, disabled=false, sizePicto}) => {
    
    return  <span className={`picto-container ${!!className?className:""} picto-click-${disabled?"disabled" : "enabled"}`} onClick={(e) => {if(!disabled) onClick(e)}} >
            <PictoWithTooltip className={`picto`} icon={icon} disabled={disabled} tooltipText={tooltipText} placement={tooltipPlacement} size={sizePicto}/>
        {!!text && (
            <>&nbsp;<span className={`picto-text ${!!sizePicto ? "fa-" + sizePicto : ""}`}>{text}</span></>
        )}
    </span>
}

CommonPictoContainer.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default CommonPictoContainer