import { faSave } from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import CommonButton from "./CommonButton"

const ButtonSave = ({onClick, type, text, tooltipText, tooltipPlacement, disabled=false, variant="primary"}) => {
    return <CommonButton type={type} variant={variant} className="button-save" onClick={onClick} icon={faSave} disabled={disabled} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement}/>
}

ButtonSave.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default ButtonSave