import './SpinnerInfinity.scss'


const SpinnerInfinity = ({size="sm", crashMode=false}) => {

    return (
        <div className={`spinner-infinity ${size} ${crashMode ? "crash-mode" : ""}`}>
            <div className="circle-ext first-circle">
                <div className="circle-int"/>
            </div>
            <div className="circle-ext">
                <div className="circle-int"/>
            </div>
            <div className="circle-ext last-circle">
                <div className="circle-int"/>
            </div>
            <div className="progress-container">
                <div className="progress-icon"></div>
            </div>
        </div>
    )
}

export default SpinnerInfinity