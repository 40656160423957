// Reorder a list (ex : d'n'd element moved)
export const reorder = (items, item, fromIndex, toIndex) => {
  //console.log(` fromIndex = ${fromIndex} & toIndex = ${toIndex}`)

  let orderedList = [...items];

  if (fromIndex >= 0) {
    orderedList = [
      ...orderedList.slice(0, fromIndex),
      ...orderedList.slice(fromIndex + 1),
    ];
  }

  if (toIndex >= 0) {
    orderedList = [
      ...orderedList.slice(0, toIndex),
      item,
      ...orderedList.slice(toIndex),
    ];
  }

  return orderedList;
};

export const getNewUniqueCode = (prefix, lstExistingCodes) => {
  // Si le préfixe finit avec des caractères numériques,
  // le code sera incrémenté à partir de la valeur numérique identifiée en fin de string
  const endCaracteresAreNumbers = [];

  for (let i = prefix?.length - 1; i >= 0; i--) {
    const character = prefix[i];
    if (/^\d+$/.test(character)) {
      // Test si le caractère est numérique
      endCaracteresAreNumbers.unshift(character); // Ajouter au début du tableau
    } else {
      break;
    }
  }

  let prefixToUse = prefix;
  let incrementStart = 0;
  if (endCaracteresAreNumbers.length > 0) {
    prefixToUse = prefix.slice(
      0,
      prefix.length - endCaracteresAreNumbers.length
    );
    incrementStart = +endCaracteresAreNumbers.join("");
  }

  const generateCode = (prefix, number) => {
    return `${prefix}${number.toString().padStart(endCaracteresAreNumbers.length, '0')}`;
  };

  const existingCodesSet = new Set(lstExistingCodes);

  for (let i = incrementStart; ; i++) {
    const code = generateCode(prefixToUse, i);
    if (!existingCodesSet.has(code)) {
      return code;
    }
  }
};


export const wait = (duration = 1000) => {
  return new Promise((resolve) => {
    window.setTimeout(resolve, duration);
  });
};
