export const SeparatorNone = {name : "none", symbol : ""} ;

export const SeparatorComma = {name : "comma", symbol : ","} ;

export const SeparatorSpace = {name : "space", symbol : " "} ;

export const SeparatorPoint = {name : "point", symbol : "."} ;

export const SeparatorApostrophe = {name : "apostrophe", symbol : "'"} ;


export function applyCustomSeparators(value, separatorThousand, separatorDecimal) {
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separatorThousand?.symbol);
    return parts.join(separatorDecimal?.symbol);
}