import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useContextI18n } from "../../../../context/ContextI18n";
import {
  ColorDisplayMode,
  LightMode,
} from "../../../assets/display/ColorDisplayMode";
import PictoMandatory from "../../utils/picto/common/PictoMandatory";
import { Slider } from "devextreme-react";

const InputInt = ({
  label,
  placeHolder,
  onChange,
  value,
  name,
  onBlur,
  textComplementaire,
  isMandatory,
  defaultValue, // value if null
  min,
  max,
  readOnly = false,
  displayColorMode = LightMode,
  inputStyle,
  showSlider = false,
  showInput = true,
  showMode
}) => {
  const { i18n } = useContextI18n();

  const controlValue = (valueAsNumber) => {
    if (isNaN(valueAsNumber)) return defaultValue;
    if ((!!min || min === 0) && valueAsNumber < min) return Math.trunc(min);
    if ((!!max || max === 0) && valueAsNumber > max) return Math.trunc(max);
    return Math.trunc(valueAsNumber);
  };

  return (
    <Form.Group
      className={`input-text input-int ${displayColorMode} ${readOnly ? "read-only" : ""
        }`}
    >
      {!!label && (
        <Form.Label className="input-int-form-label">
          {label}
          {isMandatory && <PictoMandatory />}
        </Form.Label>
      )}

      {showSlider && (
        <>
          <Slider
            readOnly={readOnly}
            min={min}
            max={max}
            value={value}
            tooltip={{
              enabled: false,
              format: function (value) { return value },
              position: "bottom",
              showMode: showMode
            }}
            label={{
              format: function (value) { return value },
              position: "top",
              visible: true
            }}
            onValueChanged={(e, v) => {
              if (!isNaN(+e.element?.firstElementChild?.value)) {
                onChange(+e.element?.firstElementChild?.value);
              }
            }}
          />
          {isNaN(min) && (
            <span>
              <br />
              No min !!!
            </span>
          )}
          {isNaN(max) && (
            <span>
              <br />
              No max !!!
            </span>
          )}
        </>
      )}
      {showInput && (

        <Form.Control
          name={name}
          onBlur={onBlur}
          readOnly={readOnly}
          onChange={(e) => onChange(controlValue(e.target.valueAsNumber), e)}
          type="number"
          placeholder={placeHolder ?? label}
          value={value}
          min={min}
          max={max}
          style={inputStyle}
        />

      )}

      {!!textComplementaire && (
        <Form.Text className="text-muted">{textComplementaire}</Form.Text>
      )}
      <Form.Control.Feedback type="invalid">
        {i18n("input.int.mandatory")}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

InputInt.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  //value: PropTypes.string,
  textComplementaire: PropTypes.string,
  isMandatory: PropTypes.bool,
  displayColorMode: PropTypes.oneOf(ColorDisplayMode),
};

export default InputInt;
