import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'


import { useContextUser } from './ContextUser'


const ContextModule = createContext({})
const useContextModule = () => useContext(ContextModule)

function UseContextModuleProvider(props) {

    const [moduleGroup, setModuleGroup] = useState()
    const [module, setModule] = useState()
//    const [topic, setTopic] = useState()
//    const [lstScreen, setLstScreen] = useState()
//    const [screen, setScreen] = useState()

    const { getFunctionalModuleTree } = useContextUser()

    const [needResolve, setNeedResolve] = useState(true)

    /**
     * Set ModuleGroup, Module, Topic default values 
     * (first of authorized list for each level)
     *  
     */


    const resolve = useCallback(() => {

        const fonctionalModuleTree = getFunctionalModuleTree()

        if (!!fonctionalModuleTree) {

            console.log(`CMTS >>> init`)
            

            let moduleGroupSelected = undefined
            let moduleSelected = undefined

            const groupList = fonctionalModuleTree?.groupList?.filter(g => g.authorized)

            // set default values
            // moduleGroup
            if (groupList?.length > 0) {
                moduleGroupSelected = groupList[0]
            }

            // module
            if (moduleGroupSelected?.moduleList?.filter(m => m.authorized).length > 0) {
                moduleSelected = moduleGroupSelected.moduleList.filter(m => m.authorized)[0]
            }

            if(!!moduleGroupSelected && !!moduleSelected){
                setModuleGroup(moduleGroupSelected)
                setModule(moduleSelected)
//                setTopic(topicSelected)
//                setScreen(screenSelected)
                setNeedResolve(false)
            }


        }

    }, [getFunctionalModuleTree])



    useEffect(() => {
        if (needResolve) resolve()
    }, [needResolve, resolve])

   

    return <ContextModule.Provider value={{
        moduleGroup, setModuleGroup,
        module, setModule,
//        topic, setTopic,
//        screen, setScreen,


    }} {...props} />
}


export { UseContextModuleProvider, useContextModule }
