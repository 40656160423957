import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./LogPanel.scss";
import parse from 'html-react-parser';

const LogItem = ({ log }) => {
  const [collapsed, setCollapsed] = useState(true);

  const time = new Date(log.time)

  const diff = {}
  let tmp = new Date() - time;

  tmp = Math.floor(tmp / 1000);             // Number of seconds 2 dates
  diff.sec = tmp % 60;                    // Extraction du nombre de secondes
  tmp = Math.floor((tmp - diff.sec) / 60);    // Number of minutes 
  diff.min = tmp % 60;

  let lastNewLevel = 'cold'
  if (diff.min < 1) {
    lastNewLevel = 'hot'
  } else if (diff.min < 10) {
    lastNewLevel = 'warm'
  }

  let devMsg = log.response?.message ?? log.response?.developerMessage ?? "no msg";

  if (typeof devMsg !== 'string') {
    devMsg = "Error message cannot be recovered"
  }

  // HTML error case
  const regHtmlBody = /<body[^>]*>([^]*)<\/body/m
  const inner = devMsg.match(regHtmlBody)
  if (!!inner && inner.length > 1)
    devMsg = parse(inner[1])

  return (
    <div className={`log-item ${log.type}`}>
      <div className="log-item-header" onClick={() => setCollapsed(!collapsed)}>
        <em className={`last-log ${lastNewLevel}`}>{time?.toLocaleString()}</em>
        <span className="code">[{log.code}]</span>
        <span className="status">{log.response?.status}</span>
        <span className="url">{log.url}</span>

        <span className="toggle">
          <FontAwesomeIcon icon={collapsed ? faChevronDown : faChevronUp} />
        </span>
      </div>

      {!collapsed && (
        <div className="log-item-msg-detail">
          {devMsg}
        </div>
      )}
    </div>
  );
};

export default LogItem;
