import React, {useRef} from "react"
import {UseContextCommonGridProvider} from "../../commons/dx-grid/ContextCommonGrid"
import {getEndPointCompany} from "../../../api/endPoints"
import {useContextUser} from "../../../context/ContextUser"
import CompanyGrid from "./CompanyGrid";

const CompanyGridContexted = ({selectionMode, onSelectionChanged}) => {
    const { urlApi, getRequestHeaders } = useContextUser();
    const dataGridRef = useRef(null)


    return (
        <UseContextCommonGridProvider urlApi={getEndPointCompany(urlApi)} getRequestHeaders={getRequestHeaders} >
            <CompanyGrid
                dataGridRef={dataGridRef}
                selectionMode={selectionMode}
                onSelectionChanged={onSelectionChanged}
            />
        </UseContextCommonGridProvider>
    )
}

CompanyGridContexted.propTypes = {

}

export default CompanyGridContexted