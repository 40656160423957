import DataGrid, {Selection} from "devextreme-react/data-grid"
import {useContextCommonGrid} from "../../commons/dx-grid/ContextCommonGrid"
import React from "react";
import CompanyFieldsGrid from "./CompanyFieldsGrid";
import {addColumnKeyAndDefaultSort, dataGridPagingAndPager} from "../../commons/dx-form/utils/dataUtils";

const CompanyGrid = ({dataGridRef, selectionMode, onRowClick, onSelectionChanged}) => {
    const {datasource} = useContextCommonGrid();

    return (
            <DataGrid
                ref={dataGridRef}
                dataSource={datasource}
                className="company-grid"
                showBorders={true}
                height={600}
                width={"100%"}
                remoteOperations={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                onSelectionChanged={onSelectionChanged}
                allowColumnResizing={true}
            >
                {dataGridPagingAndPager()}
                <Selection mode={selectionMode} />
                {addColumnKeyAndDefaultSort(CompanyFieldsGrid())}
            </DataGrid>
    )
}

export default CompanyGrid