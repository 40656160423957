import React from "react";
import { Position } from "devextreme-react/popup";
import PropTypes from "prop-types";
import ButtonValidate from "../../button/ButtonValidate";
import ButtonTimes from "../../button/ButtonTimes";
import { Popup } from "devextreme-react";
import { wait } from "../../../assets/utils";

const PopupConfirm = ({
  show,
  setShow,
  textTitle,
  textBody,
  textConfirm,
  textCancel,
  onConfirm,
  onCancel,
  variant = "default",
  className = "popup-confirm",
  positionAt = "center",
  positionMy = "center",
  positionOf = "body",
}) => {
  return (
    <>
      <Popup
        visible={show}
        onHiding={() => setShow(false)}
        dragEnabled={true}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={textTitle}
        width={400}
        height={"auto"}
        className={className}
      >
        <Position at={positionAt} my={positionMy} of={positionOf} />

        <p>{textBody}</p>

        <div className="footer">
          <ButtonTimes
            onClick={() => {
              setShow(false);
              if (!!onCancel) onCancel();
            }}
            text={textCancel}
          />
          {!!textConfirm &&
            <ButtonValidate
              variant={variant}
              onClick={async () => {
                if (!!onConfirm)
                  await wait(onConfirm())
                setShow(false);
              }}
              text={textConfirm}
            />
          }
        </div>
      </Popup>
    </>
  );
};

PopupConfirm.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default PopupConfirm;
