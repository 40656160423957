/******************************* */
/*     les ENDPOINTS de l'API    */
/******************************* */

import { notifyError } from "../components/commons/utils/notification/notification"

export const endPointAccessDictionary = 'v1/accessDictionary'

export const endPointUserPreferenceRetreive = 'v1/preference/retrieve'
export const endPointUserPreferenceCRUD = 'v1/preference'

export const endPointProperty = 'v1/hotels/bymodule' // TO DELETE
export const endPointFileImport = 'v1/v1/fileimports' // TODO : check the file import api call outside of front end
export const endPointEntity = 'v1/entities'
export const endPointEntityModule = 'v1/entities/bymodule'
export const endPointOneEntity = 'v1/entities'
export const endPointEntityRemap = 'v1/entities/remap'
export const endPointEntityRecalculateBalance = 'v1/entities/recalculatebalance'
export const endPointAppVersion = "v1/appversioning"
export const endPointFormulaPerspectiveUserList = 'v1/formula/perspective/user'
export const endPointDimensionsPerpsectiveList = 'v1/dimension/perspective'
export const endPointDimensions = 'v1/dimensions'
export const endPointDimensionsLnk = 'v1/mapping'
export const endPointMetrics = 'v1/metrics'
export const endPointFormulaList = 'v1/formulas/all'
export const endPointFormula = 'v1/formulas'
export const endPointPredicTemplate = 'v1/predicTemplates'
export const endPointPredicDeployed = 'v1/predicDeployed'
export const endPointPredicTemplateSection = 'v1/predicTemplatesSections'
export const endPointPredicDeployedSection = 'v1/predicDeployedSections'
export const endPointPredicWorkflows = 'v1/vPredicWorkflows'
export const endPointPredicWorkflowCounters = 'v1/vPredicWorkflowCounters'
export const endPointPredicTemplateSectionLine = 'v1/predicTemplatesSectionsLine'
export const endPointPredicTemplateSectionLinesDisplay = 'v1/predicTemplateSectionLinesDisplay'
export const endPointLstLinesDisplayThatReferTo = 'v1/predicTemplateSectionLinesDisplay/getLstLineDisplayThatReferTo'
export const endPointPredicDeployedSectionLine = 'v1/predicDeployedSectionsLine'
export const endPointPredicDeployedSectionLinesDisplay = 'v1/predicDeployedReminderLinesValues'
export const endPointPatchHistoricDeployedSection = 'v1/predicDeployedSections/historics'
export const endPointVPredicWorkflowQueues = 'v1/vPredicWorkflowQueues'
export const endPointTransactionVersioning = 'v1/transaction/versioning'
export const endPointForexScenarios = 'v1/forex/scenarios'
export const endPointTypeDimension = 'v1/typeDimension/all'
export const endPointUsers = 'v1/user'
export const endPointLicense = 'v1/license'
export const endPointLicenseEntity = 'v1/licenseentities'
export const endPointRemark = 'v1/predicRemark'
export const endPointNarrative = 'v1/predicNarrative'
export const endPointNarrativeCategory = 'v1/predicNarrativeCategory'
export const endPointOneNarrativeCategory = 'v1/predicNarrativeCategory'
export const endPointStatusInput = 'v1/statusInput'
export const endPointCompany = 'v1/company'
export const endPointAccounts = 'v1/accounts'
export const endPointHistoricTemplateSection = 'v1/predicTemplates/historic'
export const endPointPatchHistoricTemplateSectionAndColumnsPreferences = 'v1/predicTemplatesSections/historicsAndColumnsPreferences'
export const endPointInsertPredicTemplateSectionLineWithIndex = 'v1/predicTemplatesSectionsLine/upsertline'
export const endPointNotDeletableLines = 'v1/predicTemplatesSectionsLine/getNotDeletableLines'
export const endPointDuplicateSectionLine = 'v1/predicTemplatesSectionsLine/duplicateLine'
export const endPointDuplicateSectionLineMultiple = 'v1/predicTemplatesSectionsLine/duplicateLineMultiple'
export const endPointDuplicatePredicTemplateSectionDetail = 'v1/predicTemplatesSections/duplicateTemplateSectionDetail'
export const endPointVariablePerspectiveUserList = 'v1/variable/perspective/user'
export const endPointVariable = 'v1/variables'
export const endPointMaintenance = 'v1/maintenance'
export const endPointPredicCalculationQueue = 'v1/predicCalculationQueue'


export const endPointVariablesList = 'v1/variables/all'

export const endPointScreenDef = 'v1/screendef'
export const endPointScreenDefByModule = 'v1/screendef/bymodule'

export const endPointBloc = 'v1/blocs'
export const endPointBlocByScreenDef = 'v1/blocs/byscreendef'
export const endPointBlocSerializescreenblocs = 'v1/blocs/serializescreenblocs'
export const endPointBlocLines = 'v1/bloclines'
export const endPointCurrencies = 'v1/currency'
export const endPointCurrency = 'v1/currency/all'
export const endPointCurrencyConversion = 'api/v1/currencyconversions/all'
export const endPointCurrencyConversionCategory = 'api/v1/currencyconversions/categories'
export const endPointCurrencyConversionCategoryCheckValidity = 'api/v1/currencyconversions/categories/validity'
export const endPointEntityCheckValidity = 'v1/entities/validity'

export const endPointPerspective = 'api/v1/perspectives/byentities'
export const endPointLstPerspective = 'v1/perspectives'

export const endPointPerspectiveEntity = 'v1/perspectivesEntity'

export const endPointTransactionDetail = 'v1/transactiondetail'
export const endPointTransactionDetailDimensions = 'v1/transactiondetail/list_type_dimension'

//export const endPointMetrics = 'api/v1/metrics'

export const endPointUser = 'api/v1/user'

export const endPointEvalScreen = 'api/v1/evalScreen'
export const endPointEvalScreenListDim = 'api/v1/evalScreen/listdim'
export const endPointEvalScreenListValuesDim = 'api/v1/evalScreen/listvaluesdim'
export const endPointEvalScreenListValuesLstFormulaLstDim = 'api/v1/evalScreen/listvalueslstformulalstdim'
export const endPointEvalScreenPivotGrid = 'api/v1/evalScreen/pivotGrid'


export const endPointScenarioTypeList = 'v1/scenario/list'
export const endPointForexOptions = 'analysis-forex-options'

// see PreferenceType.java
export const PREF_TYPE_DATASETGROUP = 'DATASETGROUP'
export const PREF_TYPE_DXGRID_PROPERTY = 'GRIDPROPERTY'
export const PREF_TYPE_FORMATTAGE_NUMBER = 'FORMATTAGENUMBER'
// FILTER_BY_SCREEN 
// The variable is used to store the global filter at the screen level
export const PREF_TYPE_FILTER_BY_SCREEN = 'FILTER_BY_SCREEN'
// FILTER_BY_SCREEN_CUSTOM_LINES
// The variable is used to store a specific filter for each line
// idLine : {filter} => specific filter for the line
// idLine : {} => No filter
// idLine : undefined => Global FILTER is applied on the line (cf. FILTER_BY_SCREEN)
export const PREF_TYPE_FILTER_BY_SCREEN_CUSTOM_LINES = 'FILTER_BY_SCREEN_CUSTOM_LINES'
export const PREF_TYPE_ORDER_SCREEN_DEF = 'ORDER_SCREEN_DEF'


export const getEndPointAccessDictionary = (urlApi) => {
   return `${urlApi}/${endPointAccessDictionary}`
}

export const getEndPointPreferenceRetreive = (urlApi, idScreenDef, nameModule, nameTypePref, codePref = 'default') => {

   let url = `${urlApi}/${endPointUserPreferenceRetreive}/${nameTypePref}?`

   url += `code=${codePref}`

   if (!!idScreenDef) {
      url += `&idScreenDef=${idScreenDef}`
   }

   if (!!nameModule) {
      url += `&module=${nameModule}`
   }
   return url
}

export const getEndPointUserPreferenceCRUD = (urlApi) => {
   return `${urlApi}/${endPointUserPreferenceCRUD}`
}

export const getEndPointProperty = (urlApi, nameModule) => { // TO DELETE
   if (!!nameModule)
      return `${urlApi}/${endPointProperty}/${nameModule}`
   else
      return `${urlApi}/${endPointProperty}`

}

export const getEndPointEntity = (urlApi, nameModule) => {
   if (!nameModule) {
      return `${urlApi}/${endPointEntity}`
   } else {
      return `${urlApi}/${endPointEntityModule}/${nameModule}`
   }
}

export const getEndPointFileImport = (urlApi, idFileImport) => {
   if (!!idFileImport) {
      return `${urlApi}/${endPointFileImport}/${idFileImport}`
   } else {
      return `${urlApi}/${endPointFileImport}`
   }
}

export const getEndPointOneEntity = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointOneEntity}/${id}`
   } else {
      return `${urlApi}/${endPointOneEntity}`
   }
}

export const getEndPointEntityRemap = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointEntityRemap}/${id}`
   } else {
      notifyError(`End point error on Remap`)
   }
}

export const getEndPointEntityRecalculateBalance = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointEntityRecalculateBalance}/${id}`
   } else {
      notifyError(`End point error on Remap`)
   }
}

export const getEndPointFormulaList = (urlApi, idPerspective) => {
   return `${urlApi}/${endPointFormulaList}/${idPerspective}`
}

export const getEndPointFormulaPerspectiveUserList = (urlApi) => {
   return `${urlApi}/${endPointFormulaPerspectiveUserList}`
}

export const getEndPointDimensionsPerspectiveList = (urlApi) => {
   return `${urlApi}/${endPointDimensionsPerpsectiveList}`
}

export const getEndPointDimensions = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointDimensions}/${id}`
   } else {
      return `${urlApi}/${endPointDimensions}`
   }
}

export const getEndPointDimensionsGrid = (urlApi) => {
   return `${urlApi}/${endPointDimensions}/grid`
}

export const getEndPointDimensionsLnk = (urlApi, idDimensionSource) => {
   if (!!idDimensionSource) {
      return `${urlApi}/${endPointDimensionsLnk}/${idDimensionSource}`
   } else {
      return `${urlApi}/${endPointDimensionsLnk}`
   }
}

export const getEndPointMetric = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointMetrics}/${id}`
   } else {
      return `${urlApi}/${endPointMetrics}`
   }
}

export const getEndPointAppVersionning = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointAppVersion}/${id}`
   } else {
      return `${urlApi}/${endPointAppVersion}`
   }
}


export const getEndPointPredicTemplateSection = (urlApi, id, idPredict) => {
   if (!!idPredict && !!id) {
      return `${urlApi}/${endPointPredicTemplateSection}/${idPredict}/${id}`
   } else if (!!id) {
      return `${urlApi}/${endPointPredicTemplateSection}/${id}`
   } else {
      return `${urlApi}/${endPointPredicTemplateSection}`
   }
}

export const getEndPointPredicTemplateSectionReminders = (urlApi, idPredict, uuidPredicSection) => {
   if (!!idPredict && !!uuidPredicSection) {
      return `${urlApi}/${endPointPredicTemplateSection}/reminders/${idPredict}/${uuidPredicSection}`
   }
}

export const getEndPointPredicTemplateSectionLine = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointPredicTemplateSectionLine}/${id}`
   } else {
      return `${urlApi}/${endPointPredicTemplateSectionLine}`
   }
}

export const getEndPointPredicTemplateSectionLinesDisplay = (urlApi) => {
   return `${urlApi}/${endPointPredicTemplateSectionLinesDisplay}`
}

export const getEndPointLstLinesDisplayThatReferTo = (urlApi, idPredic, uuidSection) => {
   return `${urlApi}/${endPointLstLinesDisplayThatReferTo}/${idPredic}/${uuidSection}`
}

export const getEndPointPredicDeployedSectionLinesDisplay = (urlApi) => {
   return `${urlApi}/${endPointPredicDeployedSectionLinesDisplay}`
}



export const getEndPointPredicDeployedSectionLine = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointPredicDeployedSectionLine}/${id}`
   } else {
      return `${urlApi}/${endPointPredicDeployedSectionLine}`
   }
}


export const getEndPointVPredicWorkflowQueues = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointVPredicWorkflowQueues}/${id}`
   } else {
      return `${urlApi}/${endPointVPredicWorkflowQueues}`
   }
}


export const getEndPointPredicTemplate = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointPredicTemplate}/${id}`
   } else {
      return `${urlApi}/${endPointPredicTemplate}`
   }
}



export const getEndPointPredicTemplateWithUuid = (urlApi, uuid) => {
   if (!!uuid) {
      return `${urlApi}/${endPointPredicTemplate}/${uuid}`
   } else {
      return `${urlApi}/${endPointPredicTemplate}`
   }
}

//Todo list
export const getEndPointPredicWorkflows = (urlApi) => {
   return `${urlApi}/${endPointPredicWorkflows}`
}

//Todo list counters
export const getEndPointPredicWorkflowCounters = (urlApi) => {
   return `${urlApi}/${endPointPredicWorkflowCounters}`
}

export const getEndPointPredicTemplateCheck = (urlApi, uuid) => {
   // GET
   return `${urlApi}/${endPointPredicTemplate}/check/${uuid}`
}

export const getEndPointPredicTemplateDuplicate = (urlApi, uuidPredicTemplate) => {
   // POST
   return `${urlApi}/${endPointPredicTemplate}/duplicate/${uuidPredicTemplate}`
}

export const getEndPointPredicDeployed = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointPredicDeployed}/${id}`
   } else {
      return `${urlApi}/${endPointPredicDeployed}`
   }
}

export const getEndPointExportPredicTemplateSectionCharacteristics = (urlApi, idPredic, csvDelimiter) => {
   return `${urlApi}/${endPointPredicTemplate}/exportSectionsCharacteristics/${idPredic}/${csvDelimiter}`
}


export const getEndPointExportPredicDeployedSectionCharacteristics = (urlApi, idPredic, csvDelimiter) => {
   return `${urlApi}/${endPointPredicDeployed}/exportSectionsCharacteristics/${idPredic}/${csvDelimiter}`
}

export const getEndPointPredicDeployedSection = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointPredicDeployedSection}/${id}`
   } else {
      return `${urlApi}/${endPointPredicDeployedSection}`
   }
}

export const getEndPointPredicDeployedSectionWithCalculation = (urlApi, idPredic, uuidSection, periodicity, nbPeriod, nbPeriodHistoric) => {
   return `${urlApi}/${endPointPredicDeployedSection}/inputcalc/${idPredic}/${uuidSection}/${periodicity}/${nbPeriod}/${nbPeriodHistoric}`
}

export const getEndPointFormula = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointFormula}/${id}`
   } else {
      return `${urlApi}/${endPointFormula}`
   }
}

export const getEndPointVariablePerspectiveUserList = (urlApi) => {
   return `${urlApi}/${endPointVariablePerspectiveUserList}`
}

export const getEndPointVariable = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointVariable}/${id}`
   } else {
      return `${urlApi}/${endPointVariable}`
   }
}

export const getEndPointMaintenance = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointMaintenance}/${id}`
   } else {
      return `${urlApi}/${endPointMaintenance}`
   }
}

export const getEndPointVariablesList = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointVariablesList}/${id}`
   } else {
      return `${urlApi}/${endPointVariablesList}`
   }
}

export const getEndPointScreenDef = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointScreenDef}/${id}`
   } else {
      return `${urlApi}/${endPointScreenDef}`
   }
}

export const getEndPointBloc = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointBloc}/${id}`
   } else {
      return `${urlApi}/${endPointBloc}`
   }
}

export const getEndPointBlocByScreenDef = (urlApi, idScreenDef) => {
   if (!!idScreenDef) {
      return `${urlApi}/${endPointBlocByScreenDef}/${idScreenDef}`
   } else {
      return `${urlApi}/${endPointBlocByScreenDef}`
   }
}

export const getEndPointBlocSerializescreenblocs = (urlApi) => {
   return `${urlApi}/${endPointBlocSerializescreenblocs}`
}

export const getEndPointScreenDefByModule = (urlApi, module, perspective, datasetGroupIdSelected) => {
   return `${urlApi}/${endPointScreenDefByModule}/${module?.name}/${perspective?.id}/${datasetGroupIdSelected}`
}
export const getEndPointCurrencies = (urlApi) => {
   return `${urlApi}/${endPointCurrencies}`
}

export const getEndPointCurrency = (urlApi) => {
   return `${urlApi}/${endPointCurrency}`
}

export const getEndPointPerspective = (urlApi) => {
   return `${urlApi}/${endPointPerspective}`
}

export const getEndPointLstPerspective = (urlApi) => {
   return `${urlApi}/${endPointLstPerspective}`
}

export const getEndPointLstPerspectiveEntity = (urlApi) => {
   return `${urlApi}/${endPointPerspectiveEntity}`
}

/* export const getEndPointMetrics = (urlApi) => {
   return `${urlApi}/${endPointMetrics}`
} */

export const getEndPointTransactionDetail = (urlApi) => {
   return `${urlApi}/${endPointTransactionDetail}`
}

export const getEndPointTransactionDetailDimensions = (urlApi) => {
   return `${urlApi}/${endPointTransactionDetailDimensions}`
}

export const getEndPointEvalScreen = (urlApi) => {
   return `${urlApi}/${endPointEvalScreen}`
}

export const getEndPointEvalScreenListDim = (urlApi) => {
   return `${urlApi}/${endPointEvalScreenListDim}`
}

export const getEndPointEvalScreenListValuesDim = (urlApi) => {
   return `${urlApi}/${endPointEvalScreenListValuesDim}`
}

export const getEndPointEvalScreenListValuesLstFormulaLstDim = (urlApi) => {
   return `${urlApi}/${endPointEvalScreenListValuesLstFormulaLstDim}`
}

export const getEndPointEvalScreenPivotGrid = (urlApi) => {
   return `${urlApi}/${endPointEvalScreenPivotGrid}`
}

export const getEndPointCurrencyConversion = (urlApi) => {
   return `${urlApi}/${endPointCurrencyConversion}`
}

export const getEndPointCurrencyConversionCategory = (urlApi) => {
   return `${urlApi}/${endPointCurrencyConversionCategory}`
}

export const getEndPointCurrencyConversionCategoryCheckValidity = (urlApi) => {
   return `${urlApi}/${endPointCurrencyConversionCategoryCheckValidity}`
}


export const getEndPointEntityCheckValidity = (urlApi) => {
   return `${urlApi}/${endPointEntityCheckValidity}`
}

export const getEndPointScenarioTypeList = (urlApi) => {
   return `${urlApi}/${endPointScenarioTypeList}`
}

export const getEndPointForexOptions = (urlApi) => {
   return `${urlApi}/${endPointForexOptions}`
}

export const getEndPointTransactionVersioning = (urlApi) => {
   return `${urlApi}/${endPointTransactionVersioning}`
}

export const getEndPointTransactionVersioningInvolvedInTemplate = (urlApi, idPredic) => {
   return `${urlApi}/${endPointTransactionVersioning}/predicTVInvolved/${idPredic}`
}

export const getEndPointForexScenarios = (urlApi) => {
   return `${urlApi}/${endPointForexScenarios}`
}

export const getEndPointTypeDimension = (urlApi) => {
   return `${urlApi}/${endPointTypeDimension}`
}


export const getEndPointUsers = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointUsers}/${id}`
   } else {
      return `${urlApi}/${endPointUsers}`
   }
}

export const getendPointExportPredictiveStatistics = (urlApi) => {
   return `${urlApi}/${endPointUsers}/export/statistic`
}

export const getEndPointCompany = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointCompany}/${id}`
   } else {
      return `${urlApi}/${endPointCompany}`
   }
}


export const getEndPointAccounts = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointAccounts}/${id}`
   } else {
      return `${urlApi}/${endPointAccounts}`
   }
}

export const getEndPointLicense = (urlApi) => {
   return `${urlApi}/${endPointLicense}`
}

export const getEndPointLicenseEntity = (urlApi) => {
   return `${urlApi}/${endPointLicenseEntity}`
}

export const getEndPointRemark = (urlApi) => {
   return `${urlApi}/${endPointRemark}`
}

export const getEndPointNarrative = (urlApi) => {
   return `${urlApi}/${endPointNarrative}`
}

export const getEndPointNarrativeCategory = (urlApi) => {
   return `${urlApi}/${endPointNarrativeCategory}`
}

export const getEndPointOneNarrativeCategory = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointOneNarrativeCategory}/${id}`
   } else {
      return `${urlApi}/${endPointOneNarrativeCategory}`
   }
}

export const getEndPointStatusInput = (urlApi) => {
   return `${urlApi}/${endPointStatusInput}`
}

export const getEndPointUser = (urlApi) => {
   return `${urlApi}/${endPointUser}`
}

export const getEndPointBlocLines = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointBlocLines}/${id}`
   } else {
      return `${urlApi}/${endPointBlocLines}`
   }
}

export const getEndPointHistoricTemplateSection = (urlApi, idTemplate, uuidSecton) => {
   return `${urlApi}/${endPointHistoricTemplateSection}/${idTemplate}/${uuidSecton}`
}

export const getEndPointPatchHistoricTemplateSectionAndColumnsPreferences = (urlApi, idTemplate, uuidSecton) => {
   return `${urlApi}/${endPointPatchHistoricTemplateSectionAndColumnsPreferences}/${idTemplate}/${uuidSecton}`
}

export const getEndPointPatchHistoricDeployedSection = (urlApi, idTemplate, uuidSecton) => {
   return `${urlApi}/${endPointPatchHistoricDeployedSection}/${idTemplate}/${uuidSecton}`
}

export const getEndPointUpsertPredicTemplateSectionLineWithIndex = (urlApi, idTemplate, uuidSection, indexSelectedSectionDetail, uuidSectionDetails, indexForInsertionInList) => {
   return `${urlApi}/${endPointInsertPredicTemplateSectionLineWithIndex}/${idTemplate}/${uuidSection}/${indexSelectedSectionDetail}/${uuidSectionDetails}/${indexForInsertionInList}`
}

export const getEndPointDuplicateSectionLine = (urlApi, idTemplate, uuidSection, indexSelectedSectionDetail, uuidSectionDetails, uuidSectionLine, indexForInsertionInList) => {
   return `${urlApi}/${endPointDuplicateSectionLine}/${idTemplate}/${uuidSection}/${indexSelectedSectionDetail}/${uuidSectionDetails}/${uuidSectionLine}/${indexForInsertionInList}`
}

export const getEndPointDuplicateSectionLineMultiple = (urlApi, idTemplate, uuidSection, indexSelectedSectionDetail, uuidSectionDetails, uuidSectionLine, indexForInsertionInList) => {
   return `${urlApi}/${endPointDuplicateSectionLineMultiple}/${idTemplate}/${uuidSection}/${indexSelectedSectionDetail}/${uuidSectionDetails}/${uuidSectionLine}/${indexForInsertionInList}`
}

export const getEndPointDuplicatePredicTemplateSectionDetail = (urlApi, idTemplate, uuidSection, uuidSectionDetails) => {
   return `${urlApi}/${endPointDuplicatePredicTemplateSectionDetail}/${idTemplate}/${uuidSection}/${uuidSectionDetails}`
}

export const getEndPointNotDeletableLines = (urlApi, idPredic, uuidSection, isCtrlWithMySectionLevel) => {
   return `${urlApi}/${endPointNotDeletableLines}/${idPredic}/${uuidSection}/${isCtrlWithMySectionLevel}`
}

export const getEndPointDisablePredicTemplateSections = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicTemplate}/disable/${idPredic}`
}

export const getEndPointDeletePredicTemplateSections = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicTemplate}/deletesections/${idPredic}`
}


export const getEndPointActivatePredicTemplateSections = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicTemplate}/enable/${idPredic}`
}

export const getEndPointSectionIndxWithParentList = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicTemplate}/listindexsection/${idPredic}`
}

export const getEndPointUpsertSlippingRules = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicTemplate}/upsertSlippingRules/${idPredic}`
}

export const getEndPointClearUnhelpfulRelationships = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicTemplateSection}/clearimplicitsectionfather/${idPredic}`
}

export const getEndPointSubmitSection = (urlApi, idPredic, uuidSection, submitAndApprove, isDoneByFinalizer) => {
   return `${urlApi}/${endPointPredicDeployedSection}/submit/${idPredic}/${uuidSection}/${submitAndApprove}/${isDoneByFinalizer}`
}

export const getEndPointApproveSection = (urlApi, idPredic, uuidSection, isDoneByFinalizer) => {
   return `${urlApi}/${endPointPredicDeployedSection}/approve/${idPredic}/${uuidSection}/${isDoneByFinalizer}`
}

export const getEndPointDisapproveSection = (urlApi, idPredic, uuidSection, isDoneByFinalizer) => {
   return `${urlApi}/${endPointPredicDeployedSection}/disapprove/${idPredic}/${uuidSection}/${isDoneByFinalizer}`
}

export const getEndPointReviewSection = (urlApi, idPredic, uuidSection) => {
   return `${urlApi}/${endPointPredicDeployedSection}/review/${idPredic}/${uuidSection}`
}

export const getEndPointUpdateWorkflowMapIdSectionDptPos = (urlApi, uuidPredic) => {
   return `${urlApi}/${endPointPredicTemplateSection}/updateWorkflowMapIdSectionDptPos/${uuidPredic}`
}

export const getEndPointUpdateWorkflowDesignGlobal = (urlApi, uuidPredic) => {
   return `${urlApi}/${endPointPredicTemplateSection}/updateWorkflowDesignGlobal/${uuidPredic}`
}

export const getEndPointUpdateCurrencyManualLines = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicTemplateSection}/patchmanualcurrency/${idPredic}`
}

export const getEndPointUpdateDeployedWorkflowMapIdSectionDptPos = (urlApi, uuidPredic) => {
   return `${urlApi}/${endPointPredicDeployedSection}/updateDeployedWorkflowMapIdSectionDptPos/${uuidPredic}`
}

export const getEndPointExportPredicDeployedSection = (urlApi, idPredic, uuidSection, isManualLine, isAssumptionBasedLine, isDriverBasedLine, isEntryLock, csvDelimiter) => {
   return `${urlApi}/${endPointPredicDeployedSection}/export/${idPredic}/${uuidSection}/${isManualLine}/${isAssumptionBasedLine}/${isDriverBasedLine}/${isEntryLock}/${csvDelimiter}`
}

export const getEndPointExportLinesCharacteristics = (urlApi, idPredic, uuidSection, exportLevel, csvDelimiter) => {
   return `${urlApi}/${endPointPredicDeployedSection}/exportLinesCharacteristics/${idPredic}/${uuidSection}/${exportLevel}/${csvDelimiter}`
}

export const getEndPointImportPredicDeployedSection = (urlApi, idPredic, uuidSection, csvDelimiter) => {
   return `${urlApi}/${endPointPredicDeployedSection}/import/${idPredic}/${uuidSection}/${csvDelimiter}`
}

export const getEndPointPredicCalculationQueue = (urlApi, id) => {
   if (!!id) {
      return `${urlApi}/${endPointPredicCalculationQueue}/${id}`
   } else {
      return `${urlApi}/${endPointPredicCalculationQueue}`
   }
}

export const getEndPointExportPredicFinalizedReverseMapping = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicDeployed}/download-reverse-mapping/${idPredic}`
}

export const getEndPointExportPredicFinalizedExtractERP = (urlApi, idPredic, erpType) => {
   return `${urlApi}/${endPointPredicDeployed}/extract-erp/${idPredic}/${erpType}`
}

export const getEndPointReverseMapPredicFinalized = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicDeployed}/reverse-map/${idPredic}`
}

export const getEndPointReverseMapArbitrationData = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicDeployed}/reverse-map-arbitration-data/${idPredic}`
}

export const getEndPointPatchReverseMapArbitration = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicDeployed}/saveReverseMapping`
}

export const getEndPointExportTransactionData = (urlApi, idPredic) => {
   return `${urlApi}/${endPointPredicDeployed}/exportFinalizationFile/${idPredic}`
}
