import {getEndPointDimensions} from "../api/endPoints";
import {ERROR_PREDIC_TEMPLATE} from "../components/assets/const/ErrorCode";
import {GenericInhovateService} from "./GenericInhovateService";

export const DimensionsService = (urlApi, requestHeaders, contextLog) => {

    // error log
    const codeLogger = ERROR_PREDIC_TEMPLATE;

    const genericService = GenericInhovateService(requestHeaders, contextLog, codeLogger);
    return {
        getAllTypeDimension: (idPerspective, params) => {genericService.genericAPIGet(`${getEndPointDimensions(urlApi)}/allTypeDimension/${idPerspective}`, params)}
    }
};

