import React, { useState } from 'react'
import { useContextFormatterNumber } from '../../../../../context/ContextFormatterNumber'
import { useContextI18n } from '../../../../../context/ContextI18n'
import ButtonSave from "../../../button/ButtonSave"
import FormContextedPreferenceFormatterNumber from '../form-in-context/FormContextedPreferenceFormatterNumber'
import { LINE_FORMATTER_NUMBER } from './FormatterNumberMode'
import { FORM_BLOC } from '../../../../analysis/grid/form-bloc/FormBlocShownModal'
import _ from 'lodash'
import { Popup } from 'devextreme-react'

const ModalFormContextedPreferenceFormatterNumber = ({
    setShow,
    show,
    readOnly,
    formatterNumberMode,
    formattageNumber,
    onChangeFormatterNumber
}) => {

    const { i18n } = useContextI18n();
    const { saveFormatterNumber, formatterNumber, onFormatterNumberModification } = useContextFormatterNumber()

    const [formatterNumberFromBlocline, setFormatterNumberFromBlocline] = useState(!!formattageNumber ? formattageNumber : {})

    function updateValueInObject(key, value) {
        let copy = _.cloneDeep(formatterNumberFromBlocline)
        copy[key] = value
        setFormatterNumberFromBlocline({ ...copy })
    }

    return (
        <Popup
            
                visible={true} 
                onHidden={() => {
                    formatterNumberMode === LINE_FORMATTER_NUMBER ? 
                    setShow(FORM_BLOC) :
                    setShow(undefined)}
                }
                dragEnabled={true}
                hideOnOutsideClick={false} // important
                hideOnParentScroll={false} // important
                shading={true}
                showCloseButton={true}
                showTitle={true}
                title= {formatterNumberMode === LINE_FORMATTER_NUMBER ? i18n("modal.form.line.formatter.number")
                : i18n("modal.form.preference.formatter.title")}
                width={600}
                height="auto"
            >
      
                {formatterNumberMode === LINE_FORMATTER_NUMBER ?
                    <FormContextedPreferenceFormatterNumber
                        readOnly={readOnly}
                        formatterNumber={formatterNumberFromBlocline}
                        formatterNumberContexted={formatterNumber}
                        onFormatterNumberModification={updateValueInObject}
                    />
                    : <FormContextedPreferenceFormatterNumber
                        readOnly={readOnly}
                        formatterNumber={formatterNumber}
                        onFormatterNumberModification={onFormatterNumberModification} />
                }
          
            <div className='footer'>
                <ButtonSave
                    text={i18n("form.preference.save")}
                    disabled={readOnly}
                    onClick={() => formatterNumberMode === LINE_FORMATTER_NUMBER ? onChangeFormatterNumber(formatterNumberFromBlocline) : saveFormatterNumber()} />
            </div>
        </Popup>
    )
}

export default ModalFormContextedPreferenceFormatterNumber;