import { faArrowRight, faWalking } from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import CommonButton from "./CommonButton"

const ButtonLogout = ({onClick, text, tooltipText, tooltipPlacement, disabled=false, hidden=false, variant="primary"}) => {
    return <CommonButton variant={variant} className="button-logout" onClick={onClick} 
        icon={faWalking}
        icon2={faArrowRight} 
        disabled={disabled} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement} hidden={hidden} />
}

ButtonLogout.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default ButtonLogout