import { faUndo } from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import CommonButton from "./CommonButton"

const ButtonReset = ({ onClick, text, tooltipText, tooltipPlacement, disabled = false, hidden = false, variant = "danger" }) => {
    if (hidden) return null;
    return <CommonButton variant={variant} className="button-reset" onClick={onClick} icon={faUndo} disabled={disabled} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement} />
}

ButtonReset.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default ButtonReset