import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { PREF_TYPE_FORMATTAGE_NUMBER } from "../api/endPoints";
import { useContextUser } from "./ContextUser";
import { onBeanContextModification } from "./CommonMethodContext";
import { useContextLog } from "./ContextLog";
import { notifySuccess } from "../components/commons/utils/notification/notification";
import { useContextI18n } from "./ContextI18n";
import { PreferenceCommonService } from "../service/PreferenceCommonService";

const ContextFormatterNumber = createContext({});
const useContextFormatterNumber = () => useContext(ContextFormatterNumber);

function UseContextFormatterNumber(props) {
  const {
    urlApi,
    userFormatterNumber,
    resolveUserFormatterNumber,
    getRequestHeaders,
  } = useContextUser();
  const [formatterNumber, setFormatterNumber] = useState();

  const { i18n } = useContextI18n();

  const { addErrorLog } = useContextLog();

  /***************************** SERVICES *******************************/
  const preferenceCommonService = PreferenceCommonService(urlApi, getRequestHeaders, useContextLog())


  useEffect(() => {
    if (!!userFormatterNumber) setFormatterNumber(userFormatterNumber);
  }, [userFormatterNumber]);

  const saveFormatterNumber = useCallback(async () => {
    const bodyRequest = {
      preferenceType: PREF_TYPE_FORMATTAGE_NUMBER,
      code: "default",
      jsonValue: formatterNumber,
      idScreenDef: null,
      module: null,
    };

    bodyRequest.jsonValue = formatterNumber;

    preferenceCommonService.upsertPreference({
      data: formatterNumber,
      onSuccess: (response) => {
        resolveUserFormatterNumber();
        notifySuccess(i18n("form.preference.formatter.saved"))
      },
      onError: (e) => {
        addErrorLog(e)
      }
    }, null, null, PREF_TYPE_FORMATTAGE_NUMBER)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatterNumber]);

  const onFormatterNumberModification = (param, value) =>
    onBeanContextModification(formatterNumber, param, value, (bean) => {
      setFormatterNumber(bean);
    });

  return (
    <ContextFormatterNumber.Provider
      value={{
        formatterNumber,
        saveFormatterNumber,
        onFormatterNumberModification,
      }}
      {...props}
    />
  );
}

export { UseContextFormatterNumber, useContextFormatterNumber };
