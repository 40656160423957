export const loginPath = "/login"
export const homePath = "/home"
export const analisysPath = "/analysis"
export const predicHomePath = "/predicHomePath"
export const predictListPath = "/predictList"
export const panelPredicTemplateDesignerNoMasterPath = "/predicTemplate/designer/noMaster/panel" // private
export const panelPredicTemplateDesignerMasterPath = "/predicTemplate/designer/master/panel" // public
export const predicTemplateDesignerPathPublic = "/predicTemplate/designer/public"
export const predicTemplateDesignerPathPrivate = "/predicTemplate/designer/private"
export const panelPredicTemplateDeployerPath = "/predicTemplate/deployer/panel"
export const predicTemplateDeployerPath = "/predicTemplate/deployer"

export const panelPredicInitiatorPath = "/predicDeployed/initiator"
export const panelPredicSectionInitiatorPath = "/predicSection/initiator/panel"
export const predicSectionInitiatorPath = "/predicSection/initiator"

export const panelPredicReviewerPath = "/predicDeployed/reviewer"
export const panelPredicSectionReviewerPath = "/predicSection/reviewer/panel"
export const predicSectionReviewerPath = "/predicSection/reviewer"

export const panelPredicApproverPath = "/predicDeployed/approver"
export const panelPredicSectionApproverPath = "/predicSection/approver/panel"
export const predicSectionApproverPath = "/predicSection/approver"
export const panelPredicDeployedFinalizerPath = "/predicDeployed/finalizer/panel"
export const predicDeployedFinalizerPath = "/predicDeployed/finalizer"
export const panelPredicDeployedUserImpliedPath = "/predicDeployed/panel"
export const predicDeployedGlobalModification = "/glomi/template"
export const predicDeployedGanttMonitoring = "/predicDeployedGanttMonitoring"
export const predicDeployedGlomi = "/glomi/section"
export const formulaPath = "/formula"
export const variablesPath = "/variables"
export const dimensionsPath = "/dimensions"
export const metricsPath = "/metrics"
export const usersPath = '/users'
export const companyPath = '/company'
export const entityPath = '/entity'
export const perspectivePath = '/perspective'
export const licensePath = '/license'
export const transactionDetailsPath = '/transactionDetails'
export const narrativeCategoryPath = '/narrativeCategory'
export const fileImportPath = '/fileImport'
export const fileUploadForJsonPath = "/fileUploadForJson"
export const maintenancePath = '/maintenancePath'



