import React, { useState } from "react";
import ModalFormContextedPreferenceFormatterNumber from "../form/formatter/modal-form/ModalFormContextedPreferenceFormatterNumber";
import ButtonAlert from "./buttons/ButtonAlert";
import ButtonParams from "./buttons/ButtonParams";
import ButtonSearch from "./buttons/ButtonSearch";
import ButtonUser from "./buttons/ButtonUser";
import ButtonAdmin from "./buttons/ButtonAdmin";
import "./TopNavButtons.scss";
import { useContextUser } from "../../../context/ContextUser";
import { useContextMaintenance } from "../../../context/ContextMaintenance";

import MaintenanceInfoPopover from "../maitenance/MaintenanceInfoPopover";

const TopNavButtons = ({ hasDefaultCompany = true }) => {
  const [showPopupFormattage, setShowPopupFormattage] = useState(false);
  //  const [showUserSetting, setShowUserSetting] = useState(false);
  const { isUserCompanyUser, isDescriptiveRightReadOnly } = useContextUser();
  const { lastMaintenance, lastMaintenanceIsRead, setLastMaintenanceIsRead } = useContextMaintenance();
  const [showMaintenanceInformation, setShowMaintenanceInformation] = useState(false)


  return (
    <>
      <div className="top-nav-buttons">
        <ButtonSearch activated={false} />

        {/* ALERT MAINTENANCE */}
        {!!lastMaintenance ?
          <ButtonAlert activated={true}
            id="maintenance-information"
            className={lastMaintenanceIsRead ? "alert-msg-already-read" : "alert-msg-not-read"}
            onClick={() => { setLastMaintenanceIsRead(true); setShowMaintenanceInformation(!showMaintenanceInformation) }}

          />
          :
          <ButtonAlert activated={false} />
        }


        <ButtonParams
          showPopupFormattage={showPopupFormattage}
          setShowPopupFormattage={setShowPopupFormattage}
          activated={hasDefaultCompany}
          isDescriptiveRightReadOnly={isDescriptiveRightReadOnly}
        />

        <ButtonAdmin
          isAllowed={!isUserCompanyUser}
          activated={hasDefaultCompany}
        />

        {
          // 'true' à modifier dès qu'on aura les rôles de disponibles
        }
        <ButtonUser
        // showUserSetting={showUserSetting}
        // setShowUserSetting={setShowUserSetting}
        /> 
      </div>

      {showPopupFormattage && (
        <ModalFormContextedPreferenceFormatterNumber
          show={showPopupFormattage}
          setShow={setShowPopupFormattage}
        />
      )}

      {/* {showUserSetting && (

        <ModalFormikUser
          show={showUserSetting}
          setShow={setShowUserSetting}
          readOnly={true}
        />

      )} */}


      {showMaintenanceInformation && (
        <MaintenanceInfoPopover
          lastMaintenance={lastMaintenance}
          showMaintenanceInformation={showMaintenanceInformation}
          setShowMaintenanceInformation={setShowMaintenanceInformation}
          targetSelectorHtml={"#maintenance-information"}
        />
      )}

    </>
  );
};

export default TopNavButtons;
