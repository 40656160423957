import CommonPictoContainer from './CommonPictoContainer'
import PropTypes from 'prop-types'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

const PictoDelete = ({onClick, text, tooltipText, tooltipPlacement="top", disabled=false,  className, sizePicto}) => {
    return  <CommonPictoContainer icon={faTrashAlt} className={`picto-times ${!!className?className:""}`} onClick={onClick} text={text} tooltipText={tooltipText} tooltipPlacement={tooltipPlacement} disabled={disabled} sizePicto={sizePicto} />
}

PictoDelete.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.any,
    tooltipText: PropTypes.any,
    tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
}

export default PictoDelete