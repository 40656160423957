import { Pager, Paging } from "devextreme-react/data-grid";
import _ from "lodash";



export function splitPath(dataField) {
    const head = dataField?.split('.')[0];
    const tail = dataField?.slice(head.length + 1);
    return [head, tail];
}

export function updateSpecificAttr(experimentalData, dataField, value = null) {
    const [head, tail] = splitPath(dataField);
    return { ...experimentalData, [head]: (tail === "" ? value : updateSpecificAttr(experimentalData[head], tail, value)) };
}

export function getAttributeByPath(obj, dataField) {
    const [head, tail] = splitPath(dataField);
    return tail === "" ? obj?.[head] : getAttributeByPath(obj[head], tail);
}

export function isNotExistingAndNotEmpty(array) {
    if (!!array && array?.length !== 0) return false
    else return true
}

export const reduceObjectToKey = (keyExpr, value) => {
    return getAttributeByPath(value, keyExpr)

}

/**
 * 
 * Used to value input fields FieldDropdownGridSelector 
 * @param {*} keyExpr 
 * @param {*} value 
 * @returns 
 */
export const buildSelectedRowKeys = (keyExpr, value) => {

    //important to avoid unused API call if value is not defined
    if ([undefined, "", null].includes(value)) { // trapped as undefined
        return []
    } else if (_.isEmpty(value)) { // trapped as empty
        return []
    }

    if ((!Array.isArray(value) && keyExpr !== "id" && !!value && value !== "") ||
        (!!value && keyExpr === "id" && value[keyExpr] > 0) ||
        (Array.isArray(value) && Array(value).length > 0)
    ) {

        if (keyExpr === undefined || value === undefined || value === null)
            return []

    }
    if (Array.isArray(value)) {
        if (value.every(val => typeof val === 'object')) {
            return value.map(val => val[keyExpr])
        }
        return value
    } else if (typeof value === 'object' && !!value) {
        return [value[keyExpr]]
    } else {
        return [value]
    }

}



export function addColumnKeyAndDefaultSort(listOfElement, defaultSort) { // this doesn't handle column inside other columns -- it would be nice to adjust the algorithm

    if (Array.isArray(listOfElement)) {

        // add key
        let listOfKeyedElements = listOfElement.map((elt, index) => {
            return { ...elt, key: index }
        });

        if (!!defaultSort && defaultSort?.length !== 0) {
            // add sort props based upon defaultSort array
            let sortIndex = 0;
            for (const sort of defaultSort) {
                const indexOfColumn = listOfKeyedElements.findIndex(elt => elt?.props?.dataField === sort?.dataField);
                listOfKeyedElements[indexOfColumn].props = { ...listOfKeyedElements[indexOfColumn].props, defaultSortIndex: sortIndex, defaultSortOrder: sort?.asc === false ? "desc" : "asc" }
                sortIndex++;
            }
        } else if (!!listOfKeyedElements?.length) {
            // add sort props to the 1st visible column
            let index = 0;
            for (const elt of listOfKeyedElements) {
                if (elt?.type?.name === "Column" && elt?.props?.visible !== false) {

                    const props = listOfKeyedElements[index]?.props

                    if (!!listOfKeyedElements[index]?.props) {
                        const propsDescriptor = Object.getOwnPropertyDescriptor(listOfKeyedElements[index], 'props');

                        if (propsDescriptor.configurable) {
                            listOfKeyedElements[index].props = { ...props, defaultSortIndex: 0, defaultSortOrder: "asc" }
                        }
                    }
                    return listOfKeyedElements
                }
                index++;
            }
        }

        return listOfKeyedElements;

    } else {
        console.warn('listOfElement is not an Array', listOfElement)
    }
}


export const dataGridPagingAndPager = (defaultValue = 20, allowedPageSizes = [10, 20, 30], onSizeChange) => {

    const onPageSizeChange = (size) => {
        if (!!onSizeChange) {
            onSizeChange(size)
        } else {
            return
        }
    }

    return [
        <Paging
            key='paging'
            defaultPageSize={defaultValue}
            onPageSizeChange={onPageSizeChange}
        />,
        <Pager key='pager'
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={'compact'}
            showPageSizeSelector={true}
            showInfo={false}
        />
    ]
}