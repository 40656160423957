export const ShorterFormatNone = {name :"none", symbol : ""};

export const ShorterFormatThousand =  {name :"thousand", symbol : "K"};

export const ShorterFormatMillion =  {name :"million", symbol : "M"};

export const ShorterFormatBillion =  {name :"billion", symbol : "B"};

export const ShorterFormatDynamic =  {name :"dynamic"};


export const ShorterFormatDisplay = [ShorterFormatNone, ShorterFormatThousand, ShorterFormatMillion, ShorterFormatBillion, ShorterFormatDynamic]


export const applyShorterFormat = (value, shorterFormat) => {
    switch (shorterFormat?.name) {
        case ShorterFormatNone?.name :
            return value ;
        case ShorterFormatThousand?.name :
            return value/1000 ;
        case ShorterFormatMillion?.name :
            return value/1000000 ;
        case ShorterFormatBillion?.name :
            return value/1000000000 ;
        case ShorterFormatDynamic?.name : 
            const valueAbs = Math.abs(value)
            if (valueAbs < 1000) {
                return applyShorterFormat(value, ShorterFormatNone)
            } else if (valueAbs < 1000000) {
                return applyShorterFormat(value, ShorterFormatThousand)
            } else if (valueAbs < 1000000000) {
                return applyShorterFormat(value, ShorterFormatMillion)
            } else {
                return applyShorterFormat(value, ShorterFormatBillion)
            }
        default :
            return value
    }
}

export const getSymbolShorterFormat = (value, shorterFormat) => {

    switch (shorterFormat?.name) {
        case ShorterFormatDynamic?.name : 
        const valueAbs = Math.abs(value)
        if (valueAbs < 1000) {
            return getSymbolShorterFormat(value, ShorterFormatNone)
        } else if (valueAbs < 1000000) {
            return getSymbolShorterFormat(value, ShorterFormatThousand)
        } else if (valueAbs < 1000000000) {
            return getSymbolShorterFormat(value, ShorterFormatMillion)
        } else {
            return getSymbolShorterFormat(value, ShorterFormatBillion)
        }
    default :
        return shorterFormat?.symbol
    }
}
