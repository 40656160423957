import { useContextI18n, UseContextI18nProvider } from "../../../context/ContextI18n";
import CompanyGridContexted from "../../param/company/CompanyGridContexted";
import { useContextLog } from "../../../context/ContextLog";
import React from "react";
import { FormUserService } from "../../../service/FormUserService";
import { useContextUser } from "../../../context/ContextUser";
import { notifyError } from "../../commons/utils/notification/notification";
import TopNav from "../../commons/navigation/TopNav";
import "./PanelDefaultCompany.scss";
import { Col, Container, Row } from "react-bootstrap";

const PanelDefaultCompany = () => {

    const { i18n } = useContextI18n();
    const { urlApi, getRequestHeaders, resolveAccessDictionary, user } = useContextUser();
    const formUserService = FormUserService(urlApi, getRequestHeaders, useContextLog());

    function updateUserWithDefaultCompany(event) {
        formUserService.updateUserWithDefaultCompany({
            data: {
                user: user,
                company: { id: event.selectedRowKeys[0].id }
            },
            onSuccess: (response) => {
                resolveAccessDictionary()
            },
            onError: (error) => {
                notifyError(error);
            },
            finally: (response) => {
            }
        });

    }

    return (
        <UseContextI18nProvider>
            <TopNav hasDefaultCompany={false} />
            <Container>
                <Row>
                    <Col>
                        <div className="panel-defaultCompany-bloc">
                            <h3 className="title">{i18n("panel.defaultCompany.message")}</h3>
                            <CompanyGridContexted onSelectionChanged={updateUserWithDefaultCompany} selectionMode={"single"} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </UseContextI18nProvider>
    );

};

export default PanelDefaultCompany;