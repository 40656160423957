import { APPROVER, DEPLOYER, DESIGNER, FINALIZER, INITIATOR, REVIEWER } from "./predicTemplate/template/constants/PredicUserType"

export const TYPEVIEW_PREDIC_TEMPLATE = "tvp-template"
export const TYPEVIEW_PREDIC_DEPLOYED = "tvp-deployed"
export const TYPEVIEW_PREDIC_SECTION_DEPLOYED = "tvp-section"

export const TEMPLATES_DESIGNER_MASTER = {
    id: 0,
    tabName: "DESIGNER_MASTER",
    predicUserType: DESIGNER
}

export const TEMPLATES_DESIGNER_NO_MASTER = {
    id: 1,
    tabName: "DESIGNER_NO_MASTER",
    predicUserType: DESIGNER

}

export const TEMPLATES_DEPLOYER = {
    id: 2,
    tabName: "DEPLOYER",
    predicUserType: DEPLOYER

}

export const PREDIC_DEPLOYED_INITIATOR = {
    id: 3,
    tabName: "INITIATOR",
    predicUserType: INITIATOR

}

export const PREDIC_DEPLOYED_REVIEWER = {
    id: 4,
    tabName: "REVIEWER",
    predicUserType: REVIEWER

}

export const PREDIC_DEPLOYED_APPROVER = {
    id: 5,
    tabName: "APPROVER",
    predicUserType: APPROVER

}

export const PREDIC_DEPLOYED_FINALIZER = {
    id: 6,
    tabName: "FINALIZER",
    predicUserType: FINALIZER

}

export const PREDIC_DEPLOYED_USER_IMPLIED = {
    id: 7,
    tabName: "PREDIC_DEPLOYED_USER_IMPLIED",
    predicUserType : "USER_IMPLIED"
}

export const PREDIC_DEPLOYED_GANTT_MONITORING = {
    id: 8,
    tabName: "PREDIC_DEPLOYED_GANTT_MONITORING",
    //predicUserType : FINALIZER
}

export const PREDIC_DEPLOYED_GLOBAL_MODIFICATION = {
    id: 8,
    tabName: "PREDIC_DEPLOYED_GLOBAL_MODIFICATION",
    //predicUserType : FINALIZER
}

const ListPredicUserTypeTab = [TEMPLATES_DESIGNER_MASTER, TEMPLATES_DESIGNER_NO_MASTER, TEMPLATES_DEPLOYER, PREDIC_DEPLOYED_INITIATOR, PREDIC_DEPLOYED_REVIEWER, PREDIC_DEPLOYED_APPROVER, PREDIC_DEPLOYED_FINALIZER, PREDIC_DEPLOYED_USER_IMPLIED]

// export const getViewByPredicUserType = (predicUserType) => {

//     let tab = ListPredicUserTypeTab.find(tabulation => tabulation.predicUserType === predicUserType)
//     if (!tab) tab = TEMPLATES_DEPLOYER
//     return tab
// }

export default ListPredicUserTypeTab
