
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { PREF_TYPE_FORMATTAGE_NUMBER } from "../api/endPoints";
import mapperFunctionalModuleTree from "../components/analysis/mappers/mapperFunctionalModuleTree";
import { FMC_ANALYTICS } from "../components/assets/const/FunctionalModuleCategories";
import { notifySuccess } from "../components/commons/utils/notification/notification";
import { ShorterFormatNone } from "../components/commons/form/formatter/display/ShorterFormatDisplay";
import { SeparatorComma, SeparatorPoint } from "../components/commons/form/formatter/display/Separator";
import { FormatNegatifMinus } from "../components/commons/form/formatter/display/FormatNegatifDisplay";
import { PositionSymbolNone } from "../components/commons/form/formatter/display/PositionSymbolDisplay";
import { useContextLog } from "./ContextLog";
import { ERROR_RESOLVE_ACCESS_DICTIONNARY, ERROR_RESOLVE_USER } from "../components/assets/const/ErrorCode";
import { ERROR_RESOLVE_FORMATTER_NUMBER } from "../components/assets/const/ErrorCode";
import { ROLE_COMPANY_ADMIN, ROLE_COMPANY_USER, ROLE_INHOVATE_ADMIN, ROLE_INHOVATE_USER } from "./ListRolesUsers"
import { FormatZeroZero } from "../components/commons/form/formatter/display/FormatZeroDisplay";
import { TypeDescriptiveRight_RO } from "../components/analysis/dynamic_dashboard/users/TypeDescriptiveRight";
import { checkKeysLocalStorage } from "./utils/checkKeysLocalStorage";
import packageConfig from './../../package.json'; 
import { UserService } from "../service/UserService";
import { PreferenceCommonService } from "../service/PreferenceCommonService";
import { FormUserService } from "../service/FormUserService";
import { getSimplifiedMapOnlyWithEntitieIdList } from "./utils/userUtils";

const ContextUser = createContext({});
const useContextUser = () => useContext(ContextUser);

function UseContextUserProvider(props) {

  const { proxy, homepage } = packageConfig;
  const urlApi = props.urlApi;

  const [keycloak] = useState(props.keycloak);
  const { addErrorLog } = useContextLog();

  const getRequestHeaders = useCallback(() => {
    const headers = {
      headers: { Authorization: " Bearer " + keycloak?.token },
    };
    return headers;
  }, [keycloak?.token]);


  /***************************** SERVICES *******************************/
  const userService = UserService(urlApi, getRequestHeaders, useContextLog())
  const preferenceCommonService = PreferenceCommonService(urlApi, getRequestHeaders, useContextLog())
  const formUserService = FormUserService(urlApi, getRequestHeaders, useContextLog())
  /***************************** VARIABLES *******************************/


  /***************************** ACTIONS FOR REDUCER *******************************/
  const actions = {
    CONNECT_USER: "CONNECT_USER",
    DISCONNECT_USER: "DISCONNECT_USER",
    SET_USER_NUMBER_FORMATER: "SET_USER_NUMBER_FORMATER",
    SET_MAP_AUTHORIZED_COMPANY_MODULES_ENTITIES: "SET_MAP_AUTHORIZED_COMPANY_MODULES_ENTITIES",
    NEED_RESOLVE_ACCESS_DICTIONNARY: "NEED_RESOLVE_ACCESS_DICTIONNARY"
  }

  /***************************** INITIAL STATE  *******************************/
  const defaultFormatter = {
    positionSymbol: PositionSymbolNone,
    formatNegatif: FormatNegatifMinus,
    precision: 2,
    separatorThousand: SeparatorComma,
    separatorDecimal: SeparatorPoint,
    shorterFormat: ShorterFormatNone,
    formatZero: FormatZeroZero,
    isIndianNotation: false,
  };

  const initialState = {
    user: undefined,
    userCompany: undefined,
    simplifiedMapAuthorizedCompanyModulesEntities: undefined,
    functionalModuleTreeList: undefined,
    functionalModuleCategory: FMC_ANALYTICS,
    userFormatterNumber: defaultFormatter,
    needResolveAccessDictionary: true,
    needResolveUserFormatterNumber: false,
    needResolvemapAuthorizedCompanyModulesEntities: false
  }

  /***************************** REDUCER  *******************************/

  const reducer = (state, action) => {
    switch (action.type) {
      case actions.CONNECT_USER:
        notifySuccess("User pref loaded");
        return {
          ...state,
          user: action.user,
          userCompany: action.userCompany,
          functionalModuleTreeList: action.functionalModuleTreeList,
          needResolveAccessDictionary: false,
          needResolvemapAuthorizedCompanyModulesEntities: true,
          needResolveUserFormatterNumber: true
        }
      case actions.DISCONNECT_USERCONNECT_USER:
        return {
          ...state,
          user: undefined,
          userCompany: undefined,
          functionalModuleTreeList: undefined,
          simplifiedMapAuthorizedCompanyModulesEntities: undefined,
          userFormatterNumber: undefined
        }

      case actions.SET_MAP_AUTHORIZED_COMPANY_MODULES_ENTITIES:
        return {
          ...state,
          simplifiedMapAuthorizedCompanyModulesEntities: action.simplifiedMapAuthorizedCompanyModulesEntities,
          needResolvemapAuthorizedCompanyModulesEntities: false
        }

      case actions.SET_USER_NUMBER_FORMATER:
        notifySuccess("Number formater loaded");
        return {
          ...state,
          userFormatterNumber: action.userFormatterNumber,
          needResolveUserFormatterNumber: false
        }

      case actions.NEED_RESOLVE_ACCESS_DICTIONNARY:
        return {
          ...state,
          needResolveAccessDictionary: action.needResolveAccessDictionary
        }
      default: return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Resolve Access Dictionary
   */
  const resolveAccessDictionary = useCallback(async () => {
    userService.getAccessDictionnary({
      onSuccess: (response) => {
        dispatch({ type: actions.CONNECT_USER, user: response.data.user, userCompany: response.data.company, functionalModuleTreeList: mapperFunctionalModuleTree(response) })
      },
      onError: (e) => {
        addErrorLog(e, ERROR_RESOLVE_ACCESS_DICTIONNARY);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.needResolveAccessDictionary) resolveAccessDictionary();
  }, [resolveAccessDictionary, state.needResolveAccessDictionary]);

  /**
    * Resolve User Authorized Company-Modules-Entities
    * 
    * the simplifiedMapAuthorizedCompanyModulesEntities is an object map structured like that :
    * {idCompany : {idLicense: [idEntity,...]}}
    */
  const resolveMapAuthorizedCompanyModulesEntities = useCallback(async () => {

    if (!isNaN(state?.user?.id) && state?.user?.id > 0) {

      if ([ROLE_COMPANY_ADMIN?.name, ROLE_COMPANY_USER?.name].includes(state.user?.role)) {
        formUserService.resolveFormUserById({
          data: state.user.id,
          onSuccess: (response) => {

            const simplifiedMap = getSimplifiedMapOnlyWithEntitieIdList(response?.data?.mapAuthorizedCompanyModulesEntities)

            dispatch({
              type: actions.SET_MAP_AUTHORIZED_COMPANY_MODULES_ENTITIES,
              simplifiedMapAuthorizedCompanyModulesEntities: simplifiedMap
            })
          },
          onError: (error) => {
            addErrorLog(error, ERROR_RESOLVE_USER)
          },
          finally: () => { }
        })
      } else {
        dispatch({
          type: actions.SET_MAP_AUTHORIZED_COMPANY_MODULES_ENTITIES,
          mapAuthorizedCompanyModulesEntities: { "": "NB : No mapAuthorizedCompanyModulesEntities for Inhovate users" }
        })
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [state?.user?.id]);


  useEffect(() => {
    if (state.needResolvemapAuthorizedCompanyModulesEntities) resolveMapAuthorizedCompanyModulesEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.needResolvemapAuthorizedCompanyModulesEntities]);



  /**
    * Resolve Userr Number formatter
    */
  const resolveUserFormatterNumber = useCallback(async () => {

    const nameScreen = undefined; // pour le formatage, le screen est non défini
    const nameModule = undefined; // pour le formatage, le screen est non défini

    preferenceCommonService.getPreference({
      onSuccess: (response) => {
        if (!!response?.data) {
          dispatch({ type: actions.SET_USER_NUMBER_FORMATER, userFormatterNumber: response.data })
        } else {
          dispatch({ type: actions.SET_USER_NUMBER_FORMATER, userFormatterNumber: defaultFormatter })
        }
      },
      onError: (e) => {
        addErrorLog(e, ERROR_RESOLVE_FORMATTER_NUMBER);
        dispatch({ type: actions.SET_USER_NUMBER_FORMATER, userFormatterNumber: defaultFormatter })
      },
      finally: () => { }
    }, nameScreen, nameModule, PREF_TYPE_FORMATTAGE_NUMBER)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferenceCommonService]);


  useEffect(() => {
    if (state.needResolveUserFormatterNumber) resolveUserFormatterNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.needResolveUserFormatterNumber]);


  useEffect(() => {
    checkKeysLocalStorage()
  }, [])




  /***************************** METHODS  *******************************/
  const signOut = () => {
    dispatch({ type: actions.DISCONNECT_USER })
    const uriLogout = `${proxy}${homepage}`
    keycloak?.logout({ redirectUri: uriLogout });
  };

  const getCompanyId = () => {
    return state?.user?.companyId;
  };

  const getAccessToken = () => {
    return keycloak?.access_token;
  };

  const getFunctionalModuleTree = () => {
    if (!!state.functionalModuleTreeList && !!state.functionalModuleCategory) {
      return state.functionalModuleTreeList.find((fmt) => fmt.name === state.functionalModuleCategory);
    }
  };

  return (
    <ContextUser.Provider
      value={{
        urlApi,
        user: state.user,
        userCompany: state.userCompany,
        userFormatterNumber: state.userFormatterNumber,
        needResolveUserFormatterNumber: state.needResolveUserFormatterNumber,
        resolveAccessDictionary,
        resolveUserFormatterNumber,
        signOut,
        getAccessToken,
        getFunctionalModuleTree,
        getCompanyId,
        getRequestHeaders,
        simplifiedMapAuthorizedCompanyModulesEntities: state.simplifiedMapAuthorizedCompanyModulesEntities,
        isUserInhovateAdmin: state.user?.role === ROLE_INHOVATE_ADMIN?.name,
        isUserInhovateUser: state.user?.role === ROLE_INHOVATE_USER?.name,
        isUserCompanyAdmin: state.user?.role === ROLE_COMPANY_ADMIN?.name,
        isUserCompanyUser: state.user?.role === ROLE_COMPANY_USER?.name,
        isDescriptiveRightReadOnly: state.user?.descriptiveRight === TypeDescriptiveRight_RO,
      }}
      {...props}
    />
  );
}

export { UseContextUserProvider, useContextUser };
