import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import 'reactflow/dist/style.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root')
const urlApi = root.dataset.urlApi

ReactDOM.render(
    <App 
        urlApi={urlApi}
    />, 
    root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();