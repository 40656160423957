import React from 'react'
import PropTypes from 'prop-types'
import { useContextI18n } from '../../../../context/ContextI18n'
import './ShowMore.scss'
import { Badge } from 'react-bootstrap'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const ShowMore = ({ showMore, setShowMore, nbItems, showNbItemMax }) => {

    const { i18n } = useContextI18n()
    const i18nKeyPrefix = 'show.more.button'

    return (
        <div className="show-more">
            {!showMore && showNbItemMax < nbItems && (
                <span className='show' onClick={(e) => {setShowMore(true); e.stopPropagation();}}>
                    <Badge pill>+ {nbItems - showNbItemMax}</Badge>
                    <span className="ellipsis">
                        <FontAwesomeIcon icon={faEllipsisH} /> 
                    </span>
                    {i18n(`${i18nKeyPrefix}.display.all`)}
                </span>
            )}
            {showMore && showNbItemMax < nbItems && (
                <span className='hide' onClick={(e) => {setShowMore(false); e.stopPropagation();}}>
                    {i18n(`${i18nKeyPrefix}.hide.end.of.list`)}
                    <span className="ellipsis">
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </span>
                </span>
            )}
        </div>
    )

}

ShowMore.propTypes = {
    // showMore : affiche ou pas plus de données
    showMore: PropTypes.bool.isRequired,

    // set du bool showMore
    setShowMore: PropTypes.func.isRequired,

    //nb d'éléments au total dans la liste
    nbItems: PropTypes.number.isRequired,

    // nombre max d'éléments affichés quand l'affichage est tronqué (showMore = false)
    showNbItemMax: PropTypes.number.isRequired
}

export default ShowMore