
import { mapperCurrencyConversionCategory } from "./mapperCurrencyConversionCategoryList";

export const mapDatasetScenario = (json) => {

    try {

        const scenario = {
            id: ''+json.id, //force string
            label: json.label,
            typeId: json.typeId,
            versionId: json.versionId,
            versionCode : json.versionCode,
            ranges: json.ranges,
            forexOptions: !!json.forexOptions ? mapperCurrencyConversionCategory(json.forexOptions) : undefined
        }

        return scenario

    } catch (e) {
        console.error("ERROR mapDatasetScenario !!!",e)

    }
}


export const mapDataset = (json) => {

    try {
        const dataset = {
            id: json.id,
            label: json.label,
            code: json.code,
            currencyCode: json.currencyCode,
            propertyListLabel: json.propertyListLabel,
            propertyIdList: json.propertyIdList,
            propertyLibraryId: json.propertyLibraryId,
            lstIdMetrics: json.lstIdMetrics,
            lstScenarios: [] //json.datasetScenarios
        }

        json.lstScenarios?.forEach(s => {
            dataset.lstScenarios.push(mapDatasetScenario(s))
        });

        return dataset
    } catch (e) {
        console.error("ERROR mapDataset !!!",e)
    }
}

/*
    jsonLstDataset = json de la liste de tous les datasets
    si jsonLstDataset fourni, on met les datasets complets dans les groupes (atr. lstDataset)
    sinon que les ids stockés dans des objects d'état associatif (attr. datasetStructure)
*/

export const mapDatasetGroup = (json) => {

    const datasetGroup = {
        id: json.id,
        label: json.label,
        datasetStructure: json.datasetStructure,
        description: json.description,
        metricList: json.metricList,
        perspective: json.perspective
    }

    return datasetGroup
}




/*
    Return group list
*/
export const mapperDatasetGroupList = (json) => {

    //console.log(json);
    const jsonLstDatasetGroupList = json?.lstDatasetGroup

    const lst = []

    if (!!jsonLstDatasetGroupList) {

        jsonLstDatasetGroupList.map(json => {

            const group = mapDatasetGroup(json)
            return lst.push(group)
        })
    }

    return lst

}

/*
    Return a dataSetList
*/
export const mapperDatasetList = (json) => {

    const jsonLstDataset = json?.lstDatasets

    const lst = []

    if (!!jsonLstDataset) {
        jsonLstDataset.map(json => {
            const dataset = mapDataset(json)
            return lst.push(dataset)
        })
    }

    return lst
}

