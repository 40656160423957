import { useFormikContext } from "formik";

import LoadingInfinity from "../../navigation/LoadingInfinity";
import React, { useMemo } from "react";

import FieldDropdownGridSelectorWrapper from "./FieldDropdownGridSelectorWrapper";
import { deepFind } from "../../utils/object-manipulation/deepFind";

const FieldDropdownGridSelector2 = ({
  name, // MANDATORY
  label,
  className = "",
  isMandatory,
  dataSource, // MANDATORY
  renderItemToggle, // ex : value => value?.description
  additionalText,
  needItemToggleDataSourceLoaded,
  onChangeReturnOnlyKey,
  doAfterChange,
  keyExpr, // /!\ si Array, value doit être un object
  datagridChildren,
  selection = "single", // "single" by default or "multiple"
  remoteOperations = selection !== "multiple", // if multiple, remote operation must be false
  readOnly,
  positionAt = "top center", // Be careful, it is risky to change this positioning parameter
  positionMy = "top center", // Be careful, it is risky to change this positioning parameter
  positionOf = "body", // Be careful, it is risky to change this positioning parameter
  maxWidth,
  onCellPrepared,
  onEditorPreparing,
  enableButtonResetInSingleMode = false,
  conditionItemIsDeletable,
  errorMsg
}) => {
  const { values, setFieldValue } = useFormikContext();

  const value = useMemo(()=> {
    // Case of a name that contains a list
    // ex : name = 'lstPredicValue[0].currency'
    // ex : name = 'mapDimensions.DEPARTMENT.dimension'
    if (name.includes(".")) {
      try {

        return deepFind(values, name)

      } catch (e) {
        console.warn("FieldDropdownGridSelector2 : valorisation", name, values);
        return undefined;
      }
    } else {
      // basic case
      return values[name];
    }
  },[name, values])


  const onChange = (finalValues) => {
    if (!readOnly) { // To avoid the problems of double click on the readonly input field
      if (selection === "single" && Array.isArray(finalValues)) {

        setFieldValue(
          name,
          finalValues.length > 0 ? finalValues[0] : undefined,
          true
        );

        if (typeof doAfterChange === "function") doAfterChange(finalValues[0]);
      } else {
        setFieldValue(name, finalValues, true);
        if (typeof doAfterChange === "function") doAfterChange(finalValues);
      }
    }
  };

  if (!name)
    return (
      <LoadingInfinity
        isOverlayTransparent={false}
        crashMode={true}
        crashTitle={"Field without Name "}
      />
    );
  else {
    return (
      <>
        {!!dataSource && (
          <FieldDropdownGridSelectorWrapper
            fieldValue={value}
            doIt={onChange} // appel pour sélection
            label={label}
            className={className}
            isMandatory={isMandatory}
            dataSource={dataSource} // MANDATORY
            renderItemToggle={renderItemToggle} // ex : value => value?.description
            textComplementaire={additionalText}
            needItemToggleDataSourceLoaded={needItemToggleDataSourceLoaded}
            onChangeReturnOnlyKey={onChangeReturnOnlyKey}
            keyExpr={keyExpr} // /!\ si Array, value doit être un object
            datagridChildren={datagridChildren}
            selection={selection} // "single" by default or "multiple"
            remoteOperations={remoteOperations}
            readOnly={readOnly}
            positionAt={positionAt}
            positionMy={positionMy}
            positionOf={positionOf}
            maxWidth={maxWidth}
            shading={true}
            valueStateOnGrid={true}
            onCellPrepared={onCellPrepared}
            onEditorPreparing={onEditorPreparing}
            enableButtonResetInSingleMode={enableButtonResetInSingleMode}
            conditionItemIsDeletable={conditionItemIsDeletable}
            errorMsg={errorMsg}
          />
        )}
      </>
    );
  }
};

export default FieldDropdownGridSelector2;
