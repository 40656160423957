import { GenericService } from "./GenericService"


export const GenericInhovateService = (requestHeaders, contextLog, codeLogger) => {

    const commonInitParam = (params, method) => {

        if (!! contextLog ) {
            params.onCommonError = ((error) => {
                error.errorCode = codeLogger ; // TODO A AFFINER ?  
                contextLog.addErrorLog(error)
            })
        }

        return params
    }

    return GenericService(requestHeaders, commonInitParam);
}





