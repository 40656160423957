import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import Keycloak from 'keycloak-js';
import { UseContextUserProvider } from './context/ContextUser';
import { UseContextI18nProvider } from './context/ContextI18n';
import { UseContextAppVersionningProvider } from './context/ContextAppVersionning';

import { notifyError } from './components/commons/utils/notification/notification';
import { UseContextLogProvider } from './context/ContextLog';
import CommonScss from './CommonScss';
import { UseContextModuleProvider } from './context/ContextModule';
import { UseContextFormatterNumber } from './context/ContextFormatterNumber';
import LoadingInfinity from './components/commons/navigation/LoadingInfinity';
//import csMessages from "devextreme/localization/messages/cs.json";
//import deMessages from "devextreme/localization/messages/de.json";
//import elMessages from "devextreme/localization/messages/el.json";
import enMessages from "devextreme/localization/messages/en.json";
//import esMessages from "devextreme/localization/messages/es.json";
//import fiMessages from "devextreme/localization/messages/fi.json";
import frMessages from "devextreme/localization/messages/fr.json";
//import huMessages from "devextreme/localization/messages/hu.json";
//import itMessages from "devextreme/localization/messages/it.json";
//import jaMessages from "devextreme/localization/messages/ja.json";
//import nlMessages from "devextreme/localization/messages/nl.json";
//import ptMessages from "devextreme/localization/messages/pt.json";
//import ruMessages from "devextreme/localization/messages/ru.json";
//import slMessages from "devextreme/localization/messages/sl.json";
//import svMessages from "devextreme/localization/messages/sv.json";
import trMessages from "devextreme/localization/messages/tr.json";
//import viMessages from "devextreme/localization/messages/vi.json";
//import zhtwMessages from "devextreme/localization/messages/zh-tw.json";
//import zhMessages from "devextreme/localization/messages/zh.json";
import { loadMessages } from "devextreme/localization";
import Spa from "./components/commons/spa/Spa"
import { UseContextLoaderProvider } from './context/ContextLoader';
import { UseContextUserLstProvider } from './context/ContextUserLst';
import { UseContextMaintenanceProvider } from './context/ContextMaintenance';

const App = (props) => {


    //        loadMessages(csMessages);
    //        loadMessages(deMessages);
    //        loadMessages(elMessages);
    loadMessages(enMessages);
    //        loadMessages(esMessages);
    //        loadMessages(fiMessages);
    loadMessages(frMessages);
    //        loadMessages(huMessages);
    //        loadMessages(itMessages);
    //        loadMessages(jaMessages);
    //        loadMessages(nlMessages);
    //       loadMessages(ptMessages);
    //        loadMessages(ruMessages);
    //        loadMessages(slMessages);
    //        loadMessages(svMessages);
    loadMessages(trMessages);
    //        loadMessages(viMessages);
    //        loadMessages(zhtwMessages);
    //        loadMessages(zhMessages);
    //locale(navigator.language);

    const [connexion, setConnexion] = useState({ keycloak: null, authenticated: false, backendData: null })

    const urlApi = props.urlApi

    useEffect(() => {

        // INIT Keycloak
        const keycloak = Keycloak("/keycloak.json");

        keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(authenticated => {
            setConnexion({ keycloak: keycloak, authenticated: authenticated });

        }).catch(() => {
            console.log('Failed to load keycloak profile');
        });


        // REFRESH Keycloak token
        keycloak.onTokenExpired = () => {
            //console.log('expired ' + new Date());
            keycloak?.updateToken().then((refreshed) => {
                if (refreshed) {
                    // console.info('Keycloak refreshed ' + new Date())
                } else {
                    console.warn('Keycloak not refreshed ' + new Date())
                }
            }).catch(function () {
                notifyError('Failed to refresh the token, or the session has expired')
                console.log('Failed to refresh the token, or the session has expired')
            });
        }

    }, [])


    return (

        <Container>
            <CommonScss />
            <UseContextLogProvider>
                <UseContextLoaderProvider>
                    {connexion.authenticated ?
                        <UseContextUserProvider urlApi={urlApi} keycloak={connexion.keycloak}>
                            <UseContextAppVersionningProvider>
                                <UseContextI18nProvider>
                                    <UseContextMaintenanceProvider>
                                        <UseContextUserLstProvider>
                                            <UseContextFormatterNumber>
                                                <UseContextModuleProvider>
                                                    <Spa />
                                                </UseContextModuleProvider>
                                            </UseContextFormatterNumber>
                                        </UseContextUserLstProvider>
                                    </UseContextMaintenanceProvider>
                                </UseContextI18nProvider>
                            </UseContextAppVersionningProvider>
                        </UseContextUserProvider>
                        :
                        <LoadingInfinity title="Loading" message="Authentication in progress" />
                    }
                </UseContextLoaderProvider>
            </UseContextLogProvider>
        </Container>
    )

}

export default App;