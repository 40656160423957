import { Popover } from "devextreme-react";
import { useContextI18n } from "../../../context/ContextI18n";
import { renderClock } from "../../predict/predicDeployedSection/remarksAndNarrativesPane/clock/Clock";
import './MaintenanceInfoPopover.scss'


export const getMaintenanceInfos = (maintenance, contextI18n) => {

    const i18n = contextI18n.i18n

    return (
        <div className="maintenance-infos">
            <div className='start-date'>
                <label>{i18n("maintenance.popover.body.startDate")}</label>
                <span>{renderClock(maintenance?.startDate, true, true, contextI18n, true)} </span>
            </div>

            <div className='duration'>
                <label>{i18n("maintenance.popover.body.duration")}</label>
                <span> {maintenance?.duration} {i18n(maintenance?.periodicity?.toLowerCase())}</span>
            </div>

            <div className='description'>
                <label>{i18n("manage.maintenance.add.description")}</label>
                <span>{maintenance?.description}</span>
            </div>

            <div className='msg-info'>
                <span>{i18n("maintenance.popover.body.msg.1")}</span>
                <span>{i18n("maintenance.popover.body.msg.2")}</span>
            </div>
        </div>
    )
}

const MaintenanceInfoPopover = ({ lastMaintenance, showMaintenanceInformation, setShowMaintenanceInformation, targetSelectorHtml }) => {

    const contextI18n = useContextI18n()

    return (
        <Popover
            target={targetSelectorHtml}
            position="bottom"
            showTitle={true}
            title={contextI18n.i18n("maintenance.popover.title")}
            visible={showMaintenanceInformation}
            minWidth={200}
            maxWidth={400}
            showCloseButton={true}
            onHiding={() => setShowMaintenanceInformation(false)}
        >
            <div className="maintenance-info-popover" >

            {getMaintenanceInfos(lastMaintenance, contextI18n)}

            </div>
        </Popover>
    )


}


export default MaintenanceInfoPopover